import { Row, Col } from 'react-bootstrap';
import Styles from './howHissayWorks.module.scss';
import { ExploreAndPurchases, SitBackAndEarn, JoinHissay } from '../../../assets/fractionalOwnershipScreen';
import { Container } from '../../container';

function HowHissayWorks() {
	const hissayWorkCard = (
		image,
		title,
		text,
		buttonLabel,
	) => {
		return (
			<div className={Styles.hissayWorkCard}>
				<div className={Styles.cardImage}>
					<img src={image} width="100%" alt='Title' />
				</div>
				<div className={Styles.cardContent}>
					<h4 className={Styles.cardHeading}>{title}</h4>
					<p className={Styles.cardText}>{text}</p>
					<button className={Styles.cardButton}>{buttonLabel}</button>
				</div>
			</div>
		);
	};

	return (
		<div className="py-5">
			<Container>
				<h2 className={Styles.sectionHeading}>
					How does Hissay Work?
				</h2>
				<p className={Styles.sectionText}>
					We make property investment easy and secure. You pick a property and let us go through all
					of the effort of ensuring your investment goes towards legitimate properties. No more
					worrying about fraud and no more worrying about finding money for a whole property. We
					find other investors interested in owning the same property you want. Then we put in the
					effort to rent out your property as well! All you have to do is:
				</p>
				<Row className="justify-content-center">
					<Col xs={12} md={6} xl={4}>
						{hissayWorkCard(
							JoinHissay,
							'Join Hissay',
							'Create your free account and start your journey as a property investor',
							'Sign Up'
						)}
					</Col>
					<Col xs={12} md={6} xl={4}>
						{hissayWorkCard(
							ExploreAndPurchases,
							'Explore and Purchase',
							'Explore thousands of properties that we do the work of verifying when you decide to invest. Start off by investing asc little as Rs. 100,000',
							'Buy Now'
						)}
					</Col>
					<Col xs={12} md={6} xl={4}>
						{hissayWorkCard(
							SitBackAndEarn,
							'Sit-back and Earn',
							'Let your money work for you. Watch your properties increase in value, sell when you are ready, all while earning income from rent',
							'Learn More'
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export { HowHissayWorks };
