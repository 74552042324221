import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
	LocationIcon, FacebookIcon, TwitterIcon, PhoneIcon, EmailIcon, InstagramIcon
} from '../../assets';
import HissayLogo from '../../assets/logoWithSlogan.svg';
import Styles from './footer.module.scss';

function Footer() {
	const currentYear = new Date().getFullYear();
	const tweetMessage = 'I just signed up to co-invest in properties on Hissay. You should too! https://www.hissay.pk';

	return (
		<footer className={`py-lg-5 py-4 px-md-3 ${Styles.mainContainer}`}>
			<div className="container-fluid px-4 px-md-5 text-md-left">
				<div className="w-100 d-flex flex-column flex-lg-row justify-content-between mb-4 mb-lg-5">
					<img src={HissayLogo} alt="Logo" width={240} />
					<div className="d-flex mt-5 mt-lg-3 flex-column flex-lg-row">
						<div className="d-flex align-items-start mr-5 mb-2">
							<img className="mr-2" src={LocationIcon} alt="Location" width={20} height={26} />
							<p>123 XYZ St,<br/>Houston, TX 77079</p>
						</div>
						<div className="d-flex align-items-star mr-5 mb-2">
							<img className="mr-2" src={PhoneIcon} alt="Contact" width={20} height={20} />
							<p>051-21941822</p>
						</div>
						<div className="d-flex align-items-start">
							<img className="mr-2" src={EmailIcon} alt="Email" width={20} height={24} />
							<p>info@hissay.pk</p>
						</div>
					</div>
				</div>
				<Row className="flex-column-reverse flex-lg-row align-items-center">
					<Col xs={12} lg={6}>
						<div className="d-flex flex-wrap flex-column-reverse flex-md-row mb-lg-0">
							<p className="p-0 m-0 mr-5">© {currentYear} Hissay. All rights reserved.</p>
							<div className="p-0 m-0">
								<Link className={Styles.link} to='/terms'>Terms & Conditions</Link>
								<span> &#8226; </span>
								<Link className={Styles.link} to='/privacy-policy'>Privacy Policy</Link>
							</div>
						</div>
					</Col>
					<Col xs={12} lg={6}>
						<div className={`d-flex align-items-center ${Styles.socialLinks} justify-content-start justify-content-lg-end mb-4 mb-lg-0`}>
							<a href="https://www.google.com" className="mr-3">
								<img src={FacebookIcon} alt="Facebook" width={30} />
							</a>
							<a
								href={`https://twitter.com/intent/tweet?text=${tweetMessage}`}
								target="_blank"
								className="mr-3"
								rel="noreferrer">
								<img src={TwitterIcon} alt="Twitter" width={30} />
							</a>
							<a href="https://www.google.com" className="mr-2">
								<img src={InstagramIcon} alt="Instagram" width={30} />
							</a>
						</div>
					</Col>
				</Row>
			</div>
		</footer>
	);
}

export { Footer };
