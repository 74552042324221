import { useEffect } from 'react';
import Styles from './termsScreen.module.scss';

function TermsScreen() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, []);

	return (
		<main>
			<div className="container py-5">
				<h1 className={Styles.title}>Terms and Conditions</h1>
				<p>Last revised on August 27, 2023</p>
				<p>
					Hello and welcome to the Terms and Conditions of Use for Hissay, a service operated
					by Hissay Pvt. Ltd. Please read this document carefully as it impacts and affects
					your legal rights. Take the time to read our other documents (for example, our
					Privacy Policy) referenced in this document to know what you are agreeing to by
					using our services and how we make careful use of your data. Once you’ve read it all,
					let’s make you into a real property investor.
				</p>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>1. Terms of Service</h2>
					<p>
						Thanks for choosing Hissay (“Hissay”, “we”, “us”, or “our”). By using the Hissay
						services (collectively, the “Hissay Service”, “Service”, or “Services”) or the
						website (“Site“) or the applications for mobile, tablet, or other smart devices
						(collectively, the “Application“) or by accessing any content or material that is
						made available by Hissay through the Site, Application or Service (the “Content”)
						you agree to comply with and be legally bound by the terms and conditions of these
						Terms and Conditions of Use (“Terms“), whether or not you become a registered user
						of the Services. Your agreement includes the Terms and our Privacy Policy (collectively,
						“Agreements”) and governs your access to and use of the Site, Application and Services,
						and constitutes a binding legal agreement between you and Hissay.
					</p>
					<p>
						You acknowledge that you have read and understood the Agreements, accept these
						Agreements, and agree to be bound by them. If you don’t agree with or cannot comply with
						the Agreements, then you have no right to use the Site, Application or Services. Failure
						to use the Site, Application or Services in accordance with these Terms may subject you
						to civil and criminal penalties.
					</p>
					<p>
						<br />
						**
						<br />
						<br />
						Please read the Agreements carefully. The Agreements include information about a class
						action waiver, resolution of disputes by arbitration instead of in court, future changes
						to the Agreements, limitations of liability, and privacy information.
						<br />
						<br />
						**
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>2. Modifications to the Agreements</h2>
					<p>
						We reserve the right to, at any time and for any reason, modify the Site, Application,
						Services or these Terms. We may do this for a variety of reasons including to reflect
						changesin or requirements of the law, new features, or changes in business practices. If
						we modify these Terms, we will post the modification on the Site or via the Application
						and/or provide you notice of the modification by email. We will also update the “Last
						Updated” date at the top of these Terms. Changes to the Terms will be effective at the
						time of posting. If you continue to use or access the Site, Application or Services
						after the changes are effective then you shall be deemed to have accepted those changes.
						If the modified Terms are not acceptable to you, you must cease using the Site,
						Application and Services and must terminate the Agreements by contacting us via email at
						<span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>3. Eligibility</h2>
					<p>
						In order to use the Site, Application or Services you (1) represent and warrant to us
						that you are 18 years of age or older and that your use of the Site, Application or
						Services does not violate any applicable law (2) have the power to enter a binding
						contract with us and not be barred from doing so under any applicable laws, and (3) be
						resident in a country where the the Site, Application and Services are available. You
						also promise that any registration information that you submit to Hissay is true,
						accurate and complete, and that you will update such information to keep it accurate
						and up to date at all times.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>4. Creating an Account</h2>
					<p>
						To use Hissay, you must already have or you must create an account (“Hissay Account”)
						by providing your email address. By providing us an email address and password combo,
						you become a user of our Site, Application and Services (“Member”) and warrant to us
						that you have authorized access to the email address used. By creating an account you
						give us permission to access, use, and save information about yourself, including your
						usage of the Service. warrant to us that you have authorized access to the email address
						used. We reserve the right to suspend or terminate your Hissay Account and your access
						to the Site, Application and Services if you violate the Agreements, in which case any
						valuable assets owned by your account will be sold at what we determine to be market
						price. This price is not up for review or modification or appeal. You are responsible
						for maintaining the confidentiality of the login credentials you use to sign up for
							Hissay. You are solely responsible for all activities that occur under those
						credentials. You agree to immediately notify the Company of any disclosure or
						unauthorized use of your login credentials at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>
					</p>
					<p>
						If at any point you decide that you would like to discontinue your use of the Service,
						please send an email to <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span> with the subject line “Account Deactivation”.
						After deactivating your account, you will no longer be able to track your shares but
						they will still be retained by your user account. If you sign in to the Service again,
						your account will be reactivated and you will have to send another email to request
						another deactivation. If you determine that you would like to permanently delete your
						account, send an email to <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span> with the subject line “Account Deletion”.
						This will result in an admin liquidating your shares at the market price, initiating a
						transfer of the funds from the transaction into your assicuated bank account. After
						deleting your account, your membership will cease. Please allow at least 30 days for
						complete account deletion. Account deletion does not exempt you from the effective
						terms you agreed to during the duration of being a Member of the Service.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>5. Our Services</h2>
					<p>
						Hissay is a service where a Member may transact on various kinds of real estate assets.
						A Member may also create various lists, and have conversations with other Members
						Based on this information and based on information collected from sources the Member
						has given us permission to access, we provide recommendations on what assets the Member
						may want to purchase shares in. Certain features of the Services are free of charge,
						while other Services may require payments.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>6. The Right We Grant You</h2>
					<p>
						The Services and the Content are the property of Hissay or Hissay's licensors. We grant
						you a limited, non-exclusive, revocable license to make use of the Services, and a
						limited, non-exclusive, revocable license to make personal, non-commercial,
						entertainment use of the Content (the “License”). This License shall remain in
						effect until and unless terminated by you or Hissay.
					</p>
					<p>
						Members promise and agree that they may not use the Services or any Content contained
						in the Services in connection with any commercial endeavors, such as advertising or
						soliciting any Member to buy or sell any products or services not offered by Hissay.
						Members of the Services may not use any information obtained from the Services to
						contact, advertise to, solicit, or sell to any other user without his or her prior
						explicit consent. Organizations, companies, and/or businesses may not use the Services
						for any purpose except with Hissay’s express consent (such as for any kind of
						advertisements), which Hissay may provide or deny in its sole discretion. We may
						investigate and take any available legal action in response to illegal and/or
						unauthorized uses of the Services.
					</p>
					<p>
						The Services and the Content are licensed, not sold, to you, and Hissay and its
						licensors retain ownership of all copies of the Hisasy software applications and
						Content even after installation on your personal computers, mobile handsets, tablets,
						and/or other relevant devices (“Devices”).
					</p>
					<p>
						Alag aasmaan
						All Hissay trademarks, service marks, trade names, logos, domain names, and any other
						features of the Hissay brand (“Hissay Brand Features”) are the sole property of Hissay
						or its licensors. The Agreements do not grant you any rights to use any Hissay Brand
						Features whether for commercial or non-commercial use.
					</p>
					<p>
						You agree to abide by our User guidelines and not to use the Services, the Content, or
						any part thereof in any manner not expressly permitted by the Agreements. Except for
						the rights expressly granted to you in these Agreements, Hissay grants no right,
						title, or interest to you in the Services or the Content.
					</p>
					<p>
						<br />
						**
						<br />
						<br />
						Third party software (for example, open source software libraries) included in the
						Hissay Service are licensed to you either under the Agreements or under the relevant
						third party software library’s license terms as published in the help or settings
						section of our desktop and mobile client and/or on our website.
						<br />
						<br />
						**
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>7. Third Party Applications</h2>
					<p>
						The Hissay Service is integrated with third party applications, websites, and services
						(“Third Party Applications”) to make available content, products, and/or services to
						you. These Third Party Applications may have their own terms and conditions of use and
						privacy policies and your use of these Third Party Applications will be governed by and
						subject to such terms and conditions and privacy policies. You understand and agree that
						Hissay does not endorse and is not responsible or liable for the behavior, features, or
						content of any Third Party Application or for any transaction you may enter into with
						the provider of any such Third Party Applications.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>8. User-Generated Content</h2>
					<p>
						Hissay users may create property listing on the Service (which may include, for example,
						pictures, text, messages, property information, and/or other types of content) (“User
						Content” or “Property Listing”).
					</p>
					<p>
						You promise that, with respect to any User Content you post on Hissay, (1) you have the
						right to post such User Content, and (2) you have ownership rights to the property that
						you are listing and (3) such User Content, or its use by Hissay as contemplated by the
						Agreements, does not violate the Agreements, applicable law, or the intellectual
						property (including without limitation copyright), publicity, personality, or other
						rights of others or imply any affiliation with or endorsement of you or your User
						Content by Hissay or any label, actor, production studio, entity or individual without
						express written consent from such individual or entity.
					</p>
					<p>
						Hissay may, but has no obligation to monitor, review, or edit User Content. In all
						cases, Hissay reserves the right to remove or disable access to any User Content for
						any or no reason, including but not limited to, User Content that, in Hissay’s sole
						discretion, violates the Agreements. Hissay may take these actions without prior
						notification to you or any third party. Removal or disabling of access to User Content
						shall be at our sole discretion, and we do not promise to remove or disable access to
						any specific User Content.
					</p>
					<p>
						You are solely responsible for all User Content that you post. Hissay is not responsible
						for User Content nor does it endorse any opinion contained in any User Content. YOU
						AGREE THAT IF ANYONE BRINGS A CLAIM AGAINST HISSAY RELATED TO USER CONTENT THAT YOU
						POST, THEN, TO THE EXTENT PERMISSIBLE UNDER LOCAL LAW, YOU WILL INDEMNIFY AND HOLD
						HISSAY HARMLESS FROM AND AGAINST ALL DAMAGES, LOSSES, AND EXPENSES OF ANY KIND
						(INCLUDING REASONABLE ATTORNEY FEES AND COSTS) ARISING OUT OF SUCH CLAIM.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>9. Rights You Grant Us</h2>
					<p>
						In consideration for the rights granted to you under the Agreements, you grant us the
						right (1) to allow the Services to use the processor, bandwidth, and storage hardware
						on your Device in order to facilitate the operation of the Services, (2) to provide
						advertising and other information to you, and (3) to allow our business partners to do
						the same. In any part of the Services, the Content you view, including its selection
						and placement, may be influenced by commercial considerations, including agreements
						with third parties. Some Content licensed or provided to Hissay may contain advertising
						as part of the Content. In such cases, Hissay will make such Content available to
						you unmodified.
					</p>
					<p>
						If you provide feedback, ideas or suggestions to Hissay in connection with the Services
						or Content (“Feedback”), you acknowledge that the Feedback is not confidential and you
						authorize Hissay to use that Feedback without restriction and without payment to you.
						Feedback is considered a type of User Content.
					</p>
					<p>
						You grant Hissay a non-exclusive, transferable, sub-licensable, royalty-free, perpetual
						(or, in jurisdictions where this is not permitted, for a term equal to the duration of
						the Agreements plus twenty (20) years), irrevocable, fully paid, worldwide license to
						use, reproduce, make available to the public (e.g. perform or display), publish,
						translate, modify, create derivative works from, and distribute any of your User Content
						in connection with the Service through any medium, whether alone or in combination with
						other content or materials, in any manner and by any means, method or technology,
						whether now known or hereafter created. Aside from the rights specifically granted
						herein, you retain ownership of all rights, including intellectual property rights, in
						the User Content. Where applicable and permitted under applicable law, you also agree
						to waive any “moral rights” (or the equivalent under applicable law) such as your right
						to be identified as the author of any User Content, including Feedback, and your right
						to object to derogatory treatment of such User Content.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>10. User Guidelines</h2>
					<p>
						Hissay respects intellectual property rights and expects you to do the same. We’ve
						established a few ground rules for you to follow when using the Service, to make sure
						Hissay stays enjoyable for everyone. Please follow these rules and encourage other users
						to do the same.
					</p>
					<br />
					<p>
						The following is not permitted for any reason whatsoever:
					</p>
					<ul>
						<li>
							copying, redistributing, reproducing, recording, transferring, or displaying to the
							public, broadcasting, or making available to the public any part of the Services or
							the Content, or otherwise making any use of the Services or the Content which is not
							expressly permitted under the Agreements or applicable law or which otherwise
							infringes the intellectual property rights (such as copyright) in the Services or
							the Content or any part of it;
						</li>
						<li>
							reverse-engineering, decompiling, disassembling, modifying, or creating derivative
							works based on the Services, Content or any part thereof unless permitted by
							applicable law;
						</li>
						<li>
							circumventing any technology used by Hissay, its licensors, or any third party to
							protect the Content or the Service;
						</li>
						<li>
							selling, renting, sublicensing or leasing of any part of the Hissay Service or the
							Content;
						</li>
						<li>
							circumventing any territorial restrictions applied by Hissay or it licensors;
						</li>
						<li>
							manipulating the Services by using a script or other automated process;
						</li>
						<li>
							removing or altering any copyright, trademark, or other intellectual property
							notices contained on or provided through the Services (including for the purpose of
							disguising or changing any indications of the ownership or source of any Content);
						</li>
						<li>
							providing your password to any other person or using any other person’s username and
							password;
						</li>
						<li>
							“crawling” the Hissay Service or otherwise using any automated means (including bots,
							scrapers, and spiders) to collect information from Hissay; or
						</li>
						<li>
							selling a user account or otherwise accepting any compensation, financial or
							otherwise, to influence the name of an account or the content included on an account.
						</li>
					</ul>
					<p>
						Please respect Hissay, the owners of the Content, and other users of the Services.
						Don’t engage in any activity, post any User Content, or register and/or use a username,
						which is or includes material that:
					</p>
					<ul>
						<li>
							is offensive, abusive, defamatory, pornographic, threatening, or obscene;
						</li>
						<li>
							is illegal, or intended to promote or commit an illegal act of any kind, including
							but not limited to violations of intellectual property rights, privacy rights, or
							proprietary rights of Hissay or a third party;
						</li>
						<li>
							includes your password or purposely includes any other user’s password or purposely
							includes personal data of third parties or is intended to solicit such personal data;
						</li>
						<li>
							includes malicious content such as malware, Trojan horses, or viruses, or otherwise
							interferes with any user’s access to the Service;
						</li>
						<li>
							is intended to or does harass or bully other users;
						</li>
						<li>
							impersonates or misrepresents your affiliation with another user, person, or entity,
							or is otherwise fraudulent, false, deceptive, or misleading;
						</li>
						<li>
							uses automated means to artificially promote content;
						</li>
						<li>
							involves the transmission of unsolicited mass mailings or other forms of spam
							(“spam”), junk mail, chain letters, or similar;
						</li>
						<li>
							involves commercial or sales activities, such as advertising, promotions, contests,
							sweepstakes, or pyramid schemes, that are not expressly authorized by Hissay;
						</li>
						<li>
							links to, references, or otherwise promotes commercial products or services, except
							as expressly authorized by Hissay;
						</li>
						<li>
							interferes with or in any way disrupts the Hissay Service, tampers with, breaches,
							or attempts to probe, scan, or test for vulnerabilities in the Service or Hissay’s
							computer systems, network, usage rules, or any of Hissay’s security components,
							authentication measures or any other protection measures applicable to the Service,
							the Content or any part thereof; or
						</li>
						<li>
							conflicts with the Agreements, as determined by Hissay.
						</li>
					</ul>
					<p>
						You acknowledge and agree that posting any such User Content may result in immediate
						termination or suspension of your Hissay account. You also agree that Hissay may also
						reclaim your username for any reason.
					</p>
					<p>
						Please be thoughtful about how you use the Services and what you share. The Services
						includes social and interactive features, including the ability to post User Content,
						share content, and make certain information about you public. Remember that shared or
						publicly available information may be used and re-shared by other users on Hissay or
						across the web, so please use Hissay carefully and be mindful of your account settings.
						Hissay has no responsibility for your choices to post material on the Service.
					</p>
					<p>
						Your password protects your user account, and you are solely responsible for keeping
						your password confidential and secure. You understand that you are responsible for all
						use of your username and password on the Service. If your username or password is lost
						or stolen, or if you believe there has been unauthorized access to your account by
						third parties, please notify us immediately and change your password as soon as
						possible.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>11. Infringement and reporting User Content</h2>
					<p>
						Hissay respects the rights of intellectual property owners. If you believe that any
						Content infringes your intellectual property rights or other rights, please see the
						Copyright section of the Agreement. If Hissay is notified by a copyright holder that
						any Content infringes a copyright, Hissay may in its absolute discretion take actions
						without prior notification to the provider of that Content. If the provider believes
						that the content is not infringing, the provider may submit a counter-notification to
						Hissay with a request to restore the removed content.
					</p>
					<p>
						If you believe that any Content does not comply with the User guidelines, please email
						us at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>12. Service limitations and modifications</h2>
					<p>
						Hissay will make reasonable efforts to keep the Services operational. However, certain
						technical difficulties or maintenance may, from time to time, result in temporary
						interruptions. To the extent permissible under applicable law, Hissay reserves the
						right, periodically and at any time, to modify or discontinue, temporarily or
						permanently, functions and features of the Services, with or without notice, all
						without liability to you, except where prohibited by law, for any interruption,
						modification, or discontinuation of the Hissay Service or any function or feature
						thereof.
					</p>
					<p>
						You understand, agree, and accept that Hissay has no obligation to maintain, support,
						upgrade, or update the Service, or to provide all or any specific content through the
						Service. This section will be enforced to the extent permissible by applicable law.
						Hissay and/or the owners of any Content may, from time to time, remove any such Content
						without notice to the extent permitted by applicable law.
					</p>
					<p>
						To protect the integrity of the Service, the Company reserves the right at any time in
						its sole discretion to block users from certain IP addresses from accessing the Service.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>13. Customer support</h2>
					<p>
						For customer support with account-related questions (“Customer Support Queries”), please
						send an email to our support department at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>. We will use reasonable
						endeavors to respond to all Customer Support Queries within a reasonable time frame but
						we make no guarantees or warranties of any kind that any Customer Support Queries will
						be responded to within any particular time frame and/or that we will be able to
						satisfactorily answer any such queries.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>14. Term and termination</h2>
					<p>
						The Agreements will continue to apply to you until terminated by either you or Hissay.
						However, you acknowledge and agree that the perpetual license granted by you in
						relation to User Content, including Feedback, is irrevocable and will therefore
						continue after expiry or termination of any of the Agreements for any reason. Hissay
						may terminate the Agreements or suspend your access to the Services at any time,
						including in the event of your actual or suspected unauthorized use of the Hissay
						Service and/or Content, or non-compliance with the Agreements. If you or Hissay
						terminate the Agreements, or if Hissay suspends your access to the Hissay Service, you
						agree that Hissay shall have no liability or responsibility to you and Hissay will not
						refund any amounts that you have already paid, to the fullest extent permitted under
						applicable law. To learn how to terminate your Hissay account, please contact us via
						email at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>. This section will be enforced to the extent permissible by
						applicable law. You may terminate the Agreements at any time.
					</p>
					<p>8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20</p>
					<p>
						Sections 6, 7, 8, 10, 14, 16, 17, 18, 19, 20, 21, 22, 23, and 24 herein, as well as
						any other sections of the Agreements that, either explicitly or by their nature, must
						remain in effect even after termination of the Agreements, shall survive termination.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>15. Warranty and disclaimer</h2>
					<p>
						WE ENDEAVOUR TO PROVIDE THE BEST SERVICE WE CAN, BUT YOU UNDERSTAND AND AGREE THAT THE
						HISSAY SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE”, WITHOUT EXPRESS OR IMPLIED
						WARRANTY OR CONDITION OF ANY KIND. YOU USE THE HISSAY SERVICE AT YOUR OWN RISK. TO THE
						FULLEST EXTENT PERMITTED BY APPLICABLE LAW, HISSAY AND ALL OWNERS OF THE CONTENT MAKE
						NO REPRESENTATIONS AND DISCLAIM ANY WARRANTIES OR CONDITIONS OF SATISFACTORY QUALITY,
						MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NEITHER HISSAY
						NOR ANY OWNER OF CONTENT WARRANTS THAT THE HISSAY SERVICE IS FREE OF MALWARE OR OTHER
						HARMFUL COMPONENTS. IN ADDITION, HISSAY MAKES NO REPRESENTATION NOR DOES IT WARRANT,
						ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY THIRD PARTY APPLICATIONS (OR THE
						CONTENT THEREOF), USER CONTENT, OR ANY OTHER PRODUCT OR SERVICE ADVERTISED OR OFFERED
						BY A THIRD PARTY ON OR THROUGH THE HISSAY SERVICE OR ANY HYPERLINKED WEBSITE, OR
						FEATURED IN ANY BANNER OR OTHER ADVERTISING. YOU UNDERSTAND AND AGREE THAT HISSAY IS
						NOT RESPONSIBLE OR LIABLE FOR ANY TRANSACTION BETWEEN YOU AND THIRD PARTY PROVIDERS OF
						THIRD PARTY APPLICATIONS OR PRODUCTS OR SERVICES ADVERTISED ON OR THROUGH THE HISSAY
						SERVICE. AS WITH ANY PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
						ENVIRONMENT, YOU SHOULD USE YOUR JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. NO
						ADVICE OR INFORMATION WHETHER ORAL OR IN WRITING OBTAINED BY YOU FROM HISSAY SHALL
						CREATE ANY WARRANTY ON BEHALF OF SPOTIFY IN THIS REGARD. SOME ASPECTS OF THIS SECTION
						MAY NOT APPLY IN SOME JURISDICTIONS IF PROHIBITED BY APPLICABLE LAW. THIS DOES NOT
						AFFECT YOUR STATUTORY RIGHTS AS A CONSUMER.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>16. Limitation</h2>
					<p>
						YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE
						REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE HISSAY SERVICE IS TO UNINSTALL
						ANY HISSAY SOFTWARE AND TO STOP USING THE SERVICES. WHILE HISSAY ACCEPTS NO
						RESPONSIBILITY FOR THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF, AND WHILE YOUR
						RELATIONSHIP WITH SUCH THIRD PARTY APPLICATIONS MAY BE GOVERNED BY SEPARATE
						AGREEMENTS WITH SUCH THIRD PARTIES, TO THE EXTENT PERMITTED BY APPLICABLE LAW,
						YOUR SOLE AND EXCLUSIVE REMEDY, AS WITH RESPECT TO HISSAY, FOR ANY PROBLEMS OR
						DISSATISFACTION WITH THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF, IS TO
						UNINSTALL AND/OR STOP USING ANY SUCH THIRD PARTY APPLICATIONS.
					</p>
					<p>
						TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL HISSAY, ITS OFFICERS,
						SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS,
						ASSIGNS, SUPPLIERS, OR LICENSORS BE LIABLE FOR (1) ANY INDIRECT, SPECIAL, INCIDENTAL,
						PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES; (2) ANY LOSS OF USE, DATA, BUSINESS,
						OR PROFITS (WHETHER DIRECT OR INDIRECT), IN ALL CASES ARISING OUT OF THE USE OR
						INABILITY TO USE THE HISSAY SERVICE, THIRD PARTY APPLICATIONS, OR THIRD PARTY
						APPLICATION CONTENT, REGARDLESS OF LEGAL THEORY, WITHOUT REGARD TO WHETHER HISSAY HAS
						BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS
						ESSENTIAL PURPOSE; OR (3) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE HISSAY
						SERVICE, THIRD PARTY APPLICATIONS, OR THIRD PARTY APPLICATION CONTENT MORE THAN THE
						AMOUNTS PAID BY YOU TO HISSAY DURING THE PRIOR TWELVE MONTHS IN QUESTION, TO THE
						EXTENT PERMISSIBLE BY APPLICABLE LAW.
					</p>
					<p>
						Nothing in the Agreements removes or limits Hissay’s liability for fraud, fraudulent
						misrepresentation, death or personal injury caused by its negligence, and, if required
						by applicable law, gross negligence. Some aspects of this section may not apply in
						some jurisdictions if prohibited by applicable law.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>17. Third party rights</h2>
					<p>
						You acknowledge and agree that the owners of the Content and certain distributors
						(such as app store providers) are intended beneficiaries of the Agreements and have
						the right to enforce the Agreements directly against you. Other than as set out in
						this section, the Agreements are not intended to grant rights to anyone except you
						and Hissay, and in no event shall the Agreements create any third party beneficiary
						rights. Furthermore, the rights to terminate, rescind, or agree to any variation,
						waiver, or settlement of the Agreements are not subject to the consent of any other
						person.
					</p>
					<p>
						If you have downloaded the App from the Apple, Inc. (“Apple”) App Store or if you
						are using the App on an iOS device, you acknowledge that you have read, understood,
						and agree to the following notice regarding Apple. This Agreement is between you
						and Hissay only, not with Apple, and Apple is not responsible for the Service and
						the content thereof. Apple has no obligation whatsoever to furnish any maintenance
						and support services with respect to the Service. In the event of any failure of
						the Service to conform to any applicable warranty, then you may notify Apple and
						Apple will refund any applicable purchase price for the App to you; and, to the
						maximum extent permitted by applicable law, Apple has no other warranty obligation
						whatsoever with respect to the Service. Apple is not responsible for addressing any
						claims by you or any third party relating to the Service or your possession and/or
						use of the Service, including, but not limited to: (1) product liability claims;
						(2) any claim that the Service fails to conform to any applicable legal or
						regulatory requirement; and (3) claims arising under consumer protection or similar
						legislation. Apple is not responsible for the investigation, defense, settlement and
						discharge of any third party claim that the Service and/or your possession and use of
						the App infringe that third party’s intellectual property rights. You agree to comply
						with any applicable third party terms, when using the Service. Apple, and Apple’s
						subsidiaries, are third party beneficiaries of this Agreement, and upon your
						acceptance of this Agreement, Apple will have the right (and will be deemed to have
							accepted the right) to enforce this Agreement against you as a third party beneficiary
						of this Agreement. You hereby represent and warrant that (1) you are not located in a
						country that is subject to a U.S. Government embargo, or that has been designated by the
						U.S. Government as a “terrorist supporting” country; and (2) you are not listed on any
						U.S. Government list of prohibited or restricted parties.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>18. Entire agreement</h2>
					<p>
						Other than as stated in this section or as explicitly agreed upon in writing between you
						and Hissay, the Agreements constitute all the terms and conditions agreed upon between
						you and Hissay and supersede any prior agreements in relation to the subject matter of
						these Agreements, whether written or oral.
					</p>
					<p>
						Please note, however, that other aspects of your use of the Hissay Service may be
						governed by additional agreements. That could include, for example, access to the Hissay
						Service as a result of a gift card or free or discounted Trials. When you are presented
						with an offer for such aspects of your use, you will be presented with any related
						additional agreement, and you may have an opportunity to agree to additional terms. To
						the extent that there is any irreconcilable conflict between any additional terms and
						these Terms, the additional terms shall prevail.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>19. Severability and waiver</h2>
					<p>
						Unless as otherwise stated in the Agreements, should any provision of the Agreements be
						held invalid or unenforceable for any reason or to any extent, such invalidity or
						enforceability shall not in any manner affect or render invalid or unenforceable the
						remaining provisions of the Agreements, and the application of that provision shall be
						enforced to the extent permitted by law.
					</p>
					<p>
						Any failure by Hissay or any third party beneficiary to enforce the Agreements or any
						provision thereof shall not waive Hissay’s or the applicable third party beneficiary’s
						right to do so.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>20. Assignment</h2>
					<p>
						Hissay may assign the Agreements or any part of them, and Hissay may delegate any of its
						obligations under the Agreements. You may not assign the Agreements or any part of them,
						nor transfer or sub-license your rights under the Agreements, to any third party.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>21. Indemnification</h2>
					<p>
						To the fullest extent permitted by applicable law, you agree to indemnify and hold
						Hissay harmless from and against all damages, losses, and expenses of any kind
						(including reasonable attorney fees and costs) arising out of: (1) your breach of this
						Agreement; (2) any User Content; (3) any activity in which you engage on or through the
						Hissay Service; and (4) your violation of any law or the rights of a third party.
					</p>
					<p>
						Any contests held on Hissay will be subject to the specific rules, terms & conditions
						for that specific contest. In the case where contestants are deemed in violation of
						contest rules, Hissay reserves the right to disqualify entrants or winners.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>Choice of law, mandatory arbitration and venue</h2>
					<p>
						These Terms and your use of the Services will be interpreted in accordance with the laws
						of the State of California and the United States of America, without regard to its
						conflict-of-law provisions. You and we agree to submit to the personal jurisdiction of a
						state court located in San Francisco County, San Francisco, California or a United
						States District Court, Northern District of California located in San Francisco,
						California for any actions for which the parties retain the right to seek injunctive or
						other equitable relief in a court of competent jurisdiction to prevent the actual or
						threatened infringement, misappropriation or violation of a party’s copyrights,
						trademarks, trade secrets, patents, or other intellectual property rights, as set forth
						in the Dispute Resolution provision below.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>Contact us</h2>
					<p>
						If you have any questions concerning the Hissay Service or the Agreements, please
						contact Hissay customer service by visiting the About Us section of our website.
					</p>
					<p>
						Thank you for reading our Terms. We hope you enjoy Hissay!
					</p>
				</div>
			</div>
		</main>
	);
}

export { TermsScreen };
