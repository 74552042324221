import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import Lottie from 'lottie-web';
import { BuyingProperty } from './buyingProperty/buyingProperty';
import { HeroSection } from './heroSection';
import { HissayBenefits } from './hissayBenefits';
import { HowHissayWorks } from './howHissayWorks';
import { JoinCTASection } from '../joinCTASection';
import Styles from './fractionalOwnershipScreen.module.scss';
import CherryCakeLottie from '../../assets/lotties/cherryCakeLottie.json';
import { ReactComponent as FloatingHouses } from '../../assets/fractionalOwnershipScreen/floatingHouses.svg';
import { Container } from '../container';

function FractionalOwnershipScreen() {
	useEffect(() => {
		Lottie.loadAnimation({
			container: document.querySelector('#cherryCakeLottie'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: CherryCakeLottie
		});
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<HeroSection />
			<div className={Styles.sliceLottieSection}>
				<div className="py-5">
					<Container>
						<Row className="align-items-center">
							<Col xs={12} lg={6}>
								<div className={Styles.cherryCakeLottieContainer}>
									<div id="cherryCakeLottie" className={Styles.cherryCakeLottie} />
								</div>
							</Col>
							<Col xs={12} lg={6}>
								<h2 className={Styles.lottieHeading}>Some things come better in slices</h2>
							</Col>
						</Row>
					</Container>
				</div>
				<div className={Styles.floatingHouses}>
					<FloatingHouses />
				</div>
			</div>
			<HowHissayWorks />
			<HissayBenefits />
			<BuyingProperty />
			<JoinCTASection />
		</div>
	);
}

export { FractionalOwnershipScreen };
