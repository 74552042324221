import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container } from '../container';
import Styles from './knowledgeHubScreen.module.scss';
import { knowledgeHubLinks } from './knowledgeHubLinks';
import { ContactCTASection } from '../contactCTASection';

function KnowledgeHubScreen() {
	return (
		<div className="pt-5">
			<Container>
				<h1 className={Styles.pageHeading}>Hissay's Knowledge Hub</h1>
				<Row className="py-5">
					{
						knowledgeHubLinks.map((item, index) => (
							<Col xs={12} md={6} xl={4} key={`knowledge-hub-card-${index}`}>
								<Link className={Styles.knowledgeHubPageLink} to={item.link}>
									<div className={`shadow-sm ${Styles.knowledgeHubCard}`}>
										<div className={Styles.cardImageContainer}>
											<img src={item.image} alt={item.title} className={Styles.cardImage} />
										</div>
										<div className={Styles.cardContent}>
											<h4 className={Styles.cardHeading}>
												{item.title}
											</h4>
										</div>
									</div>
								</Link>
							</Col>
						))
					}
				</Row>
			</Container>
			<ContactCTASection />
		</div>
	);
}

export { KnowledgeHubScreen };
