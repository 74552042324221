import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Styles from './postedProperties.module.scss';
import { Button } from '../../../button';
import { PropertyCard } from '../../../properties/card';

export const PostedProperties = () => {
	const statusOptions = ['all', 'active', 'pending', 'rejected', 'sold'];
	const [activeStatusOption, setActiveStatusOption] = useState(statusOptions[0]);
	const [properties, setProperties] = useState([]);
	const [filteredproperties, setFilteredproperties] = useState([]);

	useEffect(() => {
		setProperties([
			{
				id: 2,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 477,
				status: 'pending',
			},
			{
				id: 2,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 240,
				status: 'rejected',
			},
			{
				id: 3,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 496,
				status: 'active',
			},
			{
				id: 3,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 496,
				status: 'pending',
			},
		]);
		setFilteredproperties([
			{
				id: 2,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 477,
				status: 'pending',
			},
			{
				id: 2,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 240,
				status: 'rejected',
			},
			{
				id: 3,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 496,
				status: 'active',
			},
			{
				id: 3,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 496,
				status: 'pending',
			},

		]);
	}, []);

	const getStatusCountInproperties = (status) => {
		if (status === 'all') {
			return properties.length;
		}

		return properties.filter((option) => option.status === status).length;
	};

	const handleStatusOption = (status) => {
		setActiveStatusOption(status);

		if (status === 'all') {
			setFilteredproperties(properties);
		} else {
			setFilteredproperties(properties.filter((option) => option.status === status));
		}
	};

	return (
		<section>
			<div className={Styles.titleContainer}>
				<h1>Posted Properties</h1>
				<h4 className="font-weight-normal">{properties.length} properties</h4>
			</div>
			<div className={Styles.sectionContentContainer}>
				<div className="w-100 d-flex align-items-start justify-content-between flex-column flex-xl-row align-items-xl-center">
					<div className={Styles.statusChipsContainer}>
						{
							statusOptions.map((statusOption, index) => (
								<div
									key={`status-chip-${index}`}
									className={`${Styles.statusOptionChip} ${statusOption === activeStatusOption && Styles.activeChip}`}
									onClick={() => handleStatusOption(statusOptions[index])}>
									{statusOption}({getStatusCountInproperties(statusOption)})
								</div>
							))
						}
					</div>
				</div>
				<Row className={Styles.cards}>
					{
						filteredproperties.map((item, index) => (
							<Col xs={12} xl={6} className="pl-0 pb-3" key={`properties-${index}`}>
								{
									item.status === 'rejected'
										? <PropertyCard property={item} showButtons={true} />
										: <PropertyCard property={item} showProgress={true} />
								}
							</Col>
						))
					}
				</Row>
				<Button variant="secondary" text="See All" />
			</div>
		</section>
	);
};
