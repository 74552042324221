import { Row, Col, Collapse } from 'react-bootstrap';
import Styles from './hissayBenefits.module.scss';
import { useHissayBenefits } from './useHissayBenefits';
import { ReactComponent as ChevronIcon } from '../../../assets/arrowDownIcon.svg';
import { Container } from '../../container';

function HissayBenefits() {
	const {
		benefitsList,
		selectBenefit,
		currentBenefit,
	} = useHissayBenefits();
	return (
		<div className={Styles.sectionWrapper}>
			<div className="py-5">
				<Container>
					<h2 className={Styles.sectionHeading}>
                    Benefits
					</h2>
					<div className="w-100 d-none d-lg-block">
						<Row className="justify-content-between">
							<Col lg={5}>
								{benefitsList.map((benefit, index) => (
									<div className={`${Styles.benefitsTag} ${currentBenefit === index ? Styles.currentBenefitsTag : ''}`} key={`benefit-tag-${index}`} onClick={() => selectBenefit(index)}>
										<div className={`${Styles.tagIconContainer} ${currentBenefit === index ? Styles.currentTagIconContainer : ''}`}>{benefit.icon}</div>
										<h5 className={Styles.tagLabel}>{benefit.title}</h5>
									</div>
								))}
							</Col>
							<Col lg={6}>
								<div className={Styles.benefitContent}>
									<h3 className={Styles.benefitTitle}>{benefitsList[currentBenefit].title}</h3>
									<p className={Styles.benefitText}>{benefitsList[currentBenefit].description}</p>
								</div>
							</Col>
						</Row>
					</div>
					<div className="w-100 d-block d-lg-none">
						{
							benefitsList.map((benefit, index) => (
								<div className={Styles.benefitAccordionItem} key={`benefit-accordion-${index}`}>
									<button className={`${Styles.accordionButton} ${currentBenefit === index ? Styles.currentAccordion : ''}`} onClick={() => selectBenefit(index)}>
										<h3 className="m-0">{benefit.title}</h3>
										<ChevronIcon
											className={`${Styles.accordionIcon} ${currentBenefit === index ? Styles.currentAccordionIcon : ''}`}
											stroke={currentBenefit === index ? 'white' : 'black'}
										/>
									</button>
									<Collapse in={currentBenefit === index}>
										<p className={Styles.accordionBody}>{benefit.description}</p>
									</Collapse>
								</div>
							))
						}
					</div>
				</Container>
			</div>
		</div>
	);
}

export { HissayBenefits };
