export const banksData = [
	{
		id: 1,
		name: 'National Bank of Pakistan'
	},
	{
		id: 2,
		name: 'Habib Bank Limited (HBL)'
	},
	{
		id: 3,
		name: 'United Bank Limited (UBL)'
	},
	{
		id: 4,
		name: 'MCB Bank Limited (Muslim Commercial Bank)'
	},
	{
		id: 5,
		name: 'Allied Bank Limited (ABL)'
	},
	{
		id: 6,
		name: 'Bank Alfalah Limited'
	},
	{
		id: 7,
		name: 'Faysal Bank Limited'
	},
	{
		id: 8,
		name: 'Askari Bank Limited'
	},
	{
		id: 9,
		name: 'Bank Al-Habib Limited'
	},
	{
		id: 10,
		name: 'Meezan Bank Limited'
	},
	{
		id: 11,
		name: 'Soneri Bank Limited'
	},
	{
		id: 12,
		name: 'Standard Chartered Bank (Pakistan) Limited'
	},
	{
		id: 13,
		name: 'Sindh Bank Limited'
	},
	{
		id: 14,
		name: 'Summit Bank (formerly Atlas Bank Limited)'
	},
	{
		id: 15,
		name: 'BankIslami Pakistan Limited'
	},
	{
		id: 16,
		name: 'JS Bank Limited'
	},
	{
		id: 17,
		name: 'The Bank of Khyber'
	},
	{
		id: 18,
		name: 'The Bank of Punjab'
	},
	{
		id: 19,
		name: 'Silkbank Limited'
	},
	{
		id: 20,
		name: 'NIB Bank (merged with MCB Bank)'
	},
	{
		id: 21,
		name: 'KASB Bank (merged with BankIslami)'
	},
	{
		id: 22,
		name: 'Burj Bank (merged with Summit Bank)'
	},
	{
		id: 23,
		name: 'Saudi Pak Bank (merged with Habib Bank)'
	},
	{
		id: 24,
		name: 'EasyPaisa'
	},
	{
		id: 25,
		name: 'JazzCash'
	},
	{
		id: 26,
		name: 'SadaPay'
	}
];
