import Styles from './spinner.module.scss';

const Spinner = ({ className }) => {
	return (
		<div className={`${Styles.container} ${className}`}>
			<span className={Styles.loader}></span>
		</div>
	);
};

export { Spinner };
