import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sidebarLinks } from './sidebarLinks';
import { Sidebar } from './sidebar';
import { ReactComponent as WarningIcon } from '../../../assets/warningCircleIcon.svg';
import { Alert } from '../../alert';
import Styles from './profileScreen.module.scss';

export const ProfileScreen = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [sidebarVisible, setSidebarVisible] = useState(false);
	const newQueryParameters = new URLSearchParams();
	const activeLink = sidebarLinks.find((link) => link.route === searchParams.get('section'));
	const activeSection = activeLink ? activeLink.component : sidebarLinks[0].component;

	const updateSearchParams = (name) => {
		newQueryParameters.set('section', name);

		setSearchParams(newQueryParameters);
		setSidebarVisible(false);
	};

	const toggleSidebar = () => {
		setSidebarVisible(!sidebarVisible);
	};

	return (
		<section>
			<div className={Styles.container}>
				<div className={Styles.sidebarToggleButton} onClick={toggleSidebar}>
					<span className={`${Styles.toggleIcon} ${sidebarVisible ? Styles.closeIcon : ''}`} />
				</div>
				<Sidebar
					activeLink={activeLink}
					sidebarVisible={sidebarVisible}
					updateSearchParams={updateSearchParams}
				/>
				<article className={Styles.contentContainer}>
					<Alert title="Account Verification Pending" leftIcon={<WarningIcon />} canHide={true}>
						<div>We couldn't verify your account mail us at gmail.com</div>
					</Alert>
					{activeSection}
				</article>
			</div>
		</section>
	);
};
