import { useEffect, useState } from 'react';

export function useMediaQuery(query) {
	const [matches, setMatches] = useState(false);

	function handleChange() {
		setMatches(window.matchMedia(query).matches);
	}

	useEffect(() => {
		const matchMedia = window.matchMedia(query);

		handleChange();

		matchMedia.addEventListener('change', handleChange);

		return () => {
			matchMedia.removeEventListener('change', handleChange);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	return matches;
}
