import { useState } from 'react';
import { ReactComponent as OpportunityIcon } from '../../../assets/fractionalOwnershipScreen/opportunityIcon.svg';
import { ReactComponent as RiskIcon } from '../../../assets/fractionalOwnershipScreen/riskIcon.svg';
import { ReactComponent as AffordableIcon } from '../../../assets/fractionalOwnershipScreen/affordableIcon.svg';
import { ReactComponent as IncomeIcon } from '../../../assets/fractionalOwnershipScreen/incomeIcon.svg';
import { ReactComponent as SharedCostIcon } from '../../../assets/fractionalOwnershipScreen/sharedCostIcon.svg';
import Styles from './hissayBenefits.module.scss';

export const useHissayBenefits = () => {
	const [currentBenefit, setCurrentBenefit] = useState(0);

	const benefitsList = [
		{
			title: 'Safe & Affordable',
			icon: <AffordableIcon stroke={currentBenefit === 0 ? '#457EAC' : '#FFF'} className={Styles.tagIcon} />,
			description: 'We verify your properties by checking NOCs, zoning records, and verifying property ownership files. That combined with co-ownership makes property ownership both safe and now affordable as well'
		},
		{
			title: 'Diversified Risk',
			icon: <RiskIcon stroke={currentBenefit === 1 ? '#457EAC' : '#FFF'} className={Styles.tagIcon} />,
			description: 'Have you ever heard of the phrase, "never put all of your eggs in one basket?" Risk diversification means you can spread your money around so that if one property is doing better than others, you still have benefits to pocket! Why buy just one property when you can buy Hissay in many properties?!'
		},
		{
			title: 'Potential Rental Income',
			icon: <IncomeIcon stroke={currentBenefit === 3 ? '#457EAC' : '#FFF'} className={Styles.tagIcon} />,
			description: 'Who doesn\'t like a little extra cash in their pocket every month? Along with rising property values, you get to watch as we do the work to get your property rented and then simply deposit your share of the rent into your account. It really is that simple.'
		},
		{
			title: 'Stress-free Tenant Management',
			icon: <OpportunityIcon stroke={currentBenefit === 2 ? '#457EAC' : '#FFF'} className={Styles.tagIcon} />,
			description: 'Dealing with people can be tough sometimes, especially when money is involved. Well now you never have to interface with tenants ever again. We find tenants. We verify their source of income. We verify their identity. We verify that your investment property is being used legitimately. All you have to do is watch your share of monthly rent come into your bank account!'
		},
		{
			title: 'Shared Maintenance Costs',
			icon: <SharedCostIcon stroke={currentBenefit === 4 ? '#457EAC' : '#FFF'} className={Styles.tagIcon} />,
			description: 'Things inevitably need repairs and general maintenance, especially when tenants are occupying your property. These sudden expenses can add up and hurt your wallet but with our system, you never have to feel burdened again. We take costs from maintenance and generalize them over an annual basis. That way. if you sell your share early, you are not paying for the maintenance of someone else\'s asset. With maintenance costs split across owners and divided over the year, you won\'t even notice them!'
		}
	];

	const selectBenefit = (index) => {
		if (index >= 0 && index < benefitsList.length) {
			setCurrentBenefit(index);
		}
	};

	return {
		benefitsList,
		selectBenefit,
		currentBenefit,
	};
};
