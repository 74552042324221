import { useEffect } from 'react';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import Styles from './shareSocialsModal.module.scss';
import {
	FacebookIcon,
	TwitterIcon,
	EmailIcon,
	WhatsappIcon,
	LinkedinIcon
} from '../../../../assets/propertyCard';

const ShareSocialsModal = ({ url, innerRef, modalHandler }) => {
	const [copiedText, copy] = useCopyToClipboard();
	const isMobile = useMediaQuery('(max-width: 576px)');
	const socialShareMessage = `Check out this amazing property you might be interested in investing in on Hissay. ${url}`;

	useEffect(() => {
		function handleClickOutside(event) {
			if (innerRef.current && !innerRef.current?.contains(event.target)) {
				modalHandler(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={Styles.shareContainer} ref={innerRef}>
			<div className={Styles.copyField}>
				<input
					type="text"
					value={url}
					onChange={() => null}
					className={`${Styles.copyText} ${copiedText ? Styles.highlight : null}`} />
				<button
					disabled={copiedText}
					className={Styles.copyButton}
					onClick={() => copy(url)}>
					{copiedText ? 'Copied' : 'Copy'}
				</button>
			</div>
			<div className={Styles.socialIcons}>
				<img src={FacebookIcon} alt="facebook" width={38} />
				<img src={EmailIcon} alt="email" width={38} />
				<a
					href={
						isMobile
							? `whatsapp://send?text=${socialShareMessage}`
							: `https://web.whatsapp.com/send?text=${socialShareMessage}`
					}
					rel="nofollow noopener noreferrer"
					target="_blank">
					<img src={WhatsappIcon} alt="whatsapp" width={38} />
				</a>
				<img src={LinkedinIcon} alt="linkedin" width={38} />
				<a
					href={`https://twitter.com/intent/tweet?text=${socialShareMessage}`}
					target="_blank"
					rel="noreferrer">
					<img src={TwitterIcon} alt="twitter" width={38} />
				</a>
			</div>
		</div>
	);
};

export { ShareSocialsModal };
