import Styles from './progressBar.module.scss';

export const ProgressBar = ({ total, current, note }) => {
	const progressPercentage = (current / total) * 100;
	let progressBarColor;

	if (progressPercentage <= 35) {
		progressBarColor = '#B64444';
	} else if (progressPercentage > 35 && progressPercentage <= 80) {
		progressBarColor = '#B69D44';
	} else {
		progressBarColor = '#44B672';
	}

	const progressBarInner = {
		width: `${progressPercentage}%`,
		height: '14px',
		backgroundColor: progressBarColor,
		borderRadius: '4px 0 0 4px'
	};

	return (
		<div>
			<div className={Styles.progressBar}>
				<div style={progressBarInner}></div>
			</div>
			{note && <p className={Styles.noteText}>{note}</p>}
		</div>
	);
};
