import { Link } from 'react-router-dom';
import Styles from './contactCTASection.module.scss';
import LeftSideCollage from '../../assets/knowledgeHub/cta-left-side-collage.png';
import RightSideCollage from '../../assets/knowledgeHub/cta-right-side-collage.png';
import MobileCollage from '../../assets/knowledgeHub/cta-collage-mobile.png';

function ContactCTASection() {
	return (
		<div className={Styles.contactCtaSection}>
			<div className="d-flex d-sm-none w-100 justify-content-between">
				<img src={MobileCollage} alt="Mobile Collage" className={Styles.ctaMobileCollageImage} />
			</div>
			<div className="d-none d-sm-flex w-100 justify-content-between position-absolute top-0 z-1">
				<img src={LeftSideCollage} alt="Left Side" className={Styles.ctaDesktopLeftImage} />
				<img src={RightSideCollage} alt="Right Side" className={Styles.ctaDesktopRightImage} />
			</div>
			<div className="col-lg-5 px-0 col-md-7 col-10 d-flex justfiy-content-center align-items-center flex-column z-2">
				<h2 className={Styles.contactHissayHeading}>
					If you can't find what you are looking for, <br />
					feel free to contact us
				</h2>
				<Link className={Styles.contactHissayButton} to="/contact-us">
					<h5 className="p-0 m-0">Contact Us</h5>
				</Link>
			</div>
		</div>
	);
}

export { ContactCTASection };
