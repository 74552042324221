import React from 'react';
import Styles from './landingScreen.module.scss';
import { HeroSection } from './heroSection';
import { JoinCTASection } from '../joinCTASection';

const LandingScreen = () => {
	return (
		<main className={Styles.mainContainer}>
			<HeroSection />
			<JoinCTASection />
		</main>
	);
};

export { LandingScreen };
