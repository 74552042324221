import {
	CalculatePropertyWorth,
	FrequentlyAskedQuestions,
	WhatIsFractionalOwnership,
	WhyShouldTrustHissay,
	HowDoesHissayRentalManagementWork
} from '../../assets/knowledgeHub';

export const knowledgeHubLinks = [
	{
		title: 'Caculate how much is your property worth?',
		link: 'calculate-property-worth',
		image: CalculatePropertyWorth
	},
	{
		title: 'What is Fractional Ownership?',
		link: '/fractional-ownership',
		image: WhatIsFractionalOwnership
	},
	{
		title: 'How does Hissay Rental Management Work?',
		link: 'hissay-rental-management',
		image: HowDoesHissayRentalManagementWork
	},
	{
		title: 'Why should I trust Hissay?',
		link: 'why-should-i-trust-hissay',
		image: WhyShouldTrustHissay
	},
	{
		title: 'Frequently Asked Questions',
		link: 'faq',
		image: FrequentlyAskedQuestions
	}
];
