import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { Button } from '../../button';
import { toPriceFormat, toSlashDateFormat } from '../../../utils';
import Styles from './transactionHistoryTable.module.scss';

export const TransactionHistoryTable = ({ transactions }) => {
	const [visibleRows, setVisibleRows] = useState(3);

	const loadMoreRows = () => {
		setVisibleRows(visibleRows + 5);
	};

	const OrderStatus = ({ status }) => {
		let backgroundColor;
		let textColor;

		if (status === 'Pending') {
			backgroundColor = 'rgb(255, 224, 193)';
			textColor = 'orange';
		} else if (status === 'Completed') {
			backgroundColor = 'rgb(165, 243, 165)';
			textColor = 'green';
		} else if (status === 'Cancelled') {
			backgroundColor = 'rgb(233, 179, 179)';
			textColor = 'red';
		}

		const containerStyles = {
			padding: '2px 10px',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			borderRadius: '5px',
			color: textColor,
			backgroundColor
		};

		return (
			<div style={containerStyles}>
				<p className={`m-0 p-0 ${Styles.tagText}`}>{status}</p>
			</div>
		);
	};

	return (
		<>
			<div className={`${Styles.tableContainer} containerShadow`}>
				<Table responsive className={`mb-0 ${Styles.table}`}>
					<thead className={Styles.tableHeader}>
						<tr>
							<th>Property Address</th>
							<th>Date</th>
							<th>Transaction ID</th>
							<th>Transaction Type</th>
							<th>Amount</th>
							<th>Shares</th>
							<th>Status</th>
							<th>Invoice</th>
						</tr>
					</thead>
					<tbody className={Styles.tableBody}>
						{transactions?.slice(0, visibleRows).map((transaction) => (
							<tr key={transaction.id} className="my-3">
								<td>{transaction.address}</td>
								<td>{toSlashDateFormat(transaction.date.toISOString())}</td>
								<td>{transaction.transactionID}</td>
								<td>{transaction.transactionType}</td>
								<td>Rs. {toPriceFormat(transaction.value)}</td>
								<td>{toPriceFormat(transaction.shares)}</td>
								<td><OrderStatus status={transaction.status} /></td>
								<td>
									<Link className={Styles.downloadLink} to={transaction.invoice}>
										Download
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
			{visibleRows < transactions.length && (
				<div className="text-center mt-5">
					<Button variant="secondary" styles="px-5" text="Load More" onClick={loadMoreRows} />
				</div>
			)}
		</>
	);
};
