import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { FloatingDropdownInput } from '../../../floatingDropdownInput';
import { FloatingLabelInput } from '../../../floatingLabelInput';
import { TitleBar } from '../titleBar';
import { successToast } from '../../../../utils';
import { banksData } from '../../../../utils/banksData';
import { Button } from '../../../button';
import { ReactComponent as PlusIcon } from '../../../../assets/plusIcon.svg';
import Styles from './payoutInformation.module.scss';

export const PayoutInformation = () => {
	const [payoutAdded, setPayoutAdded] = useState(false);
	const [fields, setFields] = useState({ accountTitle: '', accountNumber: '' });

	const [banks] = useState(
		banksData.map((bank) => ({ id: bank.id, value: bank.name, label: bank.name }))
	);

	const [selectedBank, setSelectedBank] = useState(banks.length > 0 ? banks[0].value : '');

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setFields((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		console.log(fields); // To Ignore Lint Warning
		successToast('Profile Updated Successfully');

		// Dispatch payout information submit
	};

	return (
		<>
			<Toaster />
			{
				!payoutAdded
					? <div className="text-center">
						<h1>Payout Information</h1>
						<p className={`${Styles.message} my-4 pt-2`}>
							You have not added any bank account to receive funds like
							rent or revenue from your share sells.<br/>
							Please add your banking info to allow money withdrawals from your Hissay transactions
						</p>
						<Button
							variant="primary"
							leftIcon={<PlusIcon />}
							text="Add Bank Account"
							onClick={() => setPayoutAdded(true)}
						/>
					</div>
					: <>
						<form onSubmit={handleSubmit}>
							<TitleBar title="Payout Information" buttonDisabled={false} />
							<Row className="mr-0 mt-5 pb-2">
								<Col xs={12} lg={6} className="pr-0">
									<FloatingDropdownInput
										id="bankName"
										label="Bank Name"
										onChange={(e) => setSelectedBank(e.target.value)}
										name="bankName"
										options={banks}
										value={selectedBank}
									/>
								</Col>
								<Col xs={12} lg={6} className="pr-0">
									<FloatingLabelInput
										label="Account Title"
										type="text"
										id="accountTitle"
										name="accountTitle"
										onChange={handleInputChange}
										required={true}
									/>
								</Col>
							</Row>
							<FloatingLabelInput
								label="Account Number"
								type="text"
								id="accountNumber"
								name="accountNumber"
								onChange={handleInputChange}
								required={true}
							/>
						</form>
					</>
			}
		</>
	);
};
