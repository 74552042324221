import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Styles from './errorScreen.module.scss';
import ErrorIllustration from '../../assets/error_404_illustration.svg';
import { SearchBar } from '../searchBar';
import { Container } from '../container';

const ErrorScreen = () => {
	return (
		<main>
			<Container className="py-5 my-lg-5">
				<Row className="flex-lg-row">
					<Col sm={12} lg={6} className="order-2 mt-3">
						<img src={ErrorIllustration} width="100%" alt="Error 404 Illustration" />
					</Col>
					<Col sm={12} lg={6} className="pr-lg-5 order-1">
						<div>
							<h2 className={Styles.errorHeading}>404</h2>
							<h1 className={Styles.notExistHeading}>This Page Does Not Exist</h1>
							<h5 className={Styles.sorryText}>
								Looks like the page you were looking for was blown away by a gust of wind!
							</h5>
							<h5 className={`${Styles.sorryText} pb-lg-5`}>
								How about we get you back to <Link to='/' className={Styles.homePageLink}>home</Link> or find you some awesome properties to invest in?
							</h5>
							<SearchBar />
						</div>
					</Col>
				</Row>
			</Container>
		</main>
	);
};

export { ErrorScreen };
