import { Container } from '../container';
import Styles from './faqScreen.module.scss';
import { ContactCTASection } from '../contactCTASection';
import { faqList } from './faqList';
import { Accordion } from '../accordion';

function FaqScreen() {
	return (
		<div className="pt-5">
			<Container>
				<h1 className={Styles.pageHeading}>Frequently Asked Questions</h1>
				<div className="py-5">
					<Accordion items={faqList} headerField="question" bodyField="answer" alwaysOpen={false} />
				</div>
			</Container>
			<ContactCTASection />
		</div>
	);
}

export { FaqScreen };
