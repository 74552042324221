import { Button } from '../../../button';
import Styles from './titleBar.module.scss';

export const TitleBar = ({ title, buttonDisabled }) => {
	return (
		<div className={Styles.titleBar}>
			<div />
			<h1 className={Styles.sectionTitle}>{title}</h1>
			<Button
				variant="primary"
				text="Update Changes"
				disabled={buttonDisabled}
			/>
		</div>
	);
};
