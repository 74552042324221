import { useSelector } from 'react-redux';
import Styles from './sidebar.module.scss';
import { sidebarLinks } from '../sidebarLinks';

export const Sidebar = ({ activeLink, sidebarVisible, updateSearchParams }) => {
	const { user } = useSelector((state) => state.auth);

	return (
		<aside className={`${Styles.sidebar} ${sidebarVisible ? Styles.show : Styles.hide}`}>
			<div className={Styles.bio}>
				<h2 className="pb-2">{user?.first_name} {user?.last_name}</h2>
				<p className="mb-1">{user?.email}</p>
				<p>{user.phone_number}</p>
			</div>
			<div className={Styles.links}>
				{sidebarLinks.map((link) => (
					<h5
						key={link.id}
						className={`${Styles.link} ${activeLink === link.id ? Styles.active : ''}`}
						onClick={() => updateSearchParams(link.route)}>
						{link.icon} {link.label}
					</h5>
				))}
			</div>
		</aside>
	);
};
