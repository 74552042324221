import { useCallback, useState } from 'react';
import {
	Row, Col, Tabs, Tab
} from 'react-bootstrap';
import Styles from './detailsTabs.module.scss';
import { UnitSelectionModal } from './unitSelectionModal';
import { convertMarlaTo, timeAgo, toPriceFormat } from '../../../../utils';
import { SegmentedProgressBar } from '../../../segmentedProgressBar';
import { PriceInsightsTab } from './priceInsightsTab';

const DetailsTabs = ({ property, purchased }) => {
	const [detailsTabKey, setDetailsTabKey] = useState('basic');
	const [areaUnit, setAreaUnit] = useState({ selected: property.land_area_unit, modal: false });
	const [parkingSpaceUnit, setParkingSpaceUnit] = useState({ selected: 'Marla', modal: false });

	const listRender = useCallback((title, value) => {
		return (
			<div className="d-flex align-items-center justify-content-between">
				<p>{title}</p>
				<p className="font-weight-bold">{value}</p>
			</div>
		);
	}, []);

	return (
		<Tabs
			activeKey={detailsTabKey}
			onSelect={(k) => setDetailsTabKey(k)}
			className={`border-0 flex-nowrap ${Styles.detailTabs}`}>
			<Tab eventKey="basic" title="Basic Information" tabClassName={Styles.detailTab}>
				<Row className="mt-4 justify-content-between">
					<Col lg={4} xl={5} md={6} className="justify-content-between">
						{listRender('Home Type', property.type)}
						{listRender('City', property.city)}
						<div className="d-flex justify-content-between">
							<p>Area</p>
							<div className="font-weight-bold position-relative text-nowrap">
								{convertMarlaTo(areaUnit.selected, 6.7)} {areaUnit.selected}
								<UnitSelectionModal setUnit={setAreaUnit} unit={areaUnit} />
							</div>
						</div>
						{listRender('Furnished', 'Yes')}
						{listRender('Central Air Conditioning', 'Standard')}
						{listRender('Electricity Backup Type', 'UPS')}
						{listRender('Broadband Internet Connection', 'Yes')}
						{listRender('Intercom', 'Yes')}
					</Col>
					<Col lg={4} xl={5} md={6}>
						<div className="d-flex align-items-center justify-content-between">
							<p>Added</p>
							<p className="font-weight-bold">{timeAgo(property.created_at)}</p>
						</div>
						{listRender('Year Built', '1997')}
						<div className="d-flex justify-content-between">
							<p>Parking Space</p>
							<div className="font-weight-bold position-relative text-nowrap">
								{convertMarlaTo(parkingSpaceUnit.selected, 0.5)} {parkingSpaceUnit.selected}
								<UnitSelectionModal setUnit={setParkingSpaceUnit} unit={parkingSpaceUnit} />
							</div>
						</div>
						{listRender('Floors', '2')}
						{listRender('Central Heating', 'Available')}
						{listRender('Flooring Type', 'Wooden')}
						{listRender('Satellite or Cable TV Ready', 'Yes')}
						{listRender('Double Glazed Windows', 'Yes')}
					</Col>
				</Row>
			</Tab>
			<Tab eventKey="additional" title="Additional Features" tabClassName={Styles.detailTab}>
				<Row className="mt-4 justify-content-between">
					<Col lg={5} md={6} className="justify-content-between">
						{listRender('Dining Room', 'Yes')}
						{listRender('Lounge', 'Yes')}
						{listRender('Garden', 'Yes')}
						{listRender('Laundry', 'Yes')}
						{listRender('Swimming Pool', 'Yes')}
						{listRender('Powder Room', 'Yes')}
					</Col>
					<Col lg={5} md={6}>
						{listRender('Drawing Room', 'Yes')}
						{listRender('Store Rooms', '3')}
						{listRender('Study Room', 'Yes')}
						{listRender('Servant Quarter', 'Yes')}
						{listRender('Gym', 'Yes')}
						{listRender('Steam Room', 'Yes')}
					</Col>
				</Row>
			</Tab>
			<Tab eventKey="community" title="Community" tabClassName={Styles.detailTab}>
				<Row className="mt-4 justify-content-between">
					<Col lg={5} md={6} className="justify-content-between">
						{listRender('Mosque', 'Yes')}
						{listRender('Community Gym', 'Yes')}
						{listRender('Community Swimming Pool', 'Yes')}
						{listRender('Maintenance Staff', 'No')}
						{listRender('Barbeque Area', 'Yes')}
					</Col>
					<Col lg={5} md={6}>
						{listRender('Community Garden', 'Yes')}
						{listRender('Kids Play Area', 'Yes')}
						{listRender('Facilities for Disabled', 'Yes')}
						{listRender('Security Staff', 'No')}
						{listRender('Day Care Centre', 'Yes')}
					</Col>
				</Row>
			</Tab>
			<Tab eventKey="price" title="Price Insights" tabClassName={Styles.detailTab}>
				<PriceInsightsTab listRender={listRender} />
			</Tab>
			{
				purchased && (
					<Tab eventKey="partners" title="Partners" tabClassName={Styles.detailTab}>
						<Row className="mt-4 justify-content-between">
							<Col lg={5} md={6}>
								{listRender('Total Partners', `Rs. ${toPriceFormat(200000)}`)}
							</Col>
							<Col lg={5} md={6}>
								{listRender('Your Share', '50%')}
							</Col>
							<Col xs={12} className="mb-4">
								<SegmentedProgressBar percentages={[20, 10, 15, 35, 20]} />
							</Col>
							<Col lg={5} md={6}>
								{listRender('Ali A.', '29.5%')}
								{listRender('Kashif K.', '8%')}
								{listRender('Warda B.', '4%')}
								{listRender('Ali J.', '9%')}
								{listRender('Zayn K.', '4%')}
							</Col>
							<Col lg={5} md={6}>
								{listRender('Ali A.', '29.5%')}
								{listRender('Kashif K.', '8%')}
								{listRender('Warda B.', '4%')}
								{listRender('Ali J.', '9%')}
								{listRender('Zayn K.', '4%')}
							</Col>
						</Row>
					</Tab>
				)
			}
		</Tabs>
	);
};

export { DetailsTabs };
