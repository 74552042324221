import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const getPropertyDetails = createAsyncThunk('/property/details', async ({
	propertyID
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get(`/properties/${propertyID}/details`);
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data.message || error.message
		});
	}
});

export const recommendPropertyForFeed = createAsyncThunk('properties/feed', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('/properties/feed', {
			params: {
				limit: 10
			}
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data.message || error.message
		});
	}
});

export const getRecommendedProperties = createAsyncThunk('properties/recommended', async ({
	propertyID, limit, loaded
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get(
			`/properties/${propertyID}/recommended?limit=${limit}&loaded=${loaded}`
		);

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data.message || error.message
		});
	}
});

export const toggleFavoriteProperty = createAsyncThunk('/property/favorite', async ({
	propertyId
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post(`/properties/${propertyId}/toggle_favourite`);
		return await response.data.message;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data.message || error.message
		});
	}
});
