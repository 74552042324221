import { createSlice } from '@reduxjs/toolkit';
import { fetchUserProfileInfo } from './userActions';

const initialState = {
	loading: false,
	user: null,
	error: null,
	success: false,
};

const authSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUserProfileInfo.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchUserProfileInfo.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.user = payload.user_details;
				state.error = null;
				state.success = true;
			})
			.addCase(fetchUserProfileInfo.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload.error;
			});
	}
});

export default authSlice.reducer;
