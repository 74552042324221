import { configureStore } from '@reduxjs/toolkit';
import { injectStore } from '../utils/axiosConfig';
import authReducer from './auth/authSlice';
import propertyReducer from './property/propertySlice';
import userReducer from './user/userSlice';

const store = configureStore({
	reducer: {
		auth: authReducer,
		property: propertyReducer,
		user: userReducer
	}
});

injectStore(store);

export default store;
