import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../button';
import { ReactComponent as ArrowDown } from '../../../../assets/arrowDownIcon.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/arrowUpIcon.svg';
import { ReactComponent as ArrowDownBlue } from '../../../../assets/arrowDownBlueCircleIcon.svg';
import { ReactComponent as ArrowUpRed } from '../../../../assets/arrowUpRedCircleIcon.svg';
import { toPriceFormat, toSlashDateFormat } from '../../../../utils';
import Styles from './mobileView.module.scss';

export const TransactionHistoryTableMobileView = ({ transactions }) => {
	const [visibleRows, setVisibleRows] = useState(3);
	const [expandedAccordion, setExpandedAccordion] = useState(null);

	const loadMoreRows = () => {
		setVisibleRows(visibleRows + 5);
	};

	const handleArrowClick = (ID) => {
		if (expandedAccordion === `accordion${ID}`) {
			setExpandedAccordion(null);
		} else {
			setExpandedAccordion(`accordion${ID}`);
		}
	};

	const OrderStatus = ({ status }) => {
		let backgroundColor;
		let textColor;

		if (status === 'Pending') {
			backgroundColor = '#FFE5CD';
			textColor = '#986986';
		} else if (status === 'Completed') {
			backgroundColor = '#D9FAE5';
			textColor = '#008000';
		} else if (status === 'Cancelled') {
			backgroundColor = '#FFD7DB';
			textColor = '#EB001B';
		}

		const containerStyles = {
			padding: '2px 15px',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			borderRadius: '5px',
			color: textColor,
			backgroundColor
		};

		return (
			<div style={containerStyles}>
				<p className={`m-0 p-0 ${Styles.tagText}`}>{status}</p>
			</div>
		);
	};

	return (
		<div>
			{transactions?.slice(0, visibleRows).map((transaction, index) => (
				<div key={transaction.id} className={`${Styles.container} containerShadow`} id={`accordion${transaction.id}`}>
					<div className={Styles.rowContainer}>
						<strong className="mb-0">{toSlashDateFormat(transaction.date.toISOString())}</strong>
						<OrderStatus status={transaction.status} />
					</div>
					<p className="my-2">Address: {transaction.address}</p>
					<div className={Styles.rowContainer}>
						<p className="mb-0 d-flex align-items-center">
							{index === 1 ? <ArrowDownBlue /> : <ArrowUpRed />}
							<strong className="pl-2">Rs. {toPriceFormat(transaction.value)}</strong>
						</p>
						<div className={Styles.arrowContainer} onClick={() => handleArrowClick(transaction.id)}>
							{
								expandedAccordion === `accordion${transaction.id}`
									? <ArrowUp className={Styles.icon} />
									: <ArrowDown className={Styles.icon} />
							}
						</div>
					</div>
					{expandedAccordion === `accordion${transaction.id}` && (
						<>
							<p className="mb-0 mt-2">Transaction ID: {transaction.transactionID}</p>
							<div className={`${Styles.rowContainer} mt-2`}>
								<p>Type: {transaction.transactionType}</p>
								<p>Shares: {toPriceFormat(transaction.shares)}</p>
							</div>
							<div>
								<Link className={Styles.downloadLink} to={transaction.invoice}>
									Download Invoice
								</Link>
							</div>
						</>
					)}
				</div>
			))}
			{visibleRows < transactions.length && (
				<div className="text-center mt-5">
					<Button variant="secondary" styles="px-5" text="Load More" onClick={loadMoreRows} />
				</div>
			)}
		</div>
	);
};
