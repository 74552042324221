import { PersonalInformation } from './personalInformation';
import { ReactComponent as PersonIcon } from '../../../assets/personIcon.svg';
import { ReactComponent as PayoutIcon } from '../../../assets/payoutIcon.svg';
import { ReactComponent as ApartmentIcon } from '../../../assets/apartmentIcon.svg';
import { ReactComponent as HouseIcon } from '../../../assets/houseIcon.svg';
import { ReactComponent as PendingPropertiesIcon } from '../../../assets/pendingPropertiesIcon.svg';
import { ReactComponent as PendingPurchasesIcon } from '../../../assets/pendingPurchasesIcon.svg';
import { ReactComponent as KeyIcon } from '../../../assets/keyIcon.svg';
import { ReactComponent as FavoriteIcon } from '../../../assets/favoriteIcon.svg';
import { PayoutInformation } from './payoutInformation';
import { PendingProperties } from './pendingProperties';
import { RentalManagement } from './rentalManagement';
import { PendingPurchases } from './pendingPurchases';
import { PostedProperties } from './postedProperties';

export const sidebarLinks = [
	{
		id: 'PersonalInformation',
		label: 'Personal Information',
		icon: <PersonIcon />,
		component: <PersonalInformation />,
		route: 'personal'
	},
	{
		id: 'PayoutInformation',
		label: 'Payout Information',
		icon: <PayoutIcon />,
		component: <PayoutInformation />,
		route: 'payout'
	},
	{
		id: 'YourProperties',
		label: 'Your Properties',
		icon: <ApartmentIcon />,
		component: <></>,
		route: 'your-properties'
	},
	{
		id: 'PostedProperties',
		label: 'Posted Properties',
		icon: <HouseIcon />,
		component: <PostedProperties />,
		route: 'posted-properties'
	},
	{
		id: 'PendingProperties',
		label: 'Pending Properties',
		icon: <PendingPropertiesIcon />,
		component: <PendingProperties />,
		route: 'pending-properties'
	},
	{
		id: 'PendingPurchases',
		label: 'Pending Purchases',
		icon: <PendingPurchasesIcon />,
		component: <PendingPurchases />,
		route: 'pending-purchases'
	},
	{
		id: 'RentalManagement',
		label: 'Rental Management',
		icon: <KeyIcon />,
		component: <RentalManagement />,
		route: 'rental'
	},
	{
		id: 'YourFavorites',
		label: 'Your Favorites',
		icon: <FavoriteIcon />,
		component: <></>,
		route: 'favorites'
	},
];
