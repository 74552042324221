import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { useEffect, useState, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { HissayLogo } from '../../assets';
import { SearchBar } from '../searchBar';
import { Sidebar } from './sidebar';
import { ReactComponent as MenuIcon } from '../../assets/navbar/menuIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/navbar/searchIcon.svg';
import { additionalMenuItems, navbarMenuItems, profileMenuItems } from './menuItems';
import { logoutUser } from '../../store/auth/authActions';
import { errorToast } from '../../utils';
import Styles from './navbar.module.scss';

function AppNavbar() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const [openSidebar, setOpenSidebar] = useState(false);
	const [searchBarVisible, setSearchBarVisible] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const mobileSearBarRef = useRef(null);

	const handleSearchSubmit = (e) => {
		e.preventDefault();
	};

	const toggleSidebar = () => {
		setOpenSidebar(!openSidebar);
	};

	const toggleSearchBar = () => {
		setSearchBarVisible(!searchBarVisible);
	};

	const handleSearchBarClickOutside = (event) => {
		if (mobileSearBarRef.current && !mobileSearBarRef.current.contains(event.target)) {
			setSearchBarVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleSearchBarClickOutside, true);

		return () => {
			document.removeEventListener('click', handleSearchBarClickOutside, true);
		};
	}, []);

	useEffect(() => {
		const regex = /\/properties\/\d+$/;
		const propertyDetailsScreenPath = regex.test(location.pathname);

		const previousPage = {
			pathname: location.pathname,
			title: document.title
		};

		if (!propertyDetailsScreenPath) {
			sessionStorage.setItem('previousPage', JSON.stringify(previousPage));
		}
	}, [location]);

	const handleLogout = () => {
		dispatch(logoutUser())
			.unwrap()
			.then((message) => {
				navigate('/sign-in', {
					state: {
						alertMessage: message,
						alertVariant: 'success'
					},
					replace: true
				});
			})
			.catch((errorResponse) => {
				errorToast(errorResponse.error);
			});

		setOpenSidebar(false);
	};

	return (
		<>
			<Navbar className={`py-0 ${Styles.navbarWrapper}`}>
				<div className={`px-2 py-3 px-md-3 px-lg-5 ${Styles.navbarContainer}`}>
					<div className="w-50 d-flex align-items-center">
						<LinkContainer to="/" className={Styles.logo}>
							<img src={HissayLogo} alt="Hissay Logo" className="mr-xl-5 mr-4" width={161} height={44} />
						</LinkContainer>
						{
							(location.pathname !== '/sign-in' && location.pathname !== '/sign-up' && location.pathname !== '/')
							&& <div className="d-none w-100 d-lg-block">
								<SearchBar handleSearchSubmit={handleSearchSubmit} />
							</div>
						}
					</div>
					{
						(location.pathname !== '/sign-in' && location.pathname !== '/sign-up')
						&& <div>
							<div className="d-none d-lg-flex align-items-center">
								<Nav className={Styles.menuContainer}>
									{
										navbarMenuItems.map((item, index) => (
											<LinkContainer to={item.link} key={`desktop-nav-menu-${index}`}>
												<Nav.Link className={Styles.navLink}>{item.title}</Nav.Link>
											</LinkContainer>
										))
									}
								</Nav>
								{
									user?.email
										? (
											<div className={Styles.profileMenuContainer}>
												<Dropdown
													show={isDropdownOpen}
													onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
													className={Styles.profileDropdown}>
													<Dropdown.Toggle
														className={`${Styles.profileButton} ${isDropdownOpen && Styles.profileButtonOpen}`}>
														<span className={Styles.profileName}>
															{user.first_name} {user.last_name}
														</span>
														<div className={Styles.avatar}>{user.first_name.charAt(0)}</div>
													</Dropdown.Toggle>
													<Dropdown.Menu
														className={Styles.dropDownMenu}>
														{
															profileMenuItems.map((item, index) => (
																<Dropdown.Item className={Styles.dropDownItem} href={item.link} key={`desktop-profile-menu-${index}`}>
																	{item.title}
																</Dropdown.Item>
															))
														}
														<div className={Styles.learnAboutContainer}>
															<p className={Styles.learnAboutText}>Learn About</p>
														</div>
														{
															additionalMenuItems.map((item, index) => (
																<Dropdown.Item className={Styles.dropDownItem} href={item.link} key={`desktop-additional-menu-${index}`}>
																	{item.title}
																</Dropdown.Item>
															))
														}
														<hr className={Styles.divider} />
														<Dropdown.Item className={Styles.dropDownItem} onClick={handleLogout}>
													Log Out
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										) : (
											<Nav className={Styles.authMenuContainer}>
												<LinkContainer to="/sign-in">
													<Nav.Link className={Styles.loginLink}>Login</Nav.Link>
												</LinkContainer>
												<LinkContainer to="/sign-up">
													<Nav.Link className={Styles.signUpLink}>Sign Up</Nav.Link>
												</LinkContainer>
											</Nav>
										)
								}
							</div>
							<div className="d-flex d-lg-none align-items-center">
								<div className={Styles.searchButton} onClick={toggleSearchBar}>
									<SearchIcon className={Styles.searchIcon} />
								</div>
								<div className={Styles.menuButton} onClick={toggleSidebar}>
									<MenuIcon className={Styles.menuIcon} />
								</div>
								<div className={`${Styles.mobileSearchBar} ${searchBarVisible && Styles.openSearchBar}`} ref={mobileSearBarRef}>
									<SearchBar handleSearchSubmit={handleSearchSubmit} type='mobile' open={searchBarVisible} toggle={toggleSearchBar} />
								</div>
							</div>
						</div>
					}
				</div>
				<div className={Styles.cities}>
					<div className="mx-lg-5">
						<Link className={Styles.city} to='/'>Karachi</Link>
						<Link className={Styles.city} to='/'>Islamabad</Link>
						<Link className={Styles.city} to='/'>Lahore</Link>
					</div>
				</div>
			</Navbar>
			<Sidebar open={openSidebar} toggle={toggleSidebar} handleLogout={handleLogout} />
		</>
	);
}

export { AppNavbar };
