import { useCallback } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgZoom from 'lightgallery/plugins/zoom';
import Styles from './imagesGrid.module.scss';

export const ImagesGrid = ({ images }) => {
	const onInit = useCallback((detail) => {
		if (detail) {
			LightGallery.current = detail.instance;
		}
	}, []);

	const openGallery = useCallback((index) => {
		LightGallery.current.openGallery(index);
	}, []);

	const allImages = images.map((image, id) => {
		return { id, src: image.url };
	});

	return (
		<>
			<LightGallery
				elementClassNames="custom-classname"
				dynamic
				dynamicEl={allImages}
				onInit={onInit}
				plugins={[lgZoom]}
				download={false}
				mobileSettings={{ showCloseIcon: true }}
			/>
			<div className={Styles.imagesGrid} id="section-overview">
				<div className={Styles.mainImage} onClick={() => openGallery(0)}>
					<img src={allImages[0].src} alt="property" width="100%" />
				</div>
				<div className={Styles.secondImage} onClick={() => openGallery(1)}>
					<img src={allImages[1].src} alt="property" width="100%" />
				</div>
				<div className={Styles.thirdImage} onClick={() => openGallery(2)}>
					<img src={allImages[2].src} alt="property" width="100%" />
					<div className={Styles.seeAllPhotos} onClick={() => openGallery(0)}>
					See all {allImages?.length} photos
					</div>
				</div>
			</div>
		</>
	);
};
