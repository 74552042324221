import { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FavoriteIcon } from '../../../assets/propertyCard/favoriteIcon.svg';
import { ReactComponent as ShareIcon } from '../../../assets/propertyCard/shareIcon.svg';
import { timeAgo, toPriceFormat } from '../../../utils/index';
import Styles from './card.module.scss';
import { ShareSocialsModal } from './shareSocialsModal/shareSocialsModal';
import { ProgressBar } from '../../progressBar';
import { Button } from '../../button';
import { toggleFavoriteProperty } from '../../../store/property/propertyActions';

function PropertyCard({
	property, showProgress, showButtons, reload
}) {
	const shareContainerRef = useRef();
	const [shareContainer, setShareContainer] = useState(false);
	const { user } = useSelector((state) => state.auth);
	const { success } = useSelector((state) => state.property);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isFavourite, setIsFavourite] = useState(false);

	const addToFavorites = () => {
		if (user?.email) {
			dispatch(toggleFavoriteProperty({
				propertyId: property.id
			}));

			if (success) {
				setIsFavourite(!isFavourite);
			}
		} else {
			navigate('/sign-in');
		}
	};

	const shareSocialsModalHandler = () => {
		setShareContainer(!shareContainer);
	};

	return (
		<Link to={`/properties/${property.id}`} reloadDocument={reload} className={Styles.propertyLink}>
			<Card className={`${Styles.propertyCard} shadow-sm`}>
				<div className={Styles.postedTime}>{`${timeAgo(property.created_at)}`}</div>
				<div className={Styles.linkImage}>
					<Card.Img variant="top" src={property.mainImage || property.property_images[0].url} />
				</div>
				<Card.Body className="py-2">
					<div className="d-flex align-items-center justify-content-between py-2">
						<h5 className={`font-weight-bold m-0 ${Styles.cardHeading}`}>Rs {toPriceFormat(property.price)}</h5>
						<div className={`d-flex align-items-center ${Styles.icons}`}>
							<div className="position-relative">
								<ShareIcon className={Styles.shareIcon} onClick={shareSocialsModalHandler} />
								{
									shareContainer
								&& <ShareSocialsModal
									url={property.url || window.location.href}
									innerRef={shareContainerRef}
									modalHandler={shareSocialsModalHandler} />
								}
							</div>
							<FavoriteIcon
								className={`${Styles.favoriteIcon} ${isFavourite ? Styles.filled : null}`}
								onClick={addToFavorites} />
						</div>
					</div>
					<div className={Styles.cardText}>
						<div className="d-flex align-items-center pt-1">
							<p className="m-0 mr-2">{property.beds} Beds</p>
							<p className="m-0 mr-2">{property.baths} Baths</p>
							<p className="m-0">{property.marla} Marla</p>
							<p className="px-2 m-0">-</p>
							<p className="m-0">House for sale</p>
						</div>
						<p className="pb-2">{property.location}</p>
					</div>
					{
						showProgress
					&& <div className="mt-2 mb-3 w-100 text-left">
						<p className="m-0 mb-1">{toPriceFormat(property.purchaseValue)} / {toPriceFormat(property.totalValue)}</p>
						<ProgressBar total={property.totalValue} current={property.purchaseValue} />
					</div>
					}
					{
						showButtons
					&& <div className="mt-2 mb-3 w-100 d-flex justify-content-between align-items-center">
						<Button variant="secondary" text="View Report" className={Styles.reportButton} />
						<Button variant="primary" text="Edit" className={Styles.editButton} />
					</div>
					}
				</Card.Body>
			</Card>
		</Link>
	);
}

export { PropertyCard };
