import Styles from './alert.module.scss';
import { ReactComponent as CrossIcon } from '../../assets/crossIcon.svg';

export const Alert = ({
	leftIcon, title, children, canHide
}) => {
	return (
		<div className={Styles.alert}>
			<div className={Styles.textContent}>
				{leftIcon}
				<div>
					{title && <h5 className={Styles.title}>{title}</h5>}
					<div className={Styles.paragraph}>
						{children}
					</div>
				</div>
			</div>
			{canHide && <CrossIcon className={Styles.cross} />}
		</div>
	);
};
