import { useState } from 'react';
import Styles from './searchBar.module.scss';
import SearchIcon from '../../assets/searchIcon.svg';
import CrossIcon from '../../assets/crossIcon.svg';
import LocationIcon from '../../assets/locationIcon.svg';
import RecentSearchIcon from '../../assets/recentSearchIcon.svg';
import CurrentLocationIcon from '../../assets/currentLocationIcon.svg';
import MobileSearchIcon from '../../assets/navbar/searchIcon.svg';

function SearchBar({
	handleSearchSubmit, className, type, toggle, open
}) {
	const [query, setQuery] = useState('');
	const [inputFocus, setInputFocus] = useState(false);

	const handleChange = (e) => {
		setQuery(e.target.value);
	};

	return (
		<form onSubmit={handleSearchSubmit} className={Styles.searchForm}>
			{
				(open || inputFocus)
				&& <div className={Styles.backdrop} onClick={toggle}></div>
			}
			<div className={`d-flex align-items-center ${Styles.searchBar} ${className}`}>
				<div className={`${Styles.inputWrapper} ${type === 'mobile' && Styles.mobileInputWrapper}`}>
					{
						type === 'mobile'
						&& <span onClick={handleSearchSubmit} className={Styles.mobileSearchButton}>
							<img src={MobileSearchIcon} alt="Search" width={24} height={24} />
						</span>
					}
					<input
						type="text"
						required
						value={query}
						className={`${Styles.searchInput} ${inputFocus ? Styles.focused : null}`}
						placeholder='Search using city, address or even a land mark'
						onFocus={() => setInputFocus(!inputFocus)}
						onBlur={() => setInputFocus(!inputFocus)}
						onChange={handleChange} />
					{
						query
						&& <span onClick={() => setQuery('')} className={Styles.clearButton}>
							<img src={CrossIcon} alt="Clear" width={12} height={12} />
						</span>
					}
				</div>
				{
					type !== 'mobile'
					&& <button type="submit" className={`${Styles.searchButton} ${inputFocus ? Styles.focused : null}`}>
						<img src={SearchIcon} alt="search" width={22} height={22} />
					</button>
				}
				{
					inputFocus
					&& <div className={`${Styles.searchResultsContainer} ${type === 'mobile' && Styles.mobileSearchResultsContainer}`}>
						{
							!query
							&& <div className={`d-flex align-items-center ${Styles.currentLocation}`}>
								<img src={CurrentLocationIcon} alt="Current Location" width={20} height={20} />
								<p className="m-0 p-0 ml-3 font-weight-bold">Current Location</p>
							</div>
						}
						<h6 className={`${Styles.recentSearchTitle} pt-3 pb-2 pb-0 pl-3 m-0`}>Recent Search</h6>
						<div className={`d-flex align-items-start ${Styles.searchResult}`}>
							<img src={RecentSearchIcon} alt="Recent Search" width={20} height={20} />
							<div className="ml-3 mt-0 p-0">
								<p className="m-0 p-0">DHA Phase 2</p>
								<p className={`${Styles.mutedText} m-0 p-0`}>DHA Phase 2, Rwp, Pakistan</p>
							</div>
						</div>
						{
							query
							&& <>
								<h6 className={`${Styles.placesTitle} py-2 p-0 pl-3 m-0`}>Places</h6>
								<div className={`d-flex align-items-start ${Styles.searchResult}`}>
									<img src={LocationIcon} alt="Places" width={20} height={20} />
									<div className="ml-3 mt-0 p-0">
										<p className="m-0 p-0">DHA Phase 1</p>
										<p className={`${Styles.mutedText} m-0 p-0`}>DHA Phase 1, Rwp, Pakistan</p>
									</div>
								</div>
							</>
						}
					</div>
				}
			</div>
		</form>
	);
}

export { SearchBar };
