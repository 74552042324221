export const faqList = [
	{
		question: 'What is Hissay?',
		answer: 'Hissay is a fractional ownership real estate platform that enables even the average person to participate in the real estate market. That means you can become a property owner with as little as Rs 100,000!'
	},
	{
		question: 'What is fractional or co-ownership?',
		answer: 'Fractional ownership allows you to own a smaller piece of a larger asset. Since you do not have to buy a whole asset, you do not have to pay the whole price of the asset. The cost gets divided between various owners depending on how much of an asset you want to purchase. On Hissay we require you to invest at least Rs 100,000 but you can invest as much of a property as you would like.',
		link: 'fractional-ownership',
		linkText: 'Learn more about Fractionalization'
	},
	{
		question: 'How do I make money on Hissay?',
		answer: 'You can make money on Hissay from potential appreciation of your property but also from rental income.'
	},
	{
		question: 'I have purchased my shares but the property still hasn\'t "closed." When do I get my shares?',
		answer: '"Closing" is the official process of a property coming on to the Hissay platform. The first time a property is listed on our platform, the property has to be completely bought before your shares are final.'
	},
	{
		question: 'What happens if a property is bought by someone else before all of the funds for that property are accumulated?',
		answer: 'You get a 100% refund. That includes your transaction fee. If we are not able to close on a property, there\'s no reason why we should get to keep any of your money! That is a part of the Hissay Tranparency Mission'
	},
	{
		question: 'What happens once a property closes?',
		answer: 'As soon as a property closes the first thing that happens is that you are issued your shares. At the same time you receive a contract from us that guarantees that in the case that the ownership of an asset is ever removed from the Hissay platform, you are entitled to a percentage of the sale price, in accordance to your ownership percentage. The last thing that happens is we being the process of trying to settle a tenent in your property so that you can start getting rental income.'
	},
	{
		question: 'Why am I not receiving any rent?',
		answer: 'We start the process of finding a tenent as soon as a property closes. Until we are able to settle a tenent, you cannot start receiving rent.'
	},
	{
		question: 'What happens to maintenence expenses?',
		answer: 'We annualize all maintenence fees. What that means is if some fee is incurred today then we divide it over a 12 month period and bill whoever the current shareholders are for that fee. That way if someone decides to sell their shares tomorrow, they do not have to worry about having paid someone else\'s maintenence fee.'
	},
	{
		question: 'How many shares exist per property?',
		answer: 'When a property first closes, whatever the price was for the property, those many shares exist. This number is fixed in perpetuity for that property. What that means is the number of shares for that property will never go up and will never go down. Shares can only be transferred (via buying and selling).'
	},
	{
		question: 'How do I know that the sale price for a property reflects actual market price?',
		answer: 'Calculating the market price for an asset is generally difficult since the most frequent rule for an asset tends to be that an asset is worth what someone is willing to pay for it. That said, we want everyone on Hissay to always get an accurate real world price per current market conditions. That\'s why we use external independent price auditors to ensure that assets are worth what they are listed for before closing. Once an asset closes on Hissay, we defer to a system of going by market price '
	},
	{
		question: 'How do you protect me from property fraud?',
		answer: 'One of the strongest points for our platform is our dedication to protecting you from the rampant property fraud in Pakistan. As soon as we receive any investment towards a property, our team goes into action to verify properties including viewing NOC certificates, official files, and other proof of ownership docs). If we detect a problem, the property is barred from our platform and you receive a 100% refund (yes, that includes your transaction fees).'
	},
	{
		question: 'What does Hissay Verified mean?',
		answer: 'Hissay Verified properties are properties that we have determined to have a clean file, verified ownership, and our users are safe to transact on. Properties cannot close on Hissay before receiving our Hissay Verified certification.'
	},
	{
		question: 'How do I know this is not a scam?',
		answer: 'Hissay operates on the premise of building user trust and delivering great returns. We do everything that is possible to ensure you can trust us as a platform, going as far as issuing you a Promissory Note for your percentage of the proceeds of a property if the property were to ever be sold off of Hissay\'s platform.'
	},
	{
		question: 'What is a blockchain?',
		answer: 'Blockchain is a decentralized, distributed and public digital ledger technology. What does that actually mean though? Blockchains are meant to store data in a way that cannot be modified because the data is spread across multiple different devices. Blockchains are meant to be public information that anyone can view and subscribe to. The more people that subscribe to a blockchain, the safer it becomes! We have developed our own Hissay Blockchain precisely because transparency and trust are so important to us. You never have to worry about shares magically appearing or disappearing. You can even filter things down to see blockchain results for just one property.'
	},
	{
		question: 'What guarantee do I have?',
		answer: 'When your shares are transferred to your account you also are digitally signing a contract with us that states that in the case that a property must be delisted from the Hissay Platform, you are entitled to the proceeds from the sale of that property. That is the guarantee that we give to you and that\'s how we build up trust as an organisation.'
	},
	{
		question: 'How can I buy shares?',
		answer: 'You can buy shares by going to the details screen of the property that you are interested in. If there are shares available for that property, you\'ll see a button to buy shares.'
	},
	{
		question: 'How do I sell my shares?',
		answer: 'We have a 90 day holding period on our platform. Once those 90 days have expired you can go to the list of properties that you have bought, mention how many shares you want to sell and at what price. If someone thinks your price is fair and your asset is worth an investment, they\'ll buy your shares and you just have to wait for the money to be depositied into your account!'
	},
	{
		question: 'What does it mean to make an illiquid asset liquid?',
		answer: 'An illiquid asset is an asset that typically cannot be converted to cash (in other words, sold) quickly. Traditional real estate is the perfect example of an illiquid asset because it takes time to sell a whole property. What we have done is made illiquid real estate into a liquid asset by allowing you to sell smaller pieces to a larger group of people. Since there are more buyers at a cheaper price, your illiquid asset should now become liquid!'
	}
];
