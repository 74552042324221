import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import { Container } from '../../container';
import {
	FacebookIcon,
	TwitterIcon,
	EmailIcon,
	WhatsappIcon,
	LinkedinIcon
} from '../../../assets/propertyCard';
import DesktopCollage from '../../../assets/auth/signupSuccessDesktopCollage.png';
import MobileCollage from '../../../assets/auth/signupSuccessMobileCollage.png';
import Styles from './signupSuccessScreen.module.scss';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export const SignupSuccessScreen = () => {
	const [copiedText, copy] = useCopyToClipboard();
	const isMobile = useMediaQuery('(max-width: 576px)');
	const socialShareMessage = 'I just signed up to co-invest in properties on Hissay. You should too! https://www.hissay.pk';

	return (
		<main>
			<Container className={Styles.mainContainer}>
				<h1>Thank You for signing up!</h1>
				<br />
				<br />
				<p>
					Welcome to hissay. We are thrilled to have you on board.
					Get ready to explore the future of real estate and build an
					incredible portfolio with minimal investment.
				</p>
				<p className="py-2">We'll be officially launching soon so stay tuned</p>
				<br />
				<br />
				<h5>Excited to start investing? Don't forget to invite your friends to the fun!</h5>
				<div className={Styles.copyField}>
					<input
						type="text"
						value="https://www.hissay.pk"
						onChange={() => null}
						className={`${Styles.copyText} ${copiedText ? Styles.highlight : null}`} />
					<button
						disabled={copiedText}
						className={Styles.copyButton}
						onClick={() => copy('https://www.hissay.pk')}>
						{copiedText ? 'Copied' : 'Copy'}
					</button>
				</div>
				<div className={Styles.socialIcons}>
					<a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
						<img src={FacebookIcon} alt="facebook" width={38} />
					</a>
					<a href="mailto:hissay@gmail.com" target="_blank" rel="noopener noreferrer">
						<img src={EmailIcon} alt="email" width={38} />
					</a>
					<a
						href={
							isMobile
								? `whatsapp://send?text=${socialShareMessage}`
								: `https://web.whatsapp.com/send?text=${socialShareMessage}`
						}
						target="_blank"
						rel="noreferrer">
						<img src={WhatsappIcon} alt="whatsapp" width={38} />
					</a>
					<a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
						<img src={LinkedinIcon} alt="linkedin" width={38} />
					</a>
					<a
						href={`https://twitter.com/intent/tweet?text=${socialShareMessage}`}
						target="_blank"
						rel="noreferrer">
						<img src={TwitterIcon} alt="twitter" width={38} />
					</a>
				</div>
			</Container>
			<dir className={Styles.desktopCollage}>
				<img src={DesktopCollage} alt="Collage" />
			</dir>
			<dir className={Styles.mobileCollage}>
				<img src={MobileCollage} alt="Collage" />
			</dir>
		</main>
	);
};
