import { createSlice } from '@reduxjs/toolkit';
import {
	recommendPropertyForFeed,
	getPropertyDetails,
	toggleFavoriteProperty,
	getRecommendedProperties
} from './propertyActions';

const initialState = {
	loading: false,
	error: null,
	success: false,
	property: null,
	message: null,
	properties: []
};

const propertySlice = createSlice({
	name: 'property',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPropertyDetails.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPropertyDetails.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.property = payload.property_details;
				state.error = null;
				state.success = true;
			})
			.addCase(getPropertyDetails.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload.error;
			});

		builder
			.addCase(recommendPropertyForFeed.pending, (state) => {
				state.loading = true;
			})
			.addCase(recommendPropertyForFeed.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.properties = payload.properties;
				state.error = null;
				state.success = true;
			})
			.addCase(recommendPropertyForFeed.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload.error;
			});

		builder
			.addCase(toggleFavoriteProperty.pending, (state) => {
				state.loading = true;
			})
			.addCase(toggleFavoriteProperty.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.message = payload.message;
				state.error = null;
				state.success = true;
			})
			.addCase(toggleFavoriteProperty.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload.error;
			});

		builder
			.addCase(getRecommendedProperties.pending, (state) => {
				state.loading = true;
			})
			.addCase(getRecommendedProperties.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.error = null;
				state.properties.push(...payload.properties);
			})
			.addCase(getRecommendedProperties.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload.error;
			});
	}
});

export default propertySlice.reducer;
