import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Lottie from 'lottie-web';
import Styles from './checkoutSuccessScreen.module.scss';
import { Button } from '../button';
import { Container } from '../container';
import { ReactComponent as ShareIcon } from '../../assets/propertyCard/shareIcon.svg';
import { ShareSocialsModal } from '../properties/card/shareSocialsModal';
import HomeLottie from '../../assets/lotties/homeLottie.json';

export const CheckoutSuccessScreen = () => {
	const location = useLocation();
	const shareContainerRef = useRef();
	const [shareContainer, setShareContainer] = useState(false);
	const [params, setParams] = useState({ title: '', text: '' });
	const [searchParams,] = useSearchParams();
	const screenType = searchParams.get('bought');

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });

		if (location.state) {
			setParams({ title: location.state?.title || '', text: location.state?.text || '' });
		}
	}, [location.state]);

	const shareSocialsModalHandler = () => {
		setShareContainer(!shareContainer);
	};

	useEffect(() => {
		Lottie.loadAnimation({
			container: document.querySelector('#homeLottie'),
			renderer: 'svg',
			loop: false,
			autoplay: true,
			animationData: HomeLottie
		});
	}, []);

	return (
		<Container className={Styles.mainContainer}>
			<div id="homeLottie" className={Styles.homeLottie} />
			{params?.title && <h2 className="pb-2">{params.title}</h2>}
			{params?.text && <p className={Styles.secondaryText}>{params.text}</p>}
			<div className={Styles.actionButtons}>
				<Link to="/properties">
					<Button variant="primary" text="Explore More Properties" />
				</Link>
				{
					screenType === 'whole'
					&& <div className="position-relative">
						<Button
							variant="secondary"
							text="Share With Your Friends"
							onClick={shareSocialsModalHandler}
							leftIcon={<ShareIcon />}
						/>
						{
							shareContainer
							&& <ShareSocialsModal
								url={window.location.href}
								innerRef={shareContainerRef}
								modalHandler={shareSocialsModalHandler}
							/>
						}
					</div>
				}
				{screenType === 'share' && <Button variant="secondary" text="View Your Properties" />}
			</div>
		</Container>
	);
};
