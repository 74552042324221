import { Col, Row } from 'react-bootstrap';
import { FloatingLabelInput } from '../../../../floatingLabelInput';
import { ImageInput } from '../../../../imageInput';
import Styles from './cnicInformationSection.module.scss';

export const CnicInformationSection = ({
	cnicImages,
	handleInputChange,
	handleCnicImageChange
}) => {
	return (
		<>
			<h4 className="mt-5 pb-2">CNIC Information</h4>
			<Row className="mr-0">
				<Col xs={12} className="pr-0">
					<FloatingLabelInput
						label="CNIC Number"
						type="text"
						id="cnic"
						name="cnic"
						onChange={handleInputChange}
						required={true}
					/>
				</Col>
			</Row>
			<Row className={`${Styles.cnicImagesContainer} mr-0`}>
				<Col xs={12} lg={6} className="pr-0 pb-4 pb-lg-0">
					<ImageInput
						id="cnicFront"
						name="cnicFront"
						cnicImage={cnicImages.cnicFront}
						verified={true}
						placeholder="Upload CNIC (Front Side)"
						handleChange={handleCnicImageChange}
						label="CNIC Front Side"
					/>
				</Col>
				<Col xs={12} lg={6} className="pr-0">
					<ImageInput
						id="cnicBack"
						name="cnicBack"
						cnicImage={cnicImages.cnicBack}
						inReview={true}
						placeholder="Upload CNIC (Back Side)"
						handleChange={handleCnicImageChange}
						label="CNIC Back Side"
					/>
				</Col>
			</Row>
		</>
	);
};
