import Styles from './imageInput.module.scss';
import { ReactComponent as UploadIcon } from '../../assets/uploadIcon.svg';
import { ReactComponent as BinIcon } from '../../assets/binIcon.svg';
import { ReactComponent as ReviewIcon } from '../../assets/reviewIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/infoIcon.svg';
import { ReactComponent as BlueTickIcon } from '../../assets/verifiedBlueTickIcon.svg';
import { ReactComponent as DangerTriangleIcon } from '../../assets/warningTriangleIcon.svg';
import { CustomTooltip } from '../customTooltip';

export const ImageInput = ({
	label,
	placeholder,
	name,
	id,
	handleChange,
	cnicImage,
	showTooltip = false,
	verificationFailed = false,
	inReview = false,
	verified = false
}) => {
	return (
		<>
			{
				label && <label className={Styles.inputLabel}>
					<div className={Styles.labelContainer}>
						<div>{label}</div>
						{
							inReview && <span className={Styles.statusTag}>
								<ReviewIcon /> <div>Review in Progress</div>
							</span>
						}
						{
							verified && <span className={Styles.statusTag}>
								<BlueTickIcon /> <div>Verified</div>
							</span>
						}
						{
							verificationFailed && <span className={Styles.statusTag}>
								<DangerTriangleIcon /> <div className={Styles.dangerText}>
									Verification Failed
								</div>
							</span>
						}
					</div>
					{
						showTooltip
						&& <CustomTooltip icon={<InfoIcon width={22} />}>
							Help us verify your account by uploading a high-resolution scanned copy or
							<br /> photo of your identity card. Be sure to capture all the necessary
							details with a picture that is not blurry. Your ID card will be
							verified with NADRA systems.
						</CustomTooltip>
					}
				</label>
			}
			<div className={Styles.cnicUploadContainer}>
				<label htmlFor={id} className={Styles.cnicUploadLabel}>
					<div className="mb-1"><UploadIcon /></div>
					{placeholder}
				</label>
				<input
					id={id}
					onChange={handleChange}
					className={Styles.cnicUploadInput}
					type="file"
					name={name}
				/>
				{
					cnicImage
					&& <div className={Styles.cnicImagePreview}>
						<img className={Styles.cnicImage} src={cnicImage} alt="cnic" />
						<BinIcon className={Styles.bin} />
					</div>
				}
			</div>
		</>
	);
};
