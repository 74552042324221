import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Styles from './tooltip.module.scss';

const tooltipFade = {
	hidden: { y: 4, opacity: 0 },
	visible: {
		y: 0,
		opacity: 1,
		transition: { duration: 0.15, ease: 'easeOut' }
	},
	exit: {
		y: 18,
		opacity: 0,
		transition: { duration: 0.15, delay: 0.1, ease: 'easeOut' }
	}
};

export const Tooltip = ({
	children, component, styles, className, position
}) => {
	const [visible, setVisible] = useState(false);

	const handleMouseEnter = () => {
		setVisible(true);
	};

	const handleMouseLeave = () => {
		setVisible(false);
	};

	const getPositionStyles = () => {
		switch (position) {
		case 'top':
			return { position: 'absolute', top: '-3rem' };
		case 'bottom':
			return { position: 'absolute', top: '1.4rem' };
		case 'left':
			return { position: 'absolute', left: '2rem' };
		case 'right':
			return { position: 'absolute', right: '2rem' };
		default:
			return {};
		}
	};

	return (
		<div
			className={`${Styles.tooltipWrapper} ${className}`}
			style={styles}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}>
			{children}
			<AnimatePresence>
				{visible && (
					<motion.div
						variants={tooltipFade}
						initial="hidden"
						animate="visible"
						style={getPositionStyles()}
						exit="exit">
						{component}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
