import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Styles from './paymentSection.module.scss';
import { ReactComponent as VisaIcon } from '../../../assets/visaIcon.svg';
import { ReactComponent as MasterCardIcon } from '../../../assets/masterCardIcon.svg';
import { ReactComponent as CardSkeleton } from '../../../assets/cardSkeletonIcon.svg';
import { Button } from '../../button';
import { ImageInput } from '../../imageInput';
import { FloatingLabelInput } from '../../floatingLabelInput';
import { ProgressSavedModal } from '../progressSavedModal';
import { toPriceFormat } from '../../../utils';

const InfoContainer = ({ text, value }) => (
	<div className={Styles.infoContainer}>
		<p className={Styles.infoText}>{text}</p>
		<p className={Styles.infoValue}>{value}</p>
	</div>
);

export const PaymentSection = ({
	updateStep,
	showProgressSaveModal,
	toggleProgressSaveModal,
}) => {
	const navigate = useNavigate();
	const [paymentMethod, setPaymentMethod] = useState('bank');
	const [, setTransactionID] = useState('');

	const purchasePropertyHandler = () => {
		// Dispatch purchase property handler

		navigate('/checkout/success?bought=whole', {
			state: {
				title: 'Congratulations',
				text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus sequi quo nam repellat sit, nisi repudiandae soluta molestias unde alias.'
			}
		});
	};

	return (
		<section>
			<ProgressSavedModal show={showProgressSaveModal} toggleHandler={toggleProgressSaveModal} />
			<div className="d-flex align-items-center justify-content-between pb-2">
				<h4 className="m-0">Shares</h4>
				<button className={Styles.editButton} onClick={() => updateStep(1)}>Edit</button>
			</div>
			<InfoContainer text="Number of Shares" value={92} />
			<InfoContainer text="Share Percentage" value='18%' />
			<InfoContainer text="Share Purchase Amount" value='18,000,000' />
			<div className="d-flex align-items-center justify-content-between pt-4 mt-3 pb-2">
				<h4>Your Details</h4>
				<button className={Styles.editButton} onClick={() => updateStep(2)}>Edit</button>
			</div>
			<InfoContainer text="Phone Number" value={+92019724972} />
			<InfoContainer text="CNIC Number" value={61101918274124} />
			<InfoContainer text="Mailing Address" value='77A, Street 11, Margalla Road Islamabad, 12345, Pakistan' />
			<div className="pt-4 mt-3 pb-2">
				<h4>Payment Summary</h4>
			</div>
			<InfoContainer text="Transaction Fee" value={toPriceFormat(10000)} />
			<InfoContainer text="Total Payable Amount" value={toPriceFormat(10242001)} />
			<div className={Styles.paymentMethodsContainer}>
				<div className={`${Styles.customRadio} ${paymentMethod === 'bank' && Styles.disabled}`} onClick={() => setPaymentMethod('bank')}>
					<div>
						<input
							type="radio"
							name="paymentType"
							id="cardRadio"
							onChange={() => setPaymentMethod('bank')}
							checked={paymentMethod === 'bank'}
						/>
						<label htmlFor="cardRadio">Debit/Credit Card</label>
					</div>
					<div>
						<VisaIcon className="mr-3" />
						<MasterCardIcon />
					</div>
				</div>
				{
					paymentMethod === 'bank'
					&& <div className={Styles.cardPaymentContainer}>
						<CardSkeleton />
						<p className="m-0">Credit/Debit Card Payments are coming soon</p>
					</div>
				}
				<div className={Styles.customRadio} onClick={() => setPaymentMethod('bank')}>
					<input
						type="radio"
						name="paymentType"
						id="bankRadio"
						onChange={() => setPaymentMethod('bank')}
						checked={paymentMethod === 'bank'}
					/>
					<label htmlFor="bankRadio">Bank Transfer</label>
				</div>
				{
					paymentMethod === 'bank'
					&& <div className={Styles.bankPaymentContainer}>
						<p className="m-0">Bank Name: Habib Bank Limited</p>
						<p className="m-0">Account Title: Hissay</p>
						<p className="m-0">Account Number: 12346192846</p>
						<p className="m-0">IBAN: PK145775174281242</p>
					</div>
				}
			</div>
			<div className="mt-4">
				<ImageInput
					id="receipt"
					name="receipt"
					verified={false}
					placeholder="Upload Bank Payment Receipt"
					handleChange={() => null}
				/>
			</div>
			<div className="mt-4">
				<FloatingLabelInput
					label="Transaction ID"
					type="text"
					onChange={(e) => setTransactionID(e.target.value)}
				/>
			</div>
			<div className={Styles.submitButtons}>
				<Button variant="secondary" text="Save & Exit" styles="w-100" onClick={toggleProgressSaveModal} />
				<Button variant="primary" text="Purchase Property" styles="w-100" onClick={purchasePropertyHandler} />
			</div>
			<p className="mt-4 pt-2">
				<span>By clicking "Purchase Property" you agree to Hissay </span>
				<Link to='/terms' target='_blank' className={Styles.link}>Terms</Link>
				<span> and </span>
				<Link to='/privacy-policy' target='_blank' className={Styles.link}>Privacy Policy</Link>
			</p>
		</section>
	);
};
