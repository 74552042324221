import { Col, Row } from 'react-bootstrap';
import Styles from './buyingProperty.module.scss';
import { BuyingShareVsWholeProperty } from '../../../assets/fractionalOwnershipScreen';
import { Container } from '../../container';

function BuyingProperty() {
	return (
		<div className="py-5">
			<Container>
				<Row className="align-items-center">
					<Col xs={12} lg={6}>
						<div className="w-100 text-right position-relative">
							<img src={BuyingShareVsWholeProperty} alt="Buying share vs. Whole property" width="100%" loading="lazy" className={Styles.sectionImage} />
						</div>
					</Col>
					<Col xs={12} lg={6}>
						<h2 className={Styles.sectionHeading}>
							Can I Just Buy a Whole Property on Hissay?
						</h2>
						<p className={Styles.sectionText}>
							Of course you can! We'll get the property you are looking to buy verified. After we
							have done all of the hard work, you will have a slick interface that you can use to
							track the price of your property. The best part? You can sell parts of your property
							on our platform later, if you decide you don't want to keep all of it!
						</p>
						<p className={Styles.sectionText}>
							We let you turn an asset that is usually stuck for years into a liquid
							asset that you can move whenever you want!
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export { BuyingProperty };
