import { useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as FavoriteIcon } from '../../../../assets/propertyCard/favoriteIcon.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/propertyCard/shareIcon.svg';
import { ReactComponent as ArrowBackNarrow } from '../../../../assets/arrowBackNarrow.svg';
import { Container } from '../../../container';
import { ShareSocialsModal } from '../../card/shareSocialsModal';
import Styles from './actionBar.module.scss';

export const ActionBar = () => {
	const shareContainerRef = useRef();
	const { success } = useSelector((state) => state.property);
	const [shareContainer, setShareContainer] = useState(false);
	const [activeSection, setActiveSection] = useState('overview');
	const [previousRoute, setPreviousRoute] = useState({
		url: '',
		title: ''
	});

	useEffect(() => {
		if (success) {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							setActiveSection(entry.target.id);
						}
					});
				},
				{ threshold: 1 }
			);

			const sections = document.querySelectorAll('[id^="section"]');

			sections.forEach((section) => {
				observer.observe(section);
			});

			return () => {
				sections.forEach((section) => {
					observer.unobserve(section);
				});
			};
		}

		return () => null;
	}, [activeSection, success]);

	useEffect(() => {
		const previousPage = sessionStorage.getItem('previousPage');

		if (previousPage) {
			const { pathname, title } = JSON.parse(previousPage);

			setPreviousRoute({ url: pathname, title: title.split('|')[0].trim() });
		} else {
			setPreviousRoute({ url: '/', title: 'Home' });
		}
	}, []);

	const shareSocialsModalHandler = () => {
		setShareContainer(!shareContainer);
	};

	return (
		<div className={Styles.actionBarContainer}>
			<Container>
				<div className={Styles.actionBar}>
					<div className={Styles.actionLinks}>
						<Link to={previousRoute.url} className={Styles.searchLink}>
							<ArrowBackNarrow /> {previousRoute.title}
						</Link>
						<div className={Styles.tabs}>
							<a
								href="#section-overview"
								className={`${Styles.actionLink} ${
									activeSection === 'section-overview' ? Styles.active : ''
								}`}>
									Overview
							</a>
							<a
								href="#section-details"
								className={`${Styles.actionLink} ${
									activeSection === 'section-details' ? Styles.active : ''
								}`}>
									Property Details
							</a>
							<a
								href="#section-location"
								className={`${Styles.actionLink} ${
									activeSection === 'section-location' ? Styles.active : ''
								}`}>
									Location <span className={Styles.nearbyText}>and Nearby</span>
							</a>
						</div>
					</div>
					<div className={Styles.actionButtons}>
						<button className={Styles.actionButton}>
							<FavoriteIcon className={Styles.favoriteIcon} />
							<span className={Styles.favoriteButtonText}>Favourite</span>
						</button>
						<div className="position-relative">
							<div onClick={shareSocialsModalHandler} className={Styles.actionButton}>
								<ShareIcon className={Styles.shareIcon} />
								<span className={Styles.shareButtonText}>Share</span>
							</div>
							{
								shareContainer
								&& <ShareSocialsModal
									url={window.location.href}
									innerRef={shareContainerRef}
									modalHandler={shareSocialsModalHandler} />
							}
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};
