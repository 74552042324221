import { Tooltip } from '../tooltip';
import Styles from './segmentedProgressBar.module.scss';

export const SegmentedProgressBar = ({ percentages }) => {
	const colorSegments = [
		{ color: '#893C6C', width: percentages[0] },
		{ color: '#81AD56', width: percentages[1] },
		{ color: '#346992', width: percentages[2] },
		{ color: '#59A4A4', width: percentages[3] },
		{ color: '#E4AF3D', width: percentages[4] },
	];

	return (
		<div className={Styles.progressBarOuter}>
			{colorSegments.map((segment, index) => (
				<Tooltip
					position="top"
					className={Styles.progressBarSegment}
					styles={{
						width: `${segment.width}%`,
						backgroundColor: segment.color
					}}
					component={
						<div className={`${Styles.tooltipContent} containerShadow`}>
							<p className="m-0">Awais A ({`${segment.width}%`})</p>
						</div>
					}
					key={index}>
				</Tooltip>
			))}
		</div>
	);
};
