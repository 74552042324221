import { useCallback } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgZoom from 'lightgallery/plugins/zoom';
import Styles from './imagesGrid.module.scss';
import { ReactComponent as MaximizeIcon } from '../../../assets/maximizeIcon.svg';

export const ImagesGrid = () => {
	const items = [
		{
			id: 1,
			url: 'https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHByb3BlcnR5fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60'
		},
		{
			id: 2,
			url: 'https://images.unsplash.com/photo-1600585153490-76fb20a32601?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fHByb3BlcnR5fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60'
		},
		{
			id: 1,
			url: 'https://images.unsplash.com/photo-1608429835892-30be51ea4d6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHByb3BlcnR5fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60'
		},
		{
			id: 1,
			url: 'https://images.unsplash.com/photo-1581279813180-4dddc1008167?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fHByb3BlcnR5fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60'
		},
		{
			id: 1,
			url: 'https://images.unsplash.com/photo-1613553507747-5f8d62ad5904?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fHByb3BlcnR5fGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60'
		},
	];

	const onInit = useCallback((detail) => {
		if (detail) {
			LightGallery.current = detail.instance;
		}
	}, []);

	const openGallery = useCallback((index) => {
		LightGallery.current.openGallery(index);
	}, []);

	const allImages = items.map((image, id) => {
		return { id, src: image.url };
	});

	return (
		<>
			<LightGallery
				elementClassNames="custom-classname"
				dynamic
				dynamicEl={allImages}
				onInit={onInit}
				plugins={[lgZoom]}
				download={false}
				mobileSettings={{ showCloseIcon: true }}
			/>
			<div className={Styles.imagesGrid}>
				<div onClick={() => openGallery(0)} className={`${Styles.imageContainer} ${items[0] && Styles.mainImage}`}>
					<img src={items[0].url} alt="property" width="100%" />
					<div className={Styles.maximizeIcon}><MaximizeIcon /></div>
					<div className={Styles.seeAllPhotos} onClick={() => openGallery(items[0])}>
						See all 10 photos
					</div>
				</div>
				<div onClick={() => openGallery(1)} className={`${Styles.imageContainer} ${items[1] && Styles.secondImage}`}>
					<img src={items[1].url} alt="property" width="100%" />
					<div className={Styles.maximizeIcon}><MaximizeIcon /></div>
				</div>
				<div onClick={() => openGallery(2)} className={`${Styles.imageContainer} ${items[2] && Styles.thirdImage}`}>
					<img src={items[2].url} alt="property" width="100%" />
					<div className={Styles.maximizeIcon}><MaximizeIcon /></div>
				</div>
				<div onClick={() => openGallery(3)} className={`${Styles.imageContainer} ${items[3] && Styles.fourthImage}`}>
					<img src={items[3].url} alt="property" width="100%" />
					<div className={Styles.maximizeIcon}><MaximizeIcon /></div>
				</div>
				<div className={`${Styles.imageContainer} ${items[4] && Styles.lastImage}`}>
					<img src={items[4].url} alt="property" width="100%" />
					<div className={Styles.maximizeIcon}><MaximizeIcon /></div>
					<div className={Styles.seeAllPhotos} onClick={() => openGallery(0)}>
						See all 10 photos
					</div>
				</div>
			</div>
		</>
	);
};
