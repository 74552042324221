import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { SegmentedProgressBar } from '../../../segmentedProgressBar';
import { ReactComponent as ShareIcon } from '../../../../assets/propertyCard/shareIcon.svg';
import { ReactComponent as PieChartGreyIcon } from '../../../../assets/pieChartGreyIcon.svg';
import { timeAgo, toPriceFormat } from '../../../../utils/index';
import { ShareSocialsModal } from '../shareSocialsModal';
import Styles from './card.module.scss';

export const PropertyCard = ({ property }) => {
	const shareContainerRef = useRef();
	const [shareContainer, setShareContainer] = useState(false);

	const shareSocialsModalHandler = () => {
		setShareContainer(!shareContainer);
	};

	return (
		<Card key={property.id} className={`${Styles.propertyCard} shadow-sm`}>
			<div className={Styles.postedTime}>{timeAgo(property.created_at)}</div>
			<Link to="/" className={Styles.linkImage}>
				<Card.Img variant="top" src={property.mainImage} />
			</Link>
			<Card.Body className="py-2">
				<div className={Styles.cardTitleContainer}>
					<h4 className="m-0">H#17B, ST#32</h4>
					<div className={Styles.icons}>
						<div className="position-relative">
							<ShareIcon className={Styles.icon} onClick={shareSocialsModalHandler} />
							{
								shareContainer
								&& <ShareSocialsModal
									url={property.url || window.location.href}
									innerRef={shareContainerRef}
									modalHandler={shareSocialsModalHandler}
								/>
							}
						</div>
						<PieChartGreyIcon className={Styles.icon} />
					</div>
				</div>
				<p className={Styles.descriptionText}>Park One Flats, Faisal Ave Islamabad</p>
				<p className="m-0 pt-3">Your Monthly Rental Income: Rs {toPriceFormat(property.price)}</p>
				<p className="m-0 pb-2">Your Share: 18%</p>
				<SegmentedProgressBar percentages={[20, 10, 15, 35, 20]} />
			</Card.Body>
		</Card>
	);
};
