import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { ReactComponent as GraphIcon } from '../../../../../assets/graphIcon.svg';
import { ReactComponent as ListviewIcon } from '../../../../../assets/favoriteProperties/listviewIcon.svg';
import { toPriceFormat } from '../../../../../utils';
import Styles from './priceInsightsTab.module.scss';

const data = [
	{
		name: 'Page A',
		uv: 4000,
		pv: 2400,
		amt: 2400,
	},
	{
		name: 'Page B',
		uv: 3000,
		pv: 1398,
		amt: 2210,
	},
	{
		name: 'Page C',
		uv: 2000,
		pv: 9800,
		amt: 2290,
	},
	{
		name: 'Page D',
		uv: 2780,
		pv: 3908,
		amt: 2000,
	},
	{
		name: 'Page E',
		uv: 1890,
		pv: 4800,
		amt: 2181,
	},
	{
		name: 'Page F',
		uv: 2390,
		pv: 3800,
		amt: 2500,
	},
	{
		name: 'Page G',
		uv: 3490,
		pv: 4300,
		amt: 2100,
	},
];

export const PriceInsightsTab = ({ listRender }) => {
	const [selectedView, setSelectedView] = useState('Table');

	return (
		<div className="mt-4">
			<div className={Styles.toggleButtons}>
				<button className={`${Styles.toggleButton} ${selectedView === 'Table' ? Styles.selected : null}`} onClick={() => setSelectedView('Table')}>
					<ListviewIcon />
					Table View
				</button>
				<button className={`${Styles.toggleButton} ${selectedView === 'Graph' ? Styles.selected : null}`} onClick={() => setSelectedView('Graph')}>
					<GraphIcon />
					Graph View
				</button>
			</div>
			{selectedView === 'Graph' && <div className="mt-4">
				<div className={Styles.graphsContainer}>
					<ResponsiveContainer width="100%" height={200}>
						<AreaChart
							width={500}
							height={200}
							data={data}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip />
							<Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
						</AreaChart>
					</ResponsiveContainer>
					<ResponsiveContainer width="100%" height={200}>
						<AreaChart
							width={500}
							height={200}
							data={data}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip />
							<Area type="monotone" dataKey="pv" stroke="#82ca9d" fill="#82ca9d" />
						</AreaChart>
					</ResponsiveContainer>
				</div>
			</div>}
			{selectedView === 'Table' && (
				<Row className="mt-4 justify-content-between">
					<Col lg={5} md={6} className="justify-content-between">
						{listRender('Current Price', `Rs. ${toPriceFormat(200000)}`)}
						{listRender('Price 6 months ago', `Rs. ${toPriceFormat(140000)}`)}
						{listRender('Price 12 months ago', `Rs. ${toPriceFormat(500000)}`)}
					</Col>
					<Col lg={5} md={6}>
						{listRender('Max Price', `Rs. ${toPriceFormat(120000)}`)}
						{listRender('Min Price', `Rs. ${toPriceFormat(40000)}`)}
					</Col>
				</Row>
			)}
		</div>
	);
};
