import { useEffect, useState } from 'react';
import { Container } from '../container';
import { PortfolioSummarySection } from './portfolioSummarySection';
import { TransactionHistoryTable } from './transactionHistoryTable';
import { TransactionHistoryTableMobileView } from './transactionHistoryTable/mobileView';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Styles from './transactionHistoryScreen.module.scss';

export const TransactionHistoryScreen = () => {
	const currentDate = new Date();
	const isLargeScreen = useMediaQuery('(min-width: 992px)');
	const [transactions, setTransactions] = useState([]);
	const [filterOption, setFilterOption] = useState('');
	const [sortOption, setSortOption] = useState('');

	useEffect(() => {
		setTransactions([
			{
				id: 1,
				address: 'H#17B, St 27, DHA Phase 2, Islamabad',
				date: currentDate,
				transactionID: '12345678',
				transactionType: 'Purchased',
				value: 163000,
				shares: 1000,
				status: 'Pending',
				invoice: ''
			},
			{
				id: 2,
				address: 'H#17B, St 27, DHA Phase 2, Islamabad',
				date: currentDate,
				transactionID: '12345678',
				transactionType: 'Purchased',
				value: 163000,
				shares: 1000,
				status: 'Completed',
				invoice: ''
			},
			{
				id: 3,
				address: 'H#17B, St 27, DHA Phase 2, Islamabad',
				date: currentDate,
				transactionID: '12345678',
				transactionType: 'Purchased',
				value: 163000,
				shares: 1000,
				status: 'Cancelled',
				invoice: ''
			},
			{
				id: 4,
				address: 'H#17B, St 27, DHA Phase 2, Islamabad',
				date: currentDate,
				transactionID: '12345678',
				transactionType: 'Purchased',
				value: 163000,
				shares: 1000,
				status: 'Pending',
				invoice: ''
			},
			{
				id: 5,
				address: 'H#17B, St 27, DHA Phase 2, Islamabad',
				date: currentDate,
				transactionID: '12345678',
				transactionType: 'Purchased',
				value: 163000,
				shares: 1000,
				status: 'Pending',
				invoice: ''
			},
			{
				id: 5,
				address: 'H#17B, St 27, DHA Phase 2, Islamabad',
				date: currentDate,
				transactionID: '12345678',
				transactionType: 'Purchased',
				value: 163000,
				shares: 1000,
				status: 'Pending',
				invoice: ''
			}
		]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filterHandler = (e) => {
		setFilterOption(e.target.value);
	};

	const sortHandler = (e) => {
		setSortOption(e.target.value);
	};

	return (
		<main className="my-5 py-5">
			<Container>
				<h1 className="pb-2">Portfolio Summary</h1>
				<PortfolioSummarySection />
				<div className="d-flex align-items-center justify-content-between flex-wrap mt-5 pb-4">
					<h2>Transaction History</h2>
					<div className={Styles.filterSortButtons}>
						<div className={Styles.filterContainer}>
							<label className="p-0 m-0">Filter:</label>
							<select
								onChange={filterHandler}
								value={filterOption}
								className={Styles.filterSelecter}>
								<option value="" disabled>Select filters</option>
								<option value="pending">Pending</option>
								<option value="completed">Completed</option>
								<option value="cancelled">Cancelled</option>
							</select>
						</div>
						<div className={`ml-md-4 ${Styles.sortContainer}`}>
							<label className="p-0 m-0">Sort:</label>
							<select
								onChange={sortHandler}
								value={sortOption}
								className={Styles.filterSelecter}>
								<option value="" disabled>Select sort options</option>
								<option value="recentToOld">Date (Recent to Old)</option>
								<option value="oldToRecent">Date (Old to Recent)</option>
								<option value="aToz">Alphabetical (A to Z)</option>
								<option value="zToA">Alphabetical (Z to A)</option>
								<option value="highToLow">Amount (High to Low)</option>
								<option value="LowToHigh">Amount (Low to High)</option>
							</select>
						</div>
					</div>
				</div>
				{
					isLargeScreen
						? <TransactionHistoryTable transactions={transactions} />
						: <TransactionHistoryTableMobileView transactions={transactions} />
				}
			</Container>
		</main>
	);
};
