import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import * as Yup from 'yup';
import Styles from './signInScreen.module.scss';
import { FloatingLabelInput } from '../../floatingLabelInput';
import { loginUser } from '../../../store/auth/authActions';
import { successToast, errorToast } from '../../../utils';
import { ReactComponent as VisibilityOn } from '../../../assets/auth/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../../assets/auth/visibility_off.svg';
import { emailValidator } from '../../../utils/yupValidators';
import { Spinner } from '../../spinner';

const validationSchema = Yup.object().shape({
	email: emailValidator,
});

function SignInScreen() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading } = useSelector((state) => state.auth);
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [validationErrors, setValidationErrors] = useState([]);
	const [fields, setFields] = useState({
		email: '',
		password: ''
	});

	useEffect(() => {
		if (location?.state?.alertVariant) {
			if (location?.state?.alertVariant === 'success') {
				successToast(location?.state?.alertMessage);
			} else if (location?.state?.alertVariant === 'danger') {
				errorToast(location?.state?.alertMessage);
			}

			navigate(location.pathname, {});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state?.alertMessage, location?.state?.alertVariant]);

	const submitForm = (event) => {
		event.preventDefault();

		validationSchema
			.validate(fields, { abortEarly: false })
			.then(() => {
				dispatch(loginUser({
					email: fields.email,
					password: fields.password
				}))
					.unwrap()
					.then(() => {
						event.target.reset();

						successToast('User Signed In Successfully');

						navigate('/sign-up/success');
					})
					.catch((errorData) => {
						errorToast(errorData.error);
					});
			})
			.catch((error) => setValidationErrors(error.errors));
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setValidationErrors([]);
		setFields({ ...fields, [name]: value });
	};

	return (
		<div className={Styles.signInContainer}>
			<Toaster/>
			<div className={Styles.formContainer}>
				<h2 className="mb-3 font-weight-bold">Sign In</h2>
				<p className="mb-4">
					<span>Don't have an account yet? </span>
					<Link to="/sign-up" className={Styles.link}>Join Hissay</Link>
				</p>
				<Form onSubmit={submitForm}>
					<Form.Group>
						<FloatingLabelInput
							label="Email"
							type="text"
							id="email"
							name="email"
							onChange={handleInputChange}
							required={true} />
					</Form.Group>
					<Form.Group className={Styles.passwordField}>
						<FloatingLabelInput
							label="Password"
							type={passwordVisibility ? 'text' : 'password'}
							id="password"
							name="password"
							onChange={handleInputChange}
							required={true} />
						<div
							className={Styles.visibilityIcon}
							onClick={() => setPasswordVisibility(!passwordVisibility)}>
							{passwordVisibility ? <VisibilityOn/> : <VisibilityOff/>}
						</div>
					</Form.Group>
					<div className="d-flex justify-content-end">
						<Link to='/forgot-password' target='_blank' className={Styles.link}>Forgot Password?</Link>
					</div>
					<div className='text-center mt-5'>
						{
							validationErrors.length > 0
							&& <p className={Styles.validationError}>{validationErrors[0]}</p>
						}
						<div className='d-flex align-items-center justify-content-center'>
							<button disabled={loading} className={`${Styles.signInButton} ${loading ? 'mr-3' : null}`}>
								Sign In
							</button>
							{loading && <Spinner />}
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
}

export { SignInScreen };
