import { useEffect, useState, React } from 'react';
import { Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import Countdown from 'react-countdown';
import pluralize from 'pluralize';
import Styles from './heroSection.module.scss';

const imageFiles = [
	'hero1x1.png', 'hero1x2.png', 'hero1x3.png', 'hero1x4.png',
	'empty', 'hero2x2.png', 'hero2x3.png', 'hero2x4.png',
	'empty', 'hero3x2.png', 'hero3x3.png', 'hero3x4.png',
	'hero4x1.png', 'hero4x2.png', 'hero4x3.png', 'hero4x4.png'
];

const HeroSection = () => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const getRandomImageIndex = () => {
		const nonEmptyIndices = imageFiles
			.map((_, index) => index)
			.filter((index) => imageFiles[index] !== 'empty');

		return nonEmptyIndices[Math.floor(Math.random() * nonEmptyIndices.length)];
	};

	useEffect(() => {
		const imageInterval = setInterval(() => {
			setCurrentImageIndex(getRandomImageIndex());
		}, 4000);

		return () => clearInterval(imageInterval);
	}, []);

	const CountDownComplete = () => <span>Hissay is now live</span>;

	const renderer = ({
		days, hours, minutes, completed
	}) => {
		if (completed) {
			return <CountDownComplete />;
		}

		return (
			<span className={Styles.countdownTimer}>{days} {pluralize('Days', days)} {hours} {pluralize('Hours', hours)} and {minutes} {pluralize('Minutes', minutes)}</span>
		);
	};

	return (
		<div className={Styles.heroSection}>
			<Row>
				<Col xs={12} lg={6}>
					<div className={Styles.contentContainer}>
						<h1 className={`${Styles.heroSectionHeading} mb-4`}>
							Property Investment<br/> for Everyone
						</h1>
						<h5 className={`${Styles.heroSectionDescription} mb-md-5 mb-4`}>
							Find your dream properties and own as much or as little as you like.
							<br />
							Become a property investor with Hissay
						</h5>
						<h3>Join us for our launch in</h3>
						<Countdown
							date={new Date(2024, 0, 0)}
							renderer={renderer}
							zeroPadTime
							zeroPadDays
						/>
					</div>
				</Col>
				<Col xs={12} lg={6} className={Styles.gridColumn}>
					<div className={Styles.imagesContainer}>
						{imageFiles.map((image, index) => (
							<motion.div
								key={index}
								animate={{
									opacity: currentImageIndex === index ? 0 : 1,
									transitionDuration: 2,
									transitionProperty: 'ease-out'
								}}
								transition={{ duration: 3 }}>
								{
									image !== 'empty'
									&& <img
										width="100%"
										src={require(`../../../assets/landingScreen/${image}`)}
										alt="Hero-pic"
									/>
								}
							</motion.div>
						))}
					</div>
				</Col>
			</Row>
		</div>
	);
};

export { HeroSection };
