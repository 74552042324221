import { Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { FloatingLabelInput } from '../../../floatingLabelInput';
import { Button } from '../../../button';
import { countriesData } from '../../../../utils/countriesData';
import { TitleBar } from '../titleBar';
import { BasicInformationSection } from './basicInformationSection';
import { CnicInformationSection } from './cnicInformationSection';
import { MailingAddressSection } from './mailingAddressSection';
import { ChangePasswordModal } from './changePasswordModal';
import { successToast } from '../../../../utils';
import {
	cnicValidator,
	emailValidator,
	firstNameValidator,
	lastNameValidator,
	phoneNumberValidator,
	postalCodeValidator
} from '../../../../utils/yupValidators';
import { fetchUserProfileInfo } from '../../../../store/user/userActions';
import { Spinner } from '../../../spinner';

const validationSchema = Yup.object().shape({
	firstName: firstNameValidator,
	lastName: lastNameValidator,
	email: emailValidator,
	phoneNumber: phoneNumberValidator,
	cnic: cnicValidator,
	postalCode: postalCodeValidator,
	streetAddress: Yup.string().required('Street Address is required')
});

export const PersonalInformation = () => {
	const [cnicImages, setCnicImages] = useState({
		cnicFront: '',
		cnicBack: ''
	});
	const [selectedCity, setSelectedCity] = useState('');
	const [cities, setCities] = useState([]);
	const [validationErrors, setValidationErrors] = useState([]);
	const dispatch = useDispatch();
	const { loading, user, success } = useSelector((state) => state.user);
	const [countries] = useState(
		Object.keys(countriesData).map((country) => ({ value: country, label: country }))
	);
	const [selectedCountry, setSelectedCountry] = useState(
		countries.length > 0 ? countries[104].value : ''
	);
	const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
	const [fields, setFields] = useState({
		firstName: '',
		lastName: '',
		email: '',
		cnic: '',
		phoneNumber: '',
		password: '',
		streetAddress: '',
		postalCode: ''
	});

	const handleCountryChange = useCallback((e) => {
		const selectedCountryValue = e.target.value;
		const citiesForSelectedCountry = countriesData[selectedCountryValue] || [];

		setSelectedCountry(selectedCountryValue);
		setSelectedCity(citiesForSelectedCountry[0] || '');
		setCitiesOptions(citiesForSelectedCountry);
		setValidationErrors([]);
	}, []);

	const setCitiesOptions = (citiesForSelectedCountry) => {
		const citiesOptions = citiesForSelectedCountry.map((city) => ({ value: city, label: city }));

		setCities(citiesOptions);
	};

	useEffect(() => {
		const initialCitiesForSelectedCountry = countriesData[countries[104].value];

		setSelectedCity(initialCitiesForSelectedCountry[0] || '');
		setCitiesOptions(initialCitiesForSelectedCountry);
	}, [countries]);

	const handleCnicImageChange = useCallback((e) => {
		setCnicImages({
			...cnicImages,
			[e.target.name]: URL.createObjectURL(e.target.files[0])
		});
	}, [cnicImages]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setValidationErrors([]);
		setFields((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		validationSchema
			.validate(fields, { abortEarly: false })
			.then(() => {
				successToast('Profile Updated Successfully');
			})
			.catch((error) => setValidationErrors(error.errors));
	};

	const toggleChangePasswordModal = () => {
		setIsChangePasswordModalVisible(!isChangePasswordModalVisible);
	};

	useEffect(() => {
		dispatch(fetchUserProfileInfo());
	}, [dispatch]);

	return (
		<>
			<Toaster />
			<form onSubmit={handleSubmit}>
				<TitleBar
					title="Personal Information"
					buttonDisabled={false}
					clickHandler={(e) => handleSubmit(e)}
				/>
				{
					loading
						? <div className="w-100 text-center">
							<Spinner />
							<p>Fetching User Details ...</p>
						</div>
						: <div>
							<BasicInformationSection
								handleInputChange={handleInputChange}
								validationErrors={validationErrors}
								userData={success ? user : {}}
							/>
							<CnicInformationSection
								cnicImages={cnicImages}
								handleInputChange={handleInputChange}
								handleCnicImageChange={handleCnicImageChange}
							/>
							<MailingAddressSection
								handleInputChange={handleInputChange}
								handleCountryChange={handleCountryChange}
								selectedCity={selectedCity}
								selectedCountry={selectedCountry}
								setSelectedCity={setSelectedCity}
								countries={countries}
								cities={cities}
							/>
						</div>
				}
				<h4 className="mt-5 pb-2">Security</h4>
				<Row className="mr-0">
					<Col xs={12} className="pr-0">
						<FloatingLabelInput
							label="Password"
							type="password"
							id="password"
							name="password"
							onChange={handleInputChange}
							required={false}
						/>
					</Col>
				</Row>
				<Button variant="primary" type="button" text="Change Password" onClick={toggleChangePasswordModal}/>
			</form>
			<ChangePasswordModal
				show={isChangePasswordModalVisible}
				onHide={toggleChangePasswordModal}
			/>
		</>
	);
};
