import {
	useCallback, useEffect, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getRecommendedProperties } from '../../../store/property/propertyActions';
import { Button } from '../../button';
import { Spinner } from '../../spinner';
import { PropertyCard } from '../card';

export const RecommendedProperties = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [page, setPage] = useState(0);
	const { properties, loading } = useSelector((state) => state.property);

	const fetchRecommendedProperties = useCallback(() => {
		dispatch(getRecommendedProperties({
			propertyID: id,
			loaded: page,
			limit: 6
		}));
	}, [dispatch, id, page]);

	const loadMore = () => {
		setPage(page + 1);
	};

	useEffect(() => {
		fetchRecommendedProperties(page);
	}, [fetchRecommendedProperties, page]);

	return (
		<>
			<h3 className="font-weight-bold pb-2">Recommended For You</h3>
			{
				properties?.length > 0 && (
					<div>
						<Row>
							{properties.map((property, index) => (
								<Col key={index} md={6} xl={4} className="my-3">
									<PropertyCard property={property} reload={true} />
								</Col>
							))}
						</Row>
						<div className="text-center mt-4">
							{
								properties.length > 0 && !loading
									? <Button variant="secondary" onClick={loadMore} text="Load More" />
									: <Spinner />
							}
						</div>
					</div>
				)
			}
		</>
	);
};
