import { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/arrowDownIcon.svg';
import Styles from './readMoreText.module.scss';

export const ReadMoreText = ({ children, characterCount }) => {
	const text = children;
	const [isReadMore, setIsReadMore] = useState(true);

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	return (
		<span>
			{isReadMore ? text.slice(0, characterCount || 100) : text}
			{text.length > (characterCount || 100) && (
				<span onClick={toggleReadMore} className={Styles.continueReadingButton}>
					{isReadMore && (
						<>
							<span className={Styles.continueReadingText}>Continue Reading</span> <ArrowDown />
						</>
					)}
				</span>
			)}
		</span>
	);
};
