import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Styles from './details.module.scss';
import { DetailsTabs } from './detailsTabs';
import { LocationTabs } from './locationTabs';
import { ActionBar } from './actionBar';
import { BuyBox } from './buyBox';
import { ImagesGrid } from './imagesGrid';
import { Container } from '../../container';
import { timeAgo, toPriceFormat } from '../../../utils';
import { ReadMoreText } from '../../readMoreText';
import { getPropertyDetails } from '../../../store/property/propertyActions';
import { RecommendedProperties } from '../recommendedProperties';
import { Spinner } from '../../spinner';

const PropertyDetailsScreen = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { property, success } = useSelector((state) => state.property);

	useEffect(() => {
		setLoading(true);

		dispatch(getPropertyDetails({
			propertyID: id
		}))
			.unwrap()
			.catch(() => navigate('/'))
			.finally(() => setLoading(false));
	}, [dispatch, id, navigate]);

	return (
		<main>
			{loading && (
				<div className={Styles.spinnerContainer}>
					<Spinner className={Styles.spinner} />
				</div>
			)}
			{
				!success
					? <></>
					: <>
						<ActionBar />
						<section className={`${Styles.mainContainer} pb-4 pt-2`}>
							<Container>
								<ImagesGrid images={property.property_images} />
								<Row className="pt-5 pb-4">
									<Col xl={8}>
										<div className={Styles.propertyInfo}>
											<article className={`${Styles.flexColumn} mr-5`}>
												<h1 className={Styles.itemValue}>Rs. {toPriceFormat(property.price)}</h1>
												<h5 className={Styles.itemText}>{property.location}</h5>
											</article>
											<div className={Styles.propertyInfoItems}>
												<article className={Styles.flexColumn}>
													<h2 className={Styles.itemValue}>{property.bedrooms}</h2>
													<h5 className={Styles.itemText}>Beds</h5>
												</article>
												<article className={Styles.flexColumn}>
													<h2 className={Styles.itemValue}>{property.bathrooms}</h2>
													<h5 className={Styles.itemText}>Baths</h5>
												</article>
												<article className={Styles.flexColumn}>
													<h2 className={Styles.itemValue}>{property.land_area}</h2>
													<h5 className={Styles.itemText}>{property.land_area_unit}</h5>
												</article>
											</div>
										</div>
										<div className="pt-4 mt-2">
											<h3 className="font-weight-bold pb-2">About</h3>
											<p className={Styles.aboutText}>
												<ReadMoreText characterCount={240}>
													{property.description}
												</ReadMoreText>
											</p>
											<div className="d-flex align-items-center mb-3 mb-xl-0">
												<p className="pr-sm-5 pr-3 border-right border-secondary">Listed By: {property.listedBy}</p>
												<p className="pl-sm-5 pl-3">Added: {timeAgo(property.created_at)}</p>
											</div>
										</div>
									</Col>
									<BuyBox property={property} />
								</Row>
								<Row className={`${Styles.tabsContainer} mt-xl-0 mt-3 pb-4`}>
									<Col xl={8}>
										<div id="section-details" className="pb-4">
											<h3 className="font-weight-bold pb-3">Details</h3>
											<DetailsTabs property={property} />
										</div>
										<div id="section-location" className="pt-4 pb-5 mt-3">
											<h3 className="font-weight-bold pb-3">Location and Nearby</h3>
											<LocationTabs />
										</div>
									</Col>
								</Row>
								<div className="py-5">
									<RecommendedProperties />
								</div>
							</Container>
						</section>
					</>
			}
		</main>
	);
};

export { PropertyDetailsScreen };
