import { useEffect } from 'react';
import Styles from './privacyPolicyScreen.module.scss';

function PrivacyPolicyScreen() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, []);

	return (
		<main>
			<div className="container py-5">
				<h1 className={Styles.title}>Privacy Policy</h1>
				<p>Last revised on September 10, 2023</p>
				<p>
					Hello and welcome to the Privacy Policy for Hissay, a service operated by Hissay
					Pvt. Ltd. Please read this document carefully as helps you make informed decisions
					about your relationship with us. Take the time to read our other documents (for
					example, our Term of Use) referenced in this document to know what you are
					agreeing to by using our services and how we make careful use of your data. Once
					you’ve read it all, let’s make you into a real property investor.
				</p>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>1. Introduction</h2>
					<p>
						Thanks for choosing Hissay. Before getting into the details of our privacy
						policy, we’d like to highlight some of the key principles behind it. These
						principles are important to us because we know they’re important to you.
					</p>
					<p>
						The Privacy Policy is here to do three things:
					</p>
					<ol>
						<li>
							Explain the way we use information that you share with us in order to build a
							great product and give you a great experience with it;
						</li>
						<li>
							Ensure that you understand what information we collect with your permission,
							and what we do — and do not do — with it;
						</li>
						<li>
							Hold us accountable for protecting your rights and your privacy under this policy.
						</li>
					</ol>
					<p>
						All the information we collect is related to providing the Hissay service and its
						features. Broadly speaking, there are two categories of information we collect: 1)
						information that we must have in order for you to use Hissay; and 2) information
						that we can use to provide additional features and improved experiences if you
						choose to share that information.
					</p>
					<br />
					<p>
						The first category includes:
					</p>
					<ul>
						<li>
							Registration information, including your name, email address, and other information
							you provide when you sign up for Hissay using one of our social sign up options
							like Facebook or via email.
						</li>
						<li>
							Broad, non-specific location, which we derive from your IP address.
						</li>
						<li>
							Interactions on the Service. This allows us to make recommendations to you and suggest
							relevant content.
						</li>
						<li>
							Technical and sensor information necessary to operate Hissay. This includes the type
							of browser and device you use, data from the touchscreen, and information from your
							device’s accelerometer and gyroscope sensors.
						</li>
					</ul>
					<p>
						The second category includes information that enables us to offer you additional
						features. We never receive any of the following information unless you expressly choose
						to share it with us. It’s as simple as that. Here are some examples:
					</p>
					<ul>
						<li>
							Your specific location: We will never gather or use your specific device location
							without first getting your explicit permission.
						</li>
						<li>
							Your photos: We will only access images that you specifically choose, and we will
							never scan or import your photo library or camera roll. This allows you to choose
							individual pictures to change your profile picture. You can stop sharing photos and
							revoke access at any time.
						</li>
						<li>
							Your contacts: We will never scan or import your contacts unless you ask us to. If you
							choose to do so, we will only use your contact information to help you find friends or
							contacts who use Hissay.
						</li>
						<li>
							Your microphone: We will never access or use your microphone unless you give us
							explicit permission. This could enable you to control Hissay with your voice, and you
							will always have the ability to disable access to the microphone.
						</li>
					</ul>
					<p>
						The distinction between these two categories is important. Information in the first
						category is information you must provide in order to use Hissay. When you agree to our
						Privacy Policy, you give us the right to collect this information and use it for the
						purposes described. Information in the second category is information we will only
						collect if you explicitly give us permission to do so in the future. For this second
						category of information, we will ask for your permission before first accessing it, we
						will describe how we will use it if you give us permission, we will only use the
						information for the purpose we described, and you will always have the ability to change
						your mind and revoke that permission. Acceptance of our Privacy Policy does not mean you
						have granted us permission to access or use information in the second category; we’re
						just explaining to you that one day we might ask you for that permission.
					</p>
					<br />
					<p>
						We will be clear with you about how and when we might share information.
					</p>
					<br />
					<p>
						In some contexts, we will share certain information. For example, like most services,
						when you sign up for Hissay through a third party like Facebook, we share some
						information with them and they share some information with us in order to enable your
						account. There also may be times when you want us to share information. For example,
						when you make a property investment, you might want to share your purchase with your
						friends.
					</p>
					<br />
					<p>
						We hope this helps you to understand our privacy commitments to you. Now, the details
						await you below, but please remember that this Introduction is part of the Privacy
						Policy itself—it’s our promise to you. If you ever have any questions or concerns, then
						please let us know at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>.
					</p>
					<br />
					<p>
						Throughout this Privacy Policy we refer to the Hissay Service or the Service or the
						Services. These terms are defined in our <a href='/terms'>Terms and Conditions of Use</a> (the “Terms and
						Conditions of Use”).
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>2. Key Highlights of What You’re Consenting to</h2>
					<p>
						By using or interacting with the Services, you are consenting to:
					</p>
					<ol>
						<li>
							the use of cookies and other technologies;
						</li>
						<li>
							the collection, use, sharing, and other processing of your information, including for
							advertising-related purposes (as described in later this Privacy Policy); and
						</li>
						<li>
							the public availability of your information and the controls over such information as
							described in Sharing information.
						</li>
					</ol>
					<p>
						In each case, you consent to the processing of data by the entities described in this
						Privacy Policy, including the Hissay entity, as data controller, indicated at the
						bottom of this document (collectively, “Hissay”, “we”, “us”, “our”). If you don't agree
						with the terms of this Privacy Policy, then please don't use the Service.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>3. The Information We Collect</h2>
					<h4 className={Styles.heading}>
						3.1 Registration Data
					</h4>
					<p>
						When you connect to the Service using credentials from a Third Party Application (as
						defined in the <a href='/terms'>Terms and Conditions of Use</a>) (e.g., Facebook), you authorise us to
						collect your authentication information, such as your username and encrypted access
						credentials. We may also collect other information available on or through your Third
						Party Application account, including, for example, your name, profile picture, country,
						hometown, email address, date of birth, gender, friends’ names and profile pictures,
						and networks.
					</p>
					<p>
						Alternatively, if you create an account using your email address you will have to
						provide us basic identifying information such as your name, date of birth, etc.
					</p>
					<p>
						You may also choose to voluntarily add other information to your profile, such as your
						mobile phone number and mobile service provider.
					</p>
					<h4 className={Styles.heading}>
						3.2 Usage, Log Data & Cookies
					</h4>
					<p>
						When you use or interact with the Service, we may use a variety of technologies that
						collect information about how the Service is accessed and used. This information may
						include:
					</p>
					<ol>
						<li>
							information about your interactions with the Service, such as interactions with
							titles, lists, other audiovisual content, other Hissay users, Third Party
							Applications, and advertising, products, and services which are offered, linked to,
							or made available on or through the Service;
						</li>
						<li>
							the details of the queries you make and the date and time of your request;
						</li>
						<li>
							User Content (as defined in the <a href='/terms'>Terms and Conditions of Use</a>) you post to the Service
							including messages you send and/or receive via the Service;
						</li>
						<li>
							technical data, which may include URL information, cookie data, your IP address,
							the types of devices you are using to access or connect to the Hissay Service,
							unique device ID, device attributes, network connection type (e.g., WiFi, 3G, LTE)
							and provider, network and device performance, browser type, language, information
							enabling digital rights management, operating system, and Hissay application version.
						</li>
						<li>
							motion-generated or orientation-generated mobile sensor data (e.g., accelerometer
							or gyroscope).
						</li>
					</ol>
					<p>
						You may integrate your Hissay account with Third Party Applications. If you do, we may
						receive similar information related to your interactions with the Service on the Third
						Party Application, as well as information about your publicly available activity on the
						Third Party Application. This includes, for example, your “Like”s and posts on Facebook.
						We may use cookies and other technologies to collect this information
					</p>
					<h4 className={Styles.heading}>
						3.3 Your Mobile Device
					</h4>
					<p>
						We may provide features that rely on the use of additional information on your mobile
						device or require access to certain services on your mobile device that will enhance
						your Hissay experience but are not required to use the Service. (In other words,
						information that falls in the second category described in the Introduction to this
						Policy.)
					</p>
					<p>
						For example, we might allow you to upload photos to your profile, connect with friends,
						or let you use voice commands to control the Service. Granting us access does not mean
						you are granting us unlimited access to that information or that we will access specific
						information without your permission. To the contrary, for each type of information
						listed in this section, before we access this information or these features of your
						mobile device, we will ask for your permission. If you provide such permission, we will
						collect the information for the specific purposes explained at the time we ask for your
						permission. You do not have to give us such permission in order to use Hissay, and
						acceptance of this Privacy Policy does not mean you have granted us permission to access
						this information.
					</p>
					<p>
						In particular:
					</p>
					<ul>
						<li>
							Photos and Camera: We will not access your photos or camera without first getting
							your explicit permission and we will never scan or import your photo library or camera
							roll. If you give us permission to access photos or your camera, we will only use
							images that you specifically choose to share with us. (You may use our application to
							select the photo or photos you choose to share, but we will never import the photos
							you review except those you explicitly share.).
						</li>
						<li>
							Location: We will not gather or use the specific location of your mobile device (by
							using, for example, GPS or Bluetooth) without first getting your explicit permission.
							And if you choose to share location information but later change your mind, you will
							always have the ability to stop sharing. Please note that this does not include IP
							address. We will continue to use your IP address as described in Section 3.2 above, to
							determine, for example, what country you are in and comply with our licensing
							agreements.
						</li>
						<li>
							Voice: We will not access your microphone without first getting your explicit
							permission. You will always have the ability to disable microphone access.
						</li>
						<li>
							Contacts: We will not scan or import your contacts stored on your phone without first
							getting your explicit permission. We will only use the contact information to help you
							find friends or contacts who use Hissay if you choose to do so, and we will not use
							contact information for any other purpose without first getting your separate explicit
							permission. Local law may require that you seek the consent of your contacts to
							provide their personal information to Hissay, which may use that information for the
							purposes specified in this Privacy Policy.
						</li>
					</ul>
					<h4 className={Styles.heading}>
						3.4 Payment Data
					</h4>
					<p>
						If you make purchases through the Service, your credit or debit card information (such
						as card type and expiration date) and other financial data that we need to process your
						payment may be collected and stored by us and/or the payment processors with which we
						work. We may also collect some limited information, such as your postal code, mobile
						number, and details of your transaction history, all of which are necessary to provide
						the Service. In addition, the payment processors generally provide us with some limited
						information related to you, such as a unique token that enables you to make additional
						purchases using the information they’ve stored, and your card’s type, expiration date,
						and certain digits of your card number.
					</p>
					<p>
						BY ACCEPTING THE PRIVACY POLICY, YOU EXPRESSLY AUTHORISE HISSAY TO USE AND SHARE WITH
						OTHER COMPANIES IN THE HISSAY GROUP, AS WELL AS CERTAIN TRUSTED BUSINESS PARTNERS AND
						SERVICE PROVIDERS, WHICH MAY BE LOCATED OUTSIDE OF THE COUNTRY OF YOUR RESIDENCE
						(INCLUDING COUNTRIES WHICH DO NOT PROVIDE THE SAME LEVEL OF PROTECTION FOR THE
							PROCESSING OF PERSONAL DATA AS THE COUNTRY OF YOUR RESIDENCE), THE INFORMATION
						PROVIDED BY YOU TO HISSAY, EVEN IF SUCH INFORMATION IS COVERED BY LOCAL BANKING SECRECY
						LAWS. YOU ACKNOWLEDGE AND AGREE TO THE IMPORTANCE OF SHARING SUCH INFORMATION FOR THE
						PROVISION OF THE HISSAY SERVICE AND ALSO AGREE THAT, BY ACCEPTING THIS PRIVACY POLICY,
						WHERE APPLICABLE AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY WAIVE YOUR
						RIGHTS UNDER SUCH BANK SECRECY LAWS WITH REGARD TO HISSAY, ANY COMPANY IN THE HISSAY
						GROUP, AND ANY TRUSTED BUSINESS PARTNERS AND SERVICE PROVIDERS, WHICH MAY BE LOCATED
						OUTSIDE YOUR COUNTRY OF RESIDENCE. THIS CONSENT IS GIVEN FOR THE DURATION OF YOUR
						RELATIONSHIP WITH HISSAY.
					</p>
					<h4 className={Styles.heading}>
						3.5 Sweepstakes, Contests & Surveys
					</h4>
					<p>
						From time to time, we may offer you the opportunity to participate in promotions such as
						sweepstakes, contests, offers, and/or surveys (“Special Promotions”) through the
						Services. A Special Promotion may be governed by a privacy policy and/or terms and
						conditions that are additional to, or separate from, this Privacy Policy and the
						<a href='/terms'> Terms and Conditions of Use</a>. If the provisions of the Special Promotion’s privacy policy or
						terms and conditions conflict with this Privacy Policy or the <a href='/terms'>Terms and Conditions of Use</a>, those additional or separate provisions shall prevail. If you participate in a
						Special Promotion, we may ask you for certain information in addition to what is stated
						in this Privacy Policy, including personal information. That additional information may
						be combined with other account information and may be used and shared as described in
						this Privacy Policy.
					</p>
					<h4 className={Styles.heading}>
						3.6 Hissay Service Providers and Partners
					</h4>
					<p>
						We may also receive information about you from our service providers and partners, which
						we use to personalise your Hissay experience, to measure ad quality and responses to
						ads, and to display ads that are more likely to be relevant to you. We also use this
						information as explained in the section How we use the information we collect below.
					</p>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>4. How We Use the Information We Collect</h2>
					<p>
						Consistent with the permissions you give us to collect the information, we may use the
						information we collect, including your personal information:
					</p>
					<ol>
						<li>
							to provide, personalize, and improve your experience with the Services and products,
							services, and advertising (including for third party products and services) made
							available on or outside the Services (including on other sites that you visit), for
							example by providing customized, personalized, or localized content, recommendations,
							features, and advertising on or outside of the Services;
						</li>
						<li>
							to ensure technical functionality of the Service, develop new products and services,
							and analyse your use of the Service, including your interaction with applications,
							advertising, products, and services that are made available, linked to, or offered
							through the Service;
						</li>
						<li>
							to communicate with you for Service-related or research purposes including via emails,
							notifications, text messages, or other messages, which you agree to receive;
						</li>
						<li>
							to communicate with you, either directly or through one of our partners, for marketing
							and promotional purposes via emails, notifications, or other messages, consistent with
							any permissions you may have communicated to us (e.g., through your Hissay account
							settings);
						</li>
						<li>
							to, if you explicitly provide us your permission, use your mobile phone number to send
							you informational, marketing and promotional text messages using an automatic dialing
							system; you are not required to provide consent as a condition of signing up for
							Hissay’s services;
						</li>
						<li>
							to enable and promote the Service and other services or products, either within or
							outside the Service, including features and content of the Service and products and
							services made available through the Service;
						</li>
						<li>
							to process your payment or prevent or detect fraud;
						</li>
						<li>
							to enforce this Privacy Policy, the <a href='/terms'>Terms and Conditions of Use</a>, and any other terms
							that you have agreed to, including to protect the rights, property, or safety of
							Hissay, its users, or any other person, or the copyright-protected content of the
							Service;
						</li>
						<li>
							to provide you with features, information, advertising, or other content which is
							influenced by your location and your location in relation to other Hissay users;
							and
						</li>
						<li>
							as otherwise stated in this Privacy Policy.
						</li>
					</ol>
				</div>
				<div className={Styles.term}>
					<h2 className={Styles.heading}>5. Sharing Information</h2>
					<p>
						This section describes how the information collected or generated through your use of
						the Hissay service may be shared by you or by us.
					</p>
					<h4 className={Styles.heading}>
						5.1 Sharing By You
					</h4>
					<h5 className={Styles.heading}>
						5.1.1 Generally
					</h5>
					<p>
						The Hissay Service is a service that offers many ways to find, invest, and share
						properties with other. We encourage you to take advantage of social features with
						others on the Service.
					</p>
					<br />
					<p>
						Your name and/or username, profile picture, and your Hissay user profile will not
						always be publicly available. We do our best to ensure investment data is reasonably
						private, unless where otherwise required to be public for the sake of transparency.
						In most cases your first name and last initial are considered to be public but no
						information that is easily identifible is ever made public.
					</p>
					<br />
					<p>
						Please remember that certain information is always publicly available, which means
						that it can be accessed by Third Party Applications through our APIs and developer
						tools. Similarly, you may consent to provide Third Party Applications with access
						to your email address, subscription status, location, birthday, or similar
						information, upon their request.
					</p>
					<br />
					<p>
						We may use your public information to promote the Service and to help others find
						and follow you, including through Facebook and other Third Party Applications. Your
						information may be used to notify others, including within the Service, via email,
						and through Facebook and Third Party Applications, about your use of the Service
						(e.g., that you have joined Hissay or activity related to a Hissay profile).
					</p>
					<br />
					<p>
						With your explicit permission, we may notify other users of your location. Likewise,
						with other Hissay users’ explicit permission, we may notify you about their
						whereabouts. We may offer you the ability to share this information to create
						shared experiences.
					</p>
					<h4 className={Styles.heading}>
						5.2 Sharing by Hissay
					</h4>
					<h5 className={Styles.heading}>
						5.2.1 Marketing and Advertising
					</h5>
					<p>
						We may share information with advertising partners in order to send you promotional
						communications about Hissay or to show you more tailored content, including relevant
						advertising for products and services that may be of interest to you, and to understand
						how users interact with advertisements. The information we share is in a de-identified
						format (for example, through the use of hashing) that does not personally identify you.
						Please see the section Information about cookies, other technologies, and third-party
						data collection below for information about the cookies and other technologies that we
						and third parties use to serve you more relevant ads and your options.
					</p>
					<h5 className={Styles.heading}>
						5.2.2 Service Providers and Others
					</h5>
					<p>
						We may from time to time share your information with service providers to perform
						functions and process user data and help provide our Services, consistent with this
						Privacy Policy. Where a third party processes user data on our behalf, it is subject
						to security and confidentiality obligations consistent with this Privacy Policy and
						applicable law. Where a third party processes user data on its own behalf, its
						processing is subject to its own Privacy Policy and applicable law.
					</p>
					<h5 className={Styles.heading}>
						5.2.3 Other Sharing
					</h5>
					<p>
						In addition to the above, we may also share your information with third parties for
						these limited purposes:
					</p>
					<ul>
						<li>
							to allow a merger, acquisition, or sale of all or a portion of our assets;
						</li>
						<li>
							to respond to legal process (e.g., a court order or subpoena), if we believe in
							good faith that it is necessary to do so; to comply with requirements of
							mandatory applicable law; to protect the safety of any person; to protect the
							rights and property of Hissay, including to enforce the <a href='/terms'>Terms and Conditions of Use</a>
							and any other terms that you have agreed to; and to address fraud, security,
							or technical issues;
						</li>
						<li>
							to academic researchers for purposes including statistical analysis and academic
							study, but only in a de-identified format;
						</li>
						<li>
							to publish de-identified or aggregate information about the use of the Hissay Service;
							and
						</li>
						<li>
							to allow other companies in the Hissay group to use your information as specified in
							this Privacy Policy.
						</li>
					</ul>
					<div className={Styles.term}>
						<h2 className={Styles.heading}>6. Links</h2>
						<p>
							We may display advertisements from third parties and other content that links to third
							party websites. We cannot control or be held responsible for third parties’ privacy
							practices and content. Please read their privacy policies to find out how they collect
							and process your personal information.
						</p>
					</div>
					<div className={Styles.term}>
						<h2 className={Styles.heading}>7. Transfer to Other Countries</h2>
						<p>
							We are a growing corporation with users and operations in multiple countries. We have
							developed data practices designed to assure information is appropriately protected but
							we cannot always know where personal information may be accessed or processed. While
							our primary data centers are in the United States, we may transfer personal
							information or other information to our offices outside of the United States. In
							addition, we may employ other companies and individuals to perform functions on our
							behalf. If we disclose personal information to a third party or to our employees
							outside of the United States, we will seek assurances that any information we may
							provide to them is safeguarded adequately and in accordance with this Privacy Policy
							and the requirements of applicable privacy laws.
						</p>
					</div>
					<div className={Styles.term}>
						<h2 className={Styles.heading}>8. Security</h2>
						<p>
							We are committed to protecting our users’ information. Your password protects your
							user account, so you should use a unique and strong password, limit access to your
							computer and browser, and log out after having used the Hissay Service. While we take
							data protection precautions, no security measures are completely secure, and we do not
							guarantee the security of user information.
						</p>
					</div>
					<div className={Styles.term}>
						<h2 className={Styles.heading}>9. Children</h2>
						<p>
							The Hissay Service is not directed to children under the age of 18. (In some
							countries, stricter age limits may apply. See our <a href='/terms'>Terms and Conditions of Use</a>.) We do
							not knowingly collect personal information from children under 13 or under the
							applicable age limit (the “Age Limit”). If you are under the Age Limit, do not use the
							Service and do not provide any personal information to us. If you are a parent of a
							child under the Age Limit and become aware that your child has provided personal
							information to Hissay, please contact us at support@hissay.pk and you may request
							exercise of your applicable access, rectification, cancellation, and/or objection
							rights. If you are a California resident under the age of 18 and you wish to remove
							publicly available content, please contact us at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>.
						</p>
					</div>
					<div className={Styles.term}>
						<h2 className={Styles.heading}>10. Accessing and Updating User Information</h2>
						<p>
							Hissay gets its information for profiles from Facebook or through direct entry by the
							User. If you want to update your Hissay profile information, please go to your
							settings. If you have questions about your privacy on the Service, this privacy
							policy, or information we have about you, please contact us at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>.
						</p>
					</div>
					<div className={Styles.term}>
						<h2 className={Styles.heading}>11. Changes to the Privacy Policy</h2>
						<p>
							Occasionally we may, in our discretion, make changes to the Agreements. When we make
							material changes to the Agreements, we’ll provide you with prominent notice as
							appropriate under the circumstances, e.g., by displaying a prominent notice within
							the Service or by sending you an email or by displaying a banner on the website. In
							some cases, we will notify you in advance, and your continued use of the Service after
							the changes have been made will constitute your acceptance of the changes. Please
							therefore make sure you read any such notice carefully. If you do not wish to continue
							using the Service under the new version of the Agreements, you may terminate the
							Agreements by contacting us at <span><a href='mailto:support@hissay.pk'> support@hissay.pk</a></span>.
						</p>
					</div>
				</div>
			</div>
		</main>
	);
}

export { PrivacyPolicyScreen };
