import { useEffect } from 'react';
import Styles from './stepsBar.module.scss';
import { ReactComponent as UnfilledTickIcon } from '../../../assets/unfilledTickIcon.svg';
import { ReactComponent as FilledTickIcon } from '../../../assets/filledGreenTickIcon.svg';
import { ReactComponent as ArrowRight } from '../../../assets/arrowRightNoTailIcon.svg';

export const StepsBar = ({ step = 1 }) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (
		<div className={Styles.steps}>
			<div className={`${Styles.step} ${Styles.current}`}>
				{step !== 1 ? <FilledTickIcon /> : <UnfilledTickIcon className={Styles.unfilledTickIcon} />}
				<span className={Styles.stepTitle}>Calculate Share</span>
				<span className={Styles.rightArrow}><ArrowRight width={14} /></span>
			</div>
			<div className={`${Styles.step} ${(step === 2 || step === 3) && Styles.current}`}>
				{step === 3 ? <FilledTickIcon /> : <UnfilledTickIcon className={Styles.unfilledTickIcon} />}
				<span className={Styles.stepTitle}>Your Details</span>
				<span className={Styles.rightArrow}><ArrowRight width={14} /></span>
			</div>
			<div className={`${Styles.step} ${step === 3 && Styles.current}`}>
				<UnfilledTickIcon className={Styles.unfilledTickIcon} />
				<span className={Styles.stepTitle}>Payment</span>
			</div>
		</div>
	);
};
