import { createSlice } from '@reduxjs/toolkit';
import { registerUser, loginUser, logoutUser } from './authActions';

const userAccessToken = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo')).access_token
	: null;

const user = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo')).user
	: null;

const initialState = {
	loading: false,
	user,
	accessToken: userAccessToken,
	error: null,
	success: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			state.user = action.payload.user;
			state.accessToken = action.payload.accessToken;

			localStorage.setItem('userInfo', JSON.stringify(action.payload));
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(registerUser.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.user = payload;
				state.error = null;
				state.success = true;
			})
			.addCase(registerUser.rejected, (state, { payload }) => {
				state.loading = false;
				state.success = false;
				state.error = payload.error;
			});

		builder
			.addCase(loginUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(loginUser.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.user = payload.user;
				state.accessToken = payload.user.access_token;
				state.error = null;
				state.success = true;

				localStorage.setItem('userInfo', JSON.stringify(payload));
			})
			.addCase(loginUser.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload.error;
			});

		builder
			.addCase(logoutUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(logoutUser.fulfilled, (state) => {
				state.loading = false;
				state.user = null;
				state.accessToken = null;
				state.success = true;
				state.error = null;

				localStorage.removeItem('userInfo');
			})
			.addCase(logoutUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload.error;
			});
	}
});

export default authSlice.reducer;
export const { setCredentials } = authSlice.actions;
