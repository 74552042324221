import { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import Styles from './changePasswordModel.module.scss';
import { FloatingLabelInput } from '../../../../floatingLabelInput';
import { passwordValidator } from '../../../../../utils/yupValidators';
import { successToast } from '../../../../../utils';

export const ChangePasswordModal = (props) => {
	const { show, onHide } = props;
	const [validationErrors, setValidationErrors] = useState([]);

	const [passwordFields, setPasswordFields] = useState({
		currentPassword: '',
		newPassword: '',
		confirmPassword: ''
	});

	const validationSchema = Yup.object().shape({
		currentPassword: Yup.string().required('You must enter your Current Password'),
		newPassword: passwordValidator,
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
			.required('You must confirm your password'),
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setValidationErrors([]);
		setPasswordFields((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		validationSchema
			.validate(passwordFields, { abortEarly: false })
			.then(() => {
				successToast('Password Changed Successfully');

				onHide();
			})
			.catch((error) => setValidationErrors(error.errors));
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			centered
			className={Styles.modalWrapper}
			dialogClassName={Styles.modalDialog}>
			<Modal.Body>
				<h4 className={`pt-3 pb-3 ${Styles.modalHeading}`}>Change Password</h4>
				<div className={Styles.formContainer}>
					<Form onSubmit={handleSubmit}>
						<FloatingLabelInput
							label="Current Password"
							id="currentPassword"
							name="currentPassword"
							onChange={handleInputChange}
							sensitiveField={true}
							required={true} />
						<FloatingLabelInput
							label="New Password"
							id="newPassword"
							name="newPassword"
							onChange={handleInputChange}
							sensitiveField={true}
							required={true} />
						<FloatingLabelInput
							label="Confirm Password"
							id="confirmPassword"
							name="confirmPassword"
							onChange={handleInputChange}
							sensitiveField={true}
							required={true} />
						<div className="text-center mt-5">
							{
								validationErrors.length > 0
								&& <p className={Styles.validationError}>{validationErrors[0]}</p>
							}
							<div className={Styles.modalButtonsContainer}>
								<button className={`m-2 px-2 ${Styles.cancelButton}`} onClick={onHide}>
									Cancel
								</button>
								<button className={`px-2 ${Styles.submitButton}`}>
									Change Password
								</button>
							</div>
						</div>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};
