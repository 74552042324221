import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import Styles from './yourDetails.module.scss';
import { FloatingLabelInput } from '../../floatingLabelInput';
import { ImageInput } from '../../imageInput';
import { countriesData } from '../../../utils/countriesData';
import { FloatingDropdownInput } from '../../floatingDropdownInput';
import { Button } from '../../button';
import { cnicValidator, phoneNumberValidator, postalCodeValidator } from '../../../utils/yupValidators';
import { ProgressSavedModal } from '../progressSavedModal';

const validationSchema = Yup.object().shape({
	cnic: cnicValidator,
	phoneNumber: phoneNumberValidator,
	streetAddress: Yup.string().required('Street Address is required'),
	postalCode: postalCodeValidator
});

export const YourDetails = ({ updateStep, toggleProgressSaveModal, showProgressSaveModal }) => {
	const [fields, setFields] = useState({
		cnic: '',
		phoneNumber: '',
		streetAddress: '',
		postalCode: ''
	});

	const [cnicImages, setCnicImages] = useState({ cnicFront: '', cnicBack: '' });
	const [selectedCity, setSelectedCity] = useState('');
	const [cities, setCities] = useState([]);
	const [validationErrors, setValidationErrors] = useState([]);

	const [countries] = useState(
		Object.keys(countriesData).map((country) => ({ value: country, label: country }))
	);

	const [selectedCountry, setSelectedCountry] = useState(
		countries.length > 0 ? countries[104].value : ''
	);

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setFields((prevData) => ({ ...prevData, [name]: value }));
		setValidationErrors([]);
	};

	const handleCnicImageChange = useCallback((e) => {
		setCnicImages({
			...cnicImages,
			[e.target.name]: URL.createObjectURL(e.target.files[0])
		});
	}, [cnicImages]);

	const setCitiesOptions = (citiesForSelectedCountry) => {
		const citiesOptions = citiesForSelectedCountry.map((city) => ({ value: city, label: city }));

		setCities(citiesOptions);
	};

	useEffect(() => {
		const initialCitiesForSelectedCountry = countriesData[countries[104].value];

		setSelectedCity(initialCitiesForSelectedCountry[0] || '');
		setCitiesOptions(initialCitiesForSelectedCountry);
	}, [countries]);

	const handleCountryChange = useCallback((e) => {
		const selectedCountryValue = e.target.value;
		const citiesForSelectedCountry = countriesData[selectedCountryValue] || [];

		setSelectedCountry(selectedCountryValue);
		setSelectedCity(citiesForSelectedCountry[0] || '');
		setCitiesOptions(citiesForSelectedCountry);
		setValidationErrors([]);
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();

		validationSchema
			.validate(fields, { abortEarly: false })
			.then(() => {
				updateStep(3);
			})
			.catch((error) => setValidationErrors(error.errors));
	};

	return (
		<section>
			<ProgressSavedModal show={showProgressSaveModal} toggleHandler={toggleProgressSaveModal} />
			<form onSubmit={handleSubmit}>
				<h4 className="mt-4 pb-1 pt-3">Contact Information</h4>
				<FloatingLabelInput
					label="Phone Number"
					type="number"
					id="phoneNumber"
					name="phoneNumber"
					onChange={handleInputChange}
					required={true}
				/>
				<h4 className="mt-4 pb-1 pt-3">CNIC Information</h4>
				<FloatingLabelInput
					label="CNIC Number"
					type="text"
					id="cnic"
					name="cnic"
					onChange={handleInputChange}
					required={true}
				/>
				<div className="mb-3 pb-1">
					<ImageInput
						id="cnicFront"
						name="cnicFront"
						cnicImage={cnicImages.cnicFront}
						verified={false}
						placeholder="Upload CNIC (Front Side)"
						handleChange={handleCnicImageChange}
					/>
				</div>
				<ImageInput
					id="cnicBack"
					name="cnicBack"
					cnicImage={cnicImages.cnicBack}
					inReview={false}
					placeholder="Upload CNIC (Back Side)"
					handleChange={handleCnicImageChange}
				/>
				<h4 className="mt-4 pb-1 pt-3">Mailing Address</h4>
				<FloatingLabelInput
					label="Street Address"
					type="text"
					id="streetAddress"
					name="streetAddress"
					onChange={handleInputChange}
					required={true}
				/>
				<Row className="mr-0">
					<Col xs={12} lg={6} className="pr-0">
						<FloatingDropdownInput
							id="city"
							label="City"
							onChange={(e) => setSelectedCity(e.target.value)}
							name="city"
							options={cities}
							value={selectedCity}
						/>
					</Col>
					<Col xs={12} lg={6} className="pr-0">
						<FloatingLabelInput
							label="Postal Code"
							type="text"
							id="postalCode"
							name="postalCode"
							onChange={handleInputChange}
							required={true}
						/>
					</Col>
				</Row>
				<FloatingDropdownInput
					id="country"
					label="Country"
					onChange={handleCountryChange}
					name="country"
					options={countries || null}
					value={selectedCountry}
				/>
				<p style={{
					color: 'red',
					textAlign: 'center',
					opacity: validationErrors.length > 0 ? 1 : 0
				}}>
					{validationErrors[0] || 'No error'}
				</p>
				<div className={Styles.submitButtons}>
					<Button
						variant="secondary"
						text="Save & Exit"
						styles="w-100"
						type="button"
						onClick={toggleProgressSaveModal}
					/>
					<Button variant="primary" text="Continue" styles="w-100" />
				</div>
			</form>
		</section>
	);
};
