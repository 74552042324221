import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PropertyCard } from '../../../properties/card/v3';
import { ReactComponent as FireIcon } from '../../../../assets/fireIcon.svg';
import Styles from './pendingProperties.module.scss';

export const PendingProperties = () => {
	const [properties, setProperties] = useState([]);

	useEffect(() => {
		// Todo: Dispatch get pending properties here
		setProperties([
			{
				id: 1,
				created_at: '2022-03-27T03:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 6920024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				status: 'closing soon',
				location: 'DHA Defence Phase 2, DHA Defense, Islamabad'
			},
			{
				id: 2,
				created_at: '2022-07-21T04:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				status: <div className="d-flex align-items-center"><FireIcon /> &nbsp; Hot</div>,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad'
			},
			{
				id: 3,
				created_at: '2022-07-27T09:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 3,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 2, DHA Defense, Islamabad'
			}
		]);
	}, []);

	return (
		<section>
			<div className={Styles.titleContainer}>
				<h1>Pending Properties</h1>
				<h4 className="font-weight-normal">2 properties</h4>
			</div>
			<Row className={Styles.cards}>
				{
					properties?.map((property) => (
						<Col xs={12} xl={6} className="pl-0 pb-3" key={property.id}>
							<PropertyCard property={property} favoriteIcon={false} />
						</Col>
					))
				}
			</Row>
		</section>
	);
};
