import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import Styles from './checkoutScreen.module.scss';
import { CalculateShare } from './calculateShare';
import { StepsBar } from './stepsBar';
import { ImagesGrid } from './imagesGrid';
import { YourDetails } from './yourDetails';
import { PageOverlaySpinner } from '../pageOverlaySpinner';
import { PaymentSection } from './paymentSection';
import { ProgressBar } from '../progressBar';
import { toPriceFormat } from '../../utils';

const detailInfo = (title, value) => {
	return (
		<div className="d-flex align-items-center justify-content-between">
			<p>{title}</p>
			<p className="font-weight-bold">{value}</p>
		</div>
	);
};

export const CheckoutScreen = () => {
	const [currentStep, setCurrentStep] = useState(1);
	const [saving,] = useState(false);
	const [purchased, setPurchased] = useState('');
	const [progressSaveModal, setProgressSaveModal] = useState(false);

	const updateStep = (value) => {
		setCurrentStep(value);
	};

	const updatePerchased = (value) => {
		setPurchased(value);
	};

	const toggleProgressSaveModal = () => {
		setProgressSaveModal(!progressSaveModal);
	};

	return (
		<main>
			{saving && <PageOverlaySpinner />}
			{
				!purchased
				&& <Row>
					<Col xl={6} className={Styles.leftColumn}>
						<h4>Hello Johny</h4>
						<h2>Make this Property Yours</h2>
						<StepsBar step={currentStep} />
						{
							currentStep === 1
							&& <CalculateShare
								updateStep={updateStep}
								toggleProgressSaveModal={toggleProgressSaveModal}
								showProgressSaveModal={progressSaveModal}
							/>
						}
						{
							currentStep === 2
							&& <YourDetails
								updateStep={updateStep}
								toggleProgressSaveModal={toggleProgressSaveModal}
								showProgressSaveModal={progressSaveModal}
							/>
						}
						{
							currentStep === 3
							&& <PaymentSection
								updateStep={updateStep}
								updatePerchased={updatePerchased}
								showProgressSaveModal={progressSaveModal}
								toggleProgressSaveModal={toggleProgressSaveModal}
							/>
						}
					</Col>
					<Col xl={6} className={Styles.rightColumn}>
						<h2>House#17B, DHA</h2>
						<h4 className={Styles.addressLine2}>DHA Phase 2, Islamabad</h4>
						<p className={`${Styles.count} mb-1`}>
						Available Shares: {toPriceFormat(10000000)}/{toPriceFormat(7000000)}
						</p>
						<div>
							<div className={Styles.priceContainer}>
								<p className={`${Styles.count} m-0`}>Price Per Share: 1.00</p>
								<h4 className={`${Styles.target} m-0`}>Target: Rs. 10 Crore</h4>
							</div>
							<ProgressBar
								total="10000000"
								current="7000000"
								note="Property is not closed until 100% of the funds for closure are raised."
							/>
						</div>
						<ImagesGrid />
						<Row className="mt-4">
							<Col md={6}>
								{detailInfo('Home Type', 'Flat')}
								{detailInfo('Bedrooms', '2')}
								{detailInfo('Year Built', '1997')}
								{detailInfo('Parking Space in sqft', '5000')}
							</Col>
							<Col md={6}>
								{detailInfo('Total Area', '5.5 Marla')}
								{detailInfo('Bathrooms', '2')}
								{detailInfo('Flooring', '1')}
								{detailInfo('Furnished', 'Yes')}
							</Col>
						</Row>
					</Col>
				</Row>
			}
		</main>
	);
};
