import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';

export const fetchUserProfileInfo = createAsyncThunk('user/details', async (_, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.get('/users/details');

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data.message || error.message
		});
	}
});
