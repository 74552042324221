import React from 'react';
import Styles from './floatingDropdownInput.module.scss';

export const FloatingDropdownInput = ({
	id,
	label,
	required,
	selectRef,
	onChange,
	name,
	options,
	value,
}) => {
	return (
		<div className={Styles.floatingInputField}>
			<select
				ref={selectRef}
				name={name}
				id={id}
				onChange={onChange}
				value={value}
				className={Styles.select}
				required={required}>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
			<label className={Styles.label} htmlFor={id}>
				{label}
			</label>
		</div>
	);
};
