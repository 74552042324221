import { Col, Row } from 'react-bootstrap';
import { FloatingLabelInput } from '../../../../floatingLabelInput';

export const BasicInformationSection = ({ handleInputChange, validationErrors, userData }) => {
	return (
		<>
			<div className="mt-3 pb-2">
				<p style={{
					color: 'red',
					opacity: validationErrors.length > 0 ? 1 : 0
				}}>
					{validationErrors[0] || 'No error'}
				</p>
				<h4>Basic Information</h4>
			</div>
			<Row className="mr-0">
				<Col xs={12} lg={6} className="pr-0">
					<FloatingLabelInput
						label="First Name"
						type="text"
						id="firstName"
						name="firstName"
						onChange={handleInputChange}
						required={true}
						value={userData.first_name}
					/>
				</Col>
				<Col xs={12} lg={6} className="pr-0">
					<FloatingLabelInput
						label="Last Name"
						type="text"
						id="lastName"
						name="lastName"
						onChange={handleInputChange}
						required={true}
						value={userData.last_name}
					/>
				</Col>
			</Row>
			<Row className="mr-0">
				<Col xs={12} lg={6} className="pr-0">
					<FloatingLabelInput
						label="Email"
						type="email"
						id="email"
						name="email"
						onChange={handleInputChange}
						required={true}
						value={userData.email}
					/>
				</Col>
				<Col xs={12} lg={6} className="pr-0">
					<FloatingLabelInput
						label="Phone Number"
						type="number"
						id="phoneNumber"
						name="phoneNumber"
						onChange={handleInputChange}
						required={true}
						value={userData.phone_number}
					/>
				</Col>
			</Row>
		</>
	);
};
