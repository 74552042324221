import * as Yup from 'yup';

export const firstNameValidator = Yup.string()
	.max(50, 'First name must be at most 50 characters long')
	.matches(/^[a-zA-Z\s]+$/, 'First name should contain only letters and spaces')
	.required('First name is required');

export const lastNameValidator = Yup.string()
	.max(50, 'Last name must be at most 50 characters long')
	.matches(/^[a-zA-Z\s]+$/, 'Last name should contain only letters and spaces')
	.required('Last name is required');

export const emailValidator = Yup.string()
	.max(100, 'Email must be at most 100 characters long')
	.matches(/^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is not valid')
	.required('Email is required');

export const passwordValidator = Yup.string()
	.required('Password is required')
	.min(8, 'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special')
	.matches(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
		'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
	);

export const phoneNumberValidator = Yup.string()
	.matches(
		/^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
		'Invalid phone number'
	)
	.required('Phone Number is required');

export const cnicValidator = Yup.string()
	.matches(
		/^[0-9]{13}$/,
		'Invalid CNIC number'
	)
	.required('CNIC is required');

export const postalCodeValidator = Yup.string()
	.matches(
		/^[0-9]{5}$/,
		'Invalid postal code'
	)
	.required('Postal Code is required');
