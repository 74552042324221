import { Link, useNavigate } from 'react-router-dom';
import { Row, Form, Col } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import * as Yup from 'yup';
import Styles from './signUpScreen.module.scss';
import { FloatingLabelInput } from '../../floatingLabelInput';
import { registerUser } from '../../../store/auth/authActions';
import { successToast, errorToast } from '../../../utils';
import { ReactComponent as VisibilityOn } from '../../../assets/auth/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../../assets/auth/visibility_off.svg';
import {
	emailValidator, firstNameValidator, lastNameValidator, passwordValidator
} from '../../../utils/yupValidators';
import { Spinner } from '../../spinner';

const validationSchema = Yup.object().shape({
	firstName: firstNameValidator,
	lastName: lastNameValidator,
	email: emailValidator,
	password: passwordValidator,
});

function SignUpScreen() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const redirectTimeout = useRef();
	const { loading } = useSelector((state) => state.auth);
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [validationErrors, setValidationErrors] = useState([]);
	const [fields, setFields] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: ''
	});

	useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);

	const submitForm = (event) => {
		event.preventDefault();

		validationSchema
			.validate(fields, { abortEarly: false })
			.then(() => {
				dispatch(registerUser({
					firstName: fields.firstName,
					lastName: fields.lastName,
					email: fields.email,
					password: fields.password
				}))
					.unwrap()
					.then(() => {
						successToast('User Registered Successfully');

						event.target.reset();

						redirectTimeout.current = setTimeout(() => navigate('/sign-up/success'), 2000);
					})
					.catch((errorData) => {
						errorToast(errorData.error);
					});
			})
			.catch((error) => setValidationErrors(error.errors))
			.finally(() => clearTimeout(redirectTimeout.current));
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setValidationErrors([]);
		setFields({ ...fields, [name]: value });
	};

	return (
		<div className={Styles.signUpContainer}>
			<Toaster />
			<div className={Styles.formContainer}>
				<h2 className="mb-3 font-weight-bold">Join Hissay</h2>
				<p className="mb-4">
					<span>Have an account? </span>
					<Link to="/sign-in" className={Styles.link}>Sign in</Link>
				</p>
				<Form onSubmit={submitForm}>
					<Form.Group as={Row}>
						<Col xs={12} md={6} className={Styles.firstNameColumn}>
							<FloatingLabelInput
								label="First Name"
								type="text"
								id="firstName"
								value={fields.firstName}
								name="firstName"
								onChange={handleInputChange}
								required={true}
							/>
						</Col>
						<Col xs={12} md={6} className={Styles.lastNameColumn}>
							<FloatingLabelInput
								label="Last Name"
								type="text"
								id="lastName"
								name="lastName"
								value={fields.lastName}
								onChange={handleInputChange}
								required={true}
							/>
						</Col>
					</Form.Group>
					<Form.Group>
						<FloatingLabelInput
							label="Email"
							type="text"
							id="email"
							name="email"
							value={fields.email}
							onChange={handleInputChange}
							required={true}
						/>
					</Form.Group>
					<Form.Group className={Styles.passwordField}>
						<FloatingLabelInput
							label="Password"
							type={passwordVisibility ? 'text' : 'password'}
							id="password"
							name="password"
							value={fields.password}
							onChange={handleInputChange}
							required={true}
						/>
						<div
							className={Styles.visibilityIcon}
							onClick={() => setPasswordVisibility(!passwordVisibility)}>
							{passwordVisibility ? <VisibilityOn/> : <VisibilityOff/>}
						</div>
					</Form.Group>
					<p>
						<span>By clicking "Join Now" you agree to Hissay </span>
						<Link to='/terms' target='_blank' className={Styles.link}>Terms</Link>
						<span> and </span>
						<Link to='/privacy-policy' target='_blank' className={Styles.link}>Privacy Policy</Link>
					</p>
					<div className='text-center mt-5'>
						{
							validationErrors.length > 0
							&& <p className={Styles.validationError}>{validationErrors[0]}</p>
						}
						<div className='d-flex align-items-center justify-content-center'>
							<button disabled={loading} className={`${Styles.joinButton} ${loading ? 'mr-3' : null}`}>
								Join Now
							</button>
							{loading && <Spinner />}
						</div>
					</div>
				</Form>
			</div>
		</div>
	);
}

export { SignUpScreen };
