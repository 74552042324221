import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Styles from './pendingPurchases.module.scss';
import { PropertyCard } from '../../../properties/card';

export const PendingPurchases = () => {
	const [purchases, setPurchases] = useState([]);

	useEffect(() => {
		setPurchases([
			{
				id: 2,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 477
			},
			{
				id: 2,
				created_at: '2023-06-03T18:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: false,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad',
				totalValue: 500,
				purchaseValue: 477
			}
		]);
	}, []);

	return (
		<section>
			<div className={Styles.titleContainer}>
				<h1>Pending Purchases</h1>
				<h4 className="font-weight-normal">2 properties</h4>
			</div>
			<Row className={Styles.cards}>
				{
					purchases?.map((property) => (
						<Col xs={12} xl={6} className="pl-0 pb-3" key={property.id}>
							<PropertyCard property={property} showProgress={true} />
						</Col>
					))
				}
			</Row>
		</section>
	);
};
