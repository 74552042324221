import { Routes, Route } from 'react-router-dom';
import { ErrorScreen } from '../errorScreen';
import { FavoritePropertiesScreen } from '../properties/favorites';
import { Footer } from '../footer';
import { LandingScreen } from '../landingScreen/landingScreen';
import { PrivacyPolicyScreen } from '../privacyPolicyScreen';
import { ProfileScreen } from '../user/profile';
import { PropertyDetailsScreen } from '../properties/details';
import { PublicRoute } from '../publicRoute';
import { SignUpScreen } from '../auth/signUpScreen';
import { SignInScreen } from '../auth/signInScreen';
import { TermsScreen } from '../termsScreen';
import Styles from './app.module.scss';
import { CheckoutScreen } from '../checkoutScreen';
import { CheckoutSuccessScreen } from '../checkoutSuccessScreen';
import { SignupSuccessScreen } from '../auth/signupSuccessScreen';
import { TransactionHistoryScreen } from '../transactionHistoryScreen';
import { FractionalOwnershipScreen } from '../fractionalOwnsershipScreen';
import { KnowledgeHubScreen } from '../knowledgeHubScreen';
import { FaqScreen } from '../faqScreen/faqScreen';

function App() {
	return (
		<div>
			<Layout>
				<Routes>
					<Route path="/" element={<PublicRoute title="Home" Component={LandingScreen} />} />
					<Route path="/terms" element={<PublicRoute title="Terms and Conditions" Component={TermsScreen} />} />
					<Route path="/privacy-policy" element={<PublicRoute title="Privacy Policy" Component={PrivacyPolicyScreen} />} />
					<Route path="/sign-up" element={<PublicRoute restricted={true} title="Sign Up" Component={SignUpScreen} />} />
					<Route path="/fractional-ownership" element={<PublicRoute title="Fractional Ownership" Component={FractionalOwnershipScreen} />} />
					<Route path="/sign-up/success" element={<PublicRoute title="Sign Up Success" Component={SignupSuccessScreen} />} />
					<Route path="/sign-in" element={<PublicRoute restricted={true} title="Sign In" Component={SignInScreen} />} />
					<Route path="/properties/favorites" element={<PublicRoute title="Favorite Properties" Component={FavoritePropertiesScreen} />} />
					<Route path="/properties/:id" element={<PublicRoute title="Property Details" Component={PropertyDetailsScreen} />} />
					<Route path="/profile" element={<PublicRoute title="User Profile" Component={ProfileScreen} />} />
					<Route path="/checkout" element={<PublicRoute title="Checkout" Component={CheckoutScreen} />} />
					<Route path="/knowledge-hub" element={<PublicRoute title="Knowledge Hub" Component={KnowledgeHubScreen} />} />
					<Route path="/knowledge-hub/faq" element={<PublicRoute title="Frequently Asked Questions" Component={FaqScreen} />} />
					<Route path="/checkout/success" element={<PublicRoute title="Checkout Success" Component={CheckoutSuccessScreen} />} />
					<Route path="/transaction-history" element={<PublicRoute title="Transaction History" Component={TransactionHistoryScreen} />} />
					<Route path="/*" element={<PublicRoute title="Error Screen" Component={ErrorScreen} />} />
				</Routes>
				<Footer />
			</Layout>
		</div>
	);
}

const Layout = ({ children }) => (
	<div className={Styles.mainContainer}>
		<div className={Styles.contentContainer}>
			{children}
		</div>
	</div>
);

export { App };
