import { Modal } from 'react-bootstrap';
import Styles from './customModal.module.scss';
import { ReactComponent as CrossIcon } from '../../assets/crossIcon.svg';

export const CustomModal = ({
	show,
	toggleModal,
	showCrossIcon,
	modalDialogStyles,
	modalContentStyles,
	modalWrapperStyles,
	modalBodyStyles,
	children
}) => {
	return (
		<Modal
			show={show}
			onHide={toggleModal}
			centered
			className={`${Styles.modalWrapper} ${modalWrapperStyles}`}
			contentClassName={`${Styles.modalContent} ${modalContentStyles}`}
			dialogClassName={`${Styles.modalDialog} ${modalDialogStyles}`}>
			<Modal.Body className={`${Styles.modalBody} ${modalBodyStyles}`}>
				{
					showCrossIcon
          && <div className={Styles.crossIcon} onClick={toggleModal}><CrossIcon /></div>
				}
				{children}
			</Modal.Body>
		</Modal>
	);
};
