import { Spinner } from 'react-bootstrap';

const LoadingSpinner = () => {
	return (
		<div className="text-center w-100">
			<Spinner animation="border" role="status" />
		</div>
	);
};

export { LoadingSpinner };
