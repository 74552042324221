import { Link } from 'react-router-dom';
import Styles from './noLongerExpensiveCTASection.module.scss';

function JoinCTASection() {
	return (
		<div className={Styles.joinHissaySection}>
			<div className='col-lg-5 px-0 col-md-7 col-10 d-flex justfiy-content-center align-items-center flex-column'>
				<h2 className={Styles.joinHissayHeading}>
					Property ownership is {' '}
					<span className={Styles.strikethrough}>expensive</span>{' '}
					<br />
					Invest in your dream property today
				</h2>
				<Link className={Styles.joinHissayButton} to="/sign-up">
					<h5 className='p-0 m-0'>Join Hissay</h5>
				</Link>
			</div>
		</div>
	);
}

export { JoinCTASection };
