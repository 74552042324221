import { useState } from 'react';
import Styles from './calculateShare.module.scss';
import { ReactComponent as SliceIcon } from '../../../assets/sliceIcon.svg';
import { ReactComponent as WholePieIcon } from '../../../assets/wholePieIcon.svg';
import { FloatingLabelInput } from '../../floatingLabelInput';
import { Button } from '../../button';
import { toPriceFormat } from '../../../utils';
import { ProgressSavedModal } from '../progressSavedModal';

const CheckboxButton = ({
	icon, text, selected, onClick, id, type
}) => {
	return (
		<button
			id={id}
			className={`${Styles.checkboxButton} ${selected && Styles.selected}`}
			onClick={onClick}
			type={type}>
			{icon}
			<span>{text}</span>
		</button>
	);
};

export const CalculateShare = ({ updateStep, toggleProgressSaveModal, showProgressSaveModal }) => {
	const avaiableShares = 40000;
	const [selectedOption, setSelectedOption] = useState('buyShare');
	const [numberOfShares, setNumberOfShares] = useState(null);
	const [sharePercentage, setSharePercentage] = useState(null);

	const handleNumberOfSharesChange = (e) => {
		const inputValue = e.target.value;

		if (inputValue === '') {
			setSharePercentage(null);
			setNumberOfShares(null);
		} else {
			const newNumberOfShares = parseFloat(inputValue);

			setNumberOfShares(newNumberOfShares);

			if (!Number.isNaN(newNumberOfShares)) {
				const calculatedPercentage = (newNumberOfShares / 1000000) * 100;

				setSharePercentage(calculatedPercentage);
			}
		}
	};

	const handleSharePercentageChange = (e) => {
		const inputValue = e.target.value;

		if (inputValue === '' || inputValue === '%') {
			setSharePercentage(null);
			setNumberOfShares(null);
		} else {
			const newSharePercentage = parseFloat(inputValue);

			setSharePercentage(newSharePercentage);

			if (!Number.isNaN(newSharePercentage)) {
				const calculatedShares = (newSharePercentage / 100) * 1000000;

				setNumberOfShares(calculatedShares);
			}
		}
	};

	const handleOptionChange = (option) => {
		setSelectedOption(option);

		if (option === 'buyWhole') {
			setSharePercentage('100');
			setNumberOfShares(avaiableShares);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		updateStep(2);
	};

	return (
		<>
			<ProgressSavedModal show={showProgressSaveModal} toggleHandler={toggleProgressSaveModal} />
			<form onSubmit={handleSubmit}>
				<div className={Styles.checkboxButtons}>
					<CheckboxButton
						icon={<SliceIcon />}
						text="Buy a Share"
						type="button"
						selected={selectedOption === 'buyShare'}
						onClick={() => handleOptionChange('buyShare')}
					/>
					<CheckboxButton
						icon={<WholePieIcon />}
						text="Buy Whole Property"
						type="button"
						selected={selectedOption === 'buyWhole'}
						onClick={() => handleOptionChange('buyWhole')}
					/>
				</div>
				<FloatingLabelInput
					label="Number of Shares"
					type="text"
					id="numberOfShares"
					name="numberOfShares"
					disabled={selectedOption === 'buyWhole'}
					value={numberOfShares !== null ? numberOfShares.toString() : ''}
					onChange={handleNumberOfSharesChange}
					required={false}
				/>
				<p className={Styles.minimumShareText}>
					Minimum shares: <b>
						{avaiableShares > 100000 ? toPriceFormat(100000) : toPriceFormat(avaiableShares)}
					</b>
				</p>
				<FloatingLabelInput
					label="Share Percentage"
					type="text"
					id="sharePercentage"
					value={sharePercentage !== null ? sharePercentage.toString() : ''}
					name="sharePercentage"
					disabled={selectedOption === 'buyWhole'}
					onChange={handleSharePercentageChange}
					showPercentage={true}
					required={false}
				/>
				<div className={Styles.payableAmount}>
					<p className="m-0 p-0">Payable Amount</p>
					<strong>Rs {toPriceFormat('10000000')} + Transaction Fee</strong>
				</div>
				<div className={Styles.submitButtons}>
					<Button
						variant="secondary"
						text="Save & Exit"
						type="button"
						styles="w-100"
						onClick={toggleProgressSaveModal}
					/>
					<Button variant="primary" text="Continue" styles="w-100" />
				</div>
			</form>
		</>
	);
};
