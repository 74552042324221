export const navbarMenuItems = [
	{
		title: 'Home',
		link: '/'
	},
	{
		title: 'Knowledge Hub',
		link: '/knowledge-hub'
	},
	{
		title: 'Buy',
		link: '/buy'
	}
];

export const profileMenuItems = [
	{
		title: 'Your profile',
		link: '/profile'
	},
	{
		title: 'Your Favorites',
		link: '/favorites'
	},
	{
		title: 'Your Properties',
		link: '/properties'
	},
	{
		title: 'Transaction History',
		link: 'transaction-history'
	}
];

export const additionalMenuItems = [
	{
		title: 'Fractional Ownership',
		link: '/fractional-ownership'
	},
	{
		title: 'Hissay Rental Management',
		link: 'rental-management'
	}
];
