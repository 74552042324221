import { useEffect, useRef } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../assets/arrowDown.svg';
import Styles from './unitSelectionModal.module.scss';

export const UnitSelectionModal = ({ setUnit, unit }) => {
	const modalRef = useRef(null);
	const availableUnits = ['Square Feet', 'Square Meter', 'Square Yards', 'Yards', 'Kanal', 'Marla'];
	const filteredUnits = availableUnits.filter(
		(availableUnit) => availableUnit !== unit.selected
	);

	const handleListItemClick = (e) => {
		const selectedUnit = e.target.dataset.id;

		setUnit({ selected: selectedUnit, modal: !unit.modal });
	};

	const handleArrowClick = () => {
		setUnit({ selected: unit.selected, modal: !unit.modal });
	};

	const handleOutsideClick = (e) => {
		if (!modalRef.current.contains(e.target)) {
			handleArrowClick();
		}
	};

	useEffect(() => {
		if (unit.modal) {
			window.addEventListener('click', handleOutsideClick);
		}

		return () => {
			window.removeEventListener('click', handleOutsideClick);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unit.modal]);

	return (
		<span ref={modalRef}>
			<ArrowDown className="pl-2" onClick={handleArrowClick} />
			{
				unit.modal && (
					<ul className={Styles.units} onClick={handleListItemClick}>
						{filteredUnits.map((u) => (
							<li className={Styles.unit} data-id={u} key={u}>
								{u}
							</li>
						))}
					</ul>
				)
			}
		</span>
	);
};
