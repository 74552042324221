import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Styles from './accordion.module.scss';
import { ReactComponent as ChevronIcon } from '../../assets/arrowDownIcon.svg';

function Accordion({
	items, headerField, bodyField, alwaysOpen
}) {
	const [currentItem, setCurrentItem] = useState(0);

	const handleAccordionToggle = (accordionNumber) => {
		if (alwaysOpen) {
			setCurrentItem(accordionNumber);
		} else if (accordionNumber === currentItem) {
			setCurrentItem(-1);
		} else {
			setCurrentItem(accordionNumber);
		}
	};

	return (
		<div className="w-100">
			{
				items.map((item, index) => (
					<div className={`${Styles.accordionItem} ${currentItem === index && Styles.accordionItemOpen}`} key={`accordion-item-${index}`}>
						<button
							className={Styles.accordionButton}
							onClick={() => handleAccordionToggle(index)}
						>
							<h5 className={Styles.accordionLabel}>{item[headerField]}</h5>
							<ChevronIcon
								className={`${Styles.accordionIcon} ${currentItem === index ? Styles.currentAccordionIcon : ''}`}
							/>
						</button>
						<AnimatePresence initial={false}>
							{currentItem === index && (
								<motion.section
									key="content"
									initial="collapsed"
									animate="open"
									exit="collapsed"
									variants={{
										open: { opacity: 1, height: 'auto' },
										collapsed: { opacity: 0, height: 0 }
									}}
									transition={{ duration: 1.0, ease: [0.04, 0.62, 0.23, 0.98] }}
								>
									<p className={Styles.accordionBodyText}>{item[bodyField]}</p>
									{item.link && (
										<Link to={`/${item.link}`} className={Styles.accordionLink}>
											{item.linkText}
										</Link>
									)}
								</motion.section>
							)}
						</AnimatePresence>
					</div>
				))
			}
		</div>
	);
}

export { Accordion };
