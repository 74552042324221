import { Col, Row } from 'react-bootstrap';
import { FloatingLabelInput } from '../../../../floatingLabelInput';
import { FloatingDropdownInput } from '../../../../floatingDropdownInput';

export const MailingAddressSection = ({
	handleInputChange,
	setSelectedCity,
	handleCountryChange,
	selectedCountry,
	selectedCity,
	cities,
	countries
}) => {
	return (
		<>
			<h4 className="mt-5 pb-2">Mailing Address</h4>
			<Row className="mr-0">
				<Col xs={12} className="pr-0">
					<FloatingLabelInput
						label="Street Address"
						type="text"
						id="streetAddress"
						name="streetAddress"
						onChange={handleInputChange}
						required={true}
					/>
				</Col>
			</Row>
			<Row className="mr-0">
				<Col xs={12} lg={6} className="pr-0">
					<FloatingDropdownInput
						id="city"
						label="City"
						onChange={(e) => setSelectedCity(e.target.value)}
						name="city"
						options={cities}
						value={selectedCity}
					/>
				</Col>
				<Col xs={12} lg={6} className="pr-0">
					<FloatingLabelInput
						label="Postal Code"
						type="text"
						id="postalCode"
						name="postalCode"
						onChange={handleInputChange}
						required={true}
					/>
				</Col>
			</Row>
			<Row className="mr-0">
				<Col xs={12} className="pr-0">
					<FloatingDropdownInput
						id="country"
						label="Country"
						onChange={handleCountryChange}
						name="country"
						options={countries || null}
						value={selectedCountry}
					/>
				</Col>
			</Row>
		</>
	);
};
