import { useState } from 'react';
import {
	Col, Row, Tab, Tabs
} from 'react-bootstrap';
import Styles from './locationTabs.module.scss';

const LocationTabs = () => {
	const [locationTabKey, setLocationTabKey] = useState('schools');

	return (
		<Tabs
			activeKey={locationTabKey}
			onSelect={(k) => setLocationTabKey(k)}
			className={`border-0 flex-nowrap ${Styles.locationTabs}`}>
			<Tab eventKey="nearby" title="Nearby" tabClassName={Styles.tab}>
				<div className="mt-4">
					Nearby Locations
				</div>
			</Tab>
			<Tab eventKey="hospital" title="Hospital" tabClassName={Styles.tab}>
				<div className="mt-4">
					Hospitals
				</div>
			</Tab>
			<Tab eventKey="schools" title="Schools" tabClassName={Styles.tab}>
				<Row className="mt-4">
					<Col xl={7} className="bg-info">
					</Col>
					<Col xl={5}>
						<h5 className="font-weight-bold">Schools</h5>
						<div className="d-flex align-items-center justify-content-between mt-4 flex-wrap">
							<p className="m-0">Lorem ipsum, dolor sit amet consectetur</p>
							<p className="m-0">Lorem ipsum, dolor</p>
						</div>
						<div className="d-flex align-items-center justify-content-between my-md-4 my-2 flex-wrap">
							<p className="m-0">Lorem ipsum, dolor sit</p>
							<p className="m-0">Lorem ipsum, dolor consectetur</p>
						</div>
						<div className="d-flex align-items-center justify-content-between flex-wrap">
							<p className="m-0">Lorem ipsum, dolor sit consectetur</p>
							<p className="m-0">Lorem ipsum, dolor</p>
						</div>
					</Col>
				</Row>
			</Tab>
			<Tab eventKey="Restaurants" title="Restaurants" tabClassName={Styles.tab}>
				<div className="mt-4">
					Restaurants
				</div>
			</Tab>
			<Tab eventKey="Parks" title="Parks" tabClassName={Styles.tab}>
				<div className="mt-4">
					Parks
				</div>
			</Tab>
		</Tabs>
	);
};

export { LocationTabs };
