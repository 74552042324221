import toast from 'react-hot-toast';
import { DateTime } from 'luxon';

export const convertMarlaTo = (unit, marla) => {
	const squareFeet = marla * 272.25;
	const squareMeter = squareFeet / 10.764;
	const squareYards = squareFeet / 9;
	const yards = Math.sqrt(squareYards);
	const kanal = squareYards / 605;

	switch (unit) {
	case 'Square Feet': return squareFeet.toFixed(2);
	case 'Square Meter': return squareMeter.toFixed(2);
	case 'Square Yards': return squareYards.toFixed(2);
	case 'Kanal': return kanal.toFixed(2);
	case 'Yards': return yards.toFixed(2);
	default: return marla.toFixed(2);
	}
};

export const successToast = (message) => {
	toast.success(message, {
		style: {
			background: '#333',
			color: '#fff',
		},
	});
};

export const errorToast = (message) => {
	toast.error(message, {
		style: {
			background: '#333',
			color: '#fff',
		},
	});
};

export const timeAgo = (date) => {
	const inputDate = DateTime.fromISO(date);
	const currentDate = DateTime.local();
	const diff = currentDate.diff(inputDate, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']).toObject();

	if (diff.years > 0) {
		return `${diff.years} year${diff.years > 1 ? 's' : ''} ago`;
	}

	if (diff.months > 0) {
		return `${diff.months} month${diff.months > 1 ? 's' : ''} ago`;
	}

	if (diff.days > 0) {
		return `${diff.days} day${diff.days > 1 ? 's' : ''} ago`;
	}

	if (diff.hours > 0) {
		return `${diff.hours} hour${diff.hours > 1 ? 's' : ''} ago`;
	}

	if (diff.minutes > 0) {
		return `${diff.minutes} minute${diff.minutes > 1 ? 's' : ''} ago`;
	}

	return 'just now';
};

export const toPriceFormat = (price) => {
	return new Intl.NumberFormat().format(price);
};

export const formatPhoneNumber = (phoneNumber) => {
	const digitsOnly = phoneNumber.replace(/\D/g, '');
	const cleanedNumber = digitsOnly.startsWith('92') ? `0${digitsOnly.slice(2)}` : digitsOnly;

	return cleanedNumber.replace(/(\d{4})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const toSlashDateFormat = (date) => {
	return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
};
