import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProgressBar } from '../../../progressBar';
import Styles from './buyBox.module.scss';
import { toPriceFormat } from '../../../../utils';

export const BuyBox = ({ property }) => {
	return (
		<Col xl={4} className="pl-xl-1">
			<div className={Styles.claimPropertySection}>
				<h6 className="pb-4 text-capitalize font-weight-bold">Make this property yours</h6>
				<div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
					<small className="m-0">{toPriceFormat(property.property_share_info.initial_offering_shares_sold)}/{toPriceFormat(property.property_share_info.initial_offering_total_shares)}</small>
					<h6 className="m-0 text-capitalize font-weight-bold">Per Share: {property.price_unit.toUpperCase()} {property.cost_per_share}</h6>
				</div>
				<ProgressBar
					total={property.property_share_info.initial_offering_total_shares}
					current={property.property_share_info.initial_offering_shares_sold}
					note='77% people have already committed to their shares!'
				/>
				<div className={Styles.callToActions}>
					<Link to='/checkout' className={Styles.buyMyShareButton}>Buy My Share</Link>
					<Link to="/" className={Styles.link}>How does buying a share work?</Link>
					<Link to='/checkout' className={Styles.wholePropertyButton}>Whole Property</Link>
				</div>
			</div>
		</Col>
	);
};
