import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AppNavbar } from '../navbar';

function PublicRoute({ Component, ...props }) {
	const { user } = useSelector((state) => state.auth);

	document.title = props.title ? `${props.title} | Hissay` : 'Hissay';

	return (
		<>
			<AppNavbar />
			{
				user?.email && props.restricted
					? <Navigate to={{ pathname: '/', state: { from: props.location } }} replace />
					: <Component {...props} />
			}
		</>
	);
}

export { PublicRoute };
