import { useEffect, useState } from 'react';
import Styles from './floatingLabelInput.module.scss';
import { ReactComponent as VisibilityOn } from '../../assets/auth/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../assets/auth/visibility_off.svg';

function FloatingLabelInput({
	type,
	id,
	label,
	required,
	inputRef,
	onChange,
	name,
	sensitiveField,
	value,
	showPercentage,
	disabled
}) {
	const [sensitiveValueType, setSensitiveValueType] = useState('password');
	const [isInputFocused, setIsInputFocused] = useState(false);

	const handleFocus = () => {
		setIsInputFocused(true);
	};

	const handleBlur = () => {
		setIsInputFocused(false);
	};

	useEffect(() => {
		if (value && value !== '') {
			setIsInputFocused(true);
		}
	}, [value]);

	const changeSensitiveValueType = () => {
		setSensitiveValueType(sensitiveValueType === 'text' ? 'password' : 'text');
	};

	return (
		<div className={`${Styles.floatingInputField} ${isInputFocused ? Styles.inputFocused : ''}`}>
			<input
				ref={inputRef}
				type={sensitiveField ? sensitiveValueType : type}
				name={name}
				id={id}
				onChange={onChange}
				className={Styles.input}
				placeholder=""
				value={value}
				onFocus={handleFocus}
				onBlur={handleBlur}
				disabled={disabled}
				required={required}>
			</input>
			<label className={Styles.label} htmlFor={id}>{label}</label>
			{showPercentage && <span className={Styles.percentageSymbol}>%</span>}
			{
				sensitiveField
				&& <div className={Styles.visibilityIcon} onClick={changeSensitiveValueType}>
					{sensitiveValueType === 'text' ? <VisibilityOn /> : <VisibilityOff />}
				</div>
			}
		</div>
	);
}

export { FloatingLabelInput };
