import Styles from './button.module.scss';

export const Button = ({
	loading,
	styles,
	disabled,
	onClick,
	variant = 'primary',
	leftIcon,
	rightIcon,
	text,
	...restProps
}) => {
	const handleClick = () => {
		if (onClick && !disabled && !loading) {
			onClick();
		}
	};

	const textStyles = {
		paddingLeft: leftIcon && '10px',
		paddingRight: rightIcon && '10px'
	};

	return (
		<button
			className={
				`${Styles.buttonBody} ${styles} ${variant !== 'primary' && Styles.secondary}`
			}
			disabled={disabled || loading}
			onClick={handleClick}
			{...restProps}>
			{leftIcon}
			<span style={textStyles}>
				{text}
			</span>
			{rightIcon}
		</button>
	);
};
