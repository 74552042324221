import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PropertyCard } from '../card';
import { Container } from '../../container';
import { ReactComponent as ListviewIcon } from '../../../assets/favoriteProperties/listviewIcon.svg';
import { ReactComponent as GridviewIcon } from '../../../assets/favoriteProperties/gridViewIcon.svg';
import { LoadingSpinner } from '../../loadingSpinner';
import Styles from './favorites.module.scss';

function FavoritePropertiesScreen() {
	const [selectedView, setSelectedView] = useState('Grid');
	const [properties, setProperties] = useState([]);
	const [filterOption, setFilterOption] = useState('selectFilters');
	const [sortOption, setSortOption] = useState('selectSortOption');
	const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
	const [showLoadMoreSpinner, setShowLoadMoreSpinner] = useState(false);
	const [showSpinner, setShowSpinner] = useState(true);

	useEffect(() => {
		setShowSpinner(false);
		setShowLoadMoreSpinner(false);	// To ignore Lint warning
		setShowLoadMoreButton(true);	// To ignore Lint warning

		// Todo: Get all properties dispatch here
		setProperties([
			{
				id: 1,
				created_at: '2023-03-20T03:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 1690024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: true,
				location: 'DHA Defence Phase 2, DHA Defense, Islamabad'
			},
			{
				id: 2,
				created_at: '2022-07-21T04:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 2590024,
				beds: 6,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: true,
				location: 'DHA Defence Phase 5, DHA Defense, Islamabad'
			},
			{
				id: 3,
				created_at: '2017-07-27T09:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 290024,
				beds: 3,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: true,
				location: 'DHA Defence Phase 2, DHA Defense, Islamabad'
			},
			{
				id: 4,
				created_at: '2023-03-18T08:21:41.000Z',
				mainImage: 'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?cs=srgb&dl=pexels-binyamin-mellish-186077.jpg&fm=jpg',
				price: 690024,
				beds: 4,
				baths: 4,
				marla: 4.6,
				sale: true,
				favorite: true,
				location: 'DHA Defence Phase 2, DHA Defense, Islamabad'
			},
		]);
	}, []);

	const propertyInfo = (key, value) => {
		return (
			<div className="d-flex align-items-center justify-content-between">
				<p>{key}</p>
				<p className="font-weight-bold">{value}</p>
			</div>
		);
	};

	const filterHandler = (e) => {
		setFilterOption(e.target.value);
	};

	const sortHandler = (e) => {
		setSortOption(e.target.value);
	};

	const ListView = () => (
		<div>
			{
				properties.map((property, index) => (
					<Row key={index} className={`${Styles.propertyRow} py-5`}>
						<Col md={6} xl={4}>
							<PropertyCard property={property} />
						</Col>
						<Col md={6} xl={8}>
							<Row>
								<Col md={12} xl={4} className="pt-4 pt-md-0">
									{propertyInfo('Home Type', 'Flat')}
									{propertyInfo('View Built', '2021')}
									{propertyInfo('Status', 'Active')}
									{propertyInfo('Max Price', 'Rs. 6,900,000')}
								</Col>
								<Col md={12} xl={4} className={Styles.propertyDetails}>
									{propertyInfo('Flooring', 'Wood')}
									{propertyInfo('Elevators', 'Available')}
									{propertyInfo('Windows', 'Double Glazed')}
								</Col>
								<Col md={12} xl={4} className={Styles.propertyDetails}>
									<button className={Styles.buyNowButton}>Buy Now</button>
								</Col>
							</Row>
						</Col>
					</Row>
				))
			}
		</div>
	);

	const gridView = () => (
		<div className={Styles.gridViewContainer}>
			<Row>
				{
					properties.map((property, index) => (
						<Col key={index} md={6} xl={4} className="my-3">
							<PropertyCard property={property} favoriteIcon={false} />
						</Col>
					))
				}
			</Row>
		</div>
	);

	return (
		<main className="py-5">
			<Container>
				<h1 className={`w-100 font-weight-bold my-5 ${Styles.screenTitle}`}>Your Favorites ({properties.length})</h1>
				{
					showSpinner
						? <LoadingSpinner />
						: (
							<>
								<div className="d-flex align-items-center justify-content-between flex-wrap">
									<div className={Styles.filterSortButtons}>
										<div className={Styles.filterContainer}>
											<label className="p-0 m-0">Filter:</label>
											<select
												onChange={filterHandler}
												value={filterOption}
												className={Styles.filterSelecter}>
												<option value="selectFilters" disabled>Select filters</option>
												<option value="activeAndSold">Active & Sold</option>
												<option value="expensive">Expensive</option>
												<option value="sold">Sold</option>
											</select>
										</div>
										<div className={`ml-md-4 ${Styles.sortContainer}`}>
											<label className="p-0 m-0">Sort:</label>
											<select
												onChange={sortHandler}
												value={sortOption}
												className={Styles.filterSelecter}>
												<option value="selectSortOption" disabled>Select sort options</option>
												<option value="recentToOld">Date Added (Recent to Old)</option>
												<option value="oldToRecent">Date Added (Old to Recent)</option>
											</select>
										</div>
									</div>
									<div className={`${Styles.viewButtons} mt-lg-0 mt-4`}>
										<button className={`${Styles.viewButton} ${selectedView === 'Grid' ? Styles.selected : null}`} onClick={() => setSelectedView('Grid')}>
											<GridviewIcon />
											Grid View
										</button>
										<button className={`${Styles.viewButton} ${selectedView === 'List' ? Styles.selected : null}`} onClick={() => setSelectedView('List')}>
											<ListviewIcon />
											List View
										</button>
									</div>
								</div>
								{selectedView === 'Grid' && gridView()}
								{selectedView === 'List' && ListView()}
								<div className="text-center my-4">
									{
										showLoadMoreButton && !showLoadMoreSpinner
											? <button className={Styles.loadMoreButton}>
												Load More
											</button>
											: <>
												{
													showLoadMoreSpinner
														? <LoadingSpinner />
														: <p className="m-0">There are no more favorites</p>
												}
											</>
									}
								</div>
							</>
						)
				}
			</Container>
		</main>
	);
}

export { FavoritePropertiesScreen };
