import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const CustomTooltip = ({ children, icon }) => {
	const tooltip = (
		<Tooltip id="tooltip" className="whiteTooltip">
			{children}
		</Tooltip>
	);

	return (
		<OverlayTrigger placement="top" overlay={tooltip}>
			{icon}
		</OverlayTrigger>
	);
};
