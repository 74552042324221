import { CustomModal } from '../../customModal';
import { ReactComponent as SaveIcon } from '../../../assets/saveIcon.svg';
import { Button } from '../../button';
import Styles from './progressSavedModal.module.scss';

export const ProgressSavedModal = ({ show, toggleHandler }) => {
	const submitHandler = () => {
		toggleHandler(false);
	};

	return (
		<CustomModal
			show={show}
			toggleModal={() => toggleHandler(!show)}
			showCrossIcon={true}>
			<SaveIcon />
			<h4 className="pt-3">Progress Saved as a Draft</h4>
			<p>
				Your progress has been saved as draft, and you can access it from your profile page
				to complete your purchase.
			</p>
			<div className={Styles.submitButtons}>
				<Button
					variant="primary"
					text="Continue"
					styles="w-100"
					onClick={submitHandler}
				/>
				<Button
					variant="secondary"
					text="Cancel"
					styles="w-100"
					onClick={() => toggleHandler(!show)}
				/>
			</div>
		</CustomModal>
	);
};
