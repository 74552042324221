import Styles from './pageOverlaySpinner.module.scss';

export const PageOverlaySpinner = () => {
	return (
		<div className={Styles.screenCoveredSpinner}>
			<div className={Styles.textsContainer}>
				<h1 className={Styles.text1}>Saving...</h1>
				<h1 className={Styles.text2}>Saving...</h1>
			</div>
		</div>
	);
};
