import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Styles from './sidebar.module.scss';
import { HissayLogo } from '../../../assets';
import CloseIcon from '../../../assets/navbar/closeIcon.svg';
import { additionalMenuItems, navbarMenuItems, profileMenuItems } from '../menuItems';

function Sidebar({ open, toggle, handleLogout }) {
	const { user } = useSelector((state) => state.auth);

	return (
		<div className={`${Styles.sidebarContainer} ${open ? Styles.sidebarOpen : ''}`}>
			<div className={Styles.sidebarTopContainer}>
				<div className='px-3 py-1 d-flex align-items-center justify-content-between'>
					{
						user?.email
							? (
								<button className={Styles.profileButton}>
									<div className={Styles.avatar}>{user?.first_name.charAt(0)}</div>
									<span className={Styles.profileName}>
										{user.first_name} {user.last_name}
									</span>
								</button>
							) : (
								<LinkContainer to="/" className={Styles.logo}>
									<img src={HissayLogo} alt="Hissay Logo" className="mr-xl-5 mr-4" width={161} height={44} />
								</LinkContainer>
							)
					}
					<div onClick={toggle} className={Styles.closeButton}>
						<img src={CloseIcon} alt="Close" width={36} height={36} />
					</div>
				</div>
				{
					!user?.email
					&& <div className="mb-3 mt-5 px-3">
						<Link to="/sign-up">
							<button className={`px-2 ${Styles.signUpButton}`} onClick={toggle}>
								Sign Up
							</button>
						</Link>
						<Link to="/sign-in">
							<button className={`px-2 ${Styles.loginButton}`} onClick={toggle}>
								Login
							</button>
						</Link>
					</div>
				}
				<hr className={Styles.divider} />
				<Nav className={Styles.menuContainer}>
					{
						navbarMenuItems.map((item, index) => (
							<LinkContainer to={item.link} key={`mobile-nav-menu-${index}`}>
								<Nav.Link className={Styles.navLink}>{item.title}</Nav.Link>
							</LinkContainer>
						))
					}
				</Nav>
				{
					user?.email
					&& <div>
						<hr className={Styles.divider} />
						<Nav className={Styles.menuContainer}>
							{
								profileMenuItems.map((item, index) => (
									<LinkContainer to={item.link} key={`mobile-profile-menu-${index}`}>
										<Nav.Link className={Styles.navLink}>{item.title}</Nav.Link>
									</LinkContainer>
								))
							}
						</Nav>
						<div className={Styles.learnAboutContainer}>
							<p className={Styles.learnAboutText}>Learn About</p>
						</div>
						<Nav className={Styles.menuContainer}>
							{
								additionalMenuItems.map((item, index) => (
									<LinkContainer to={item.link} key={`mobile-additional-menu-${index}`}>
										<Nav.Link className={Styles.navLink}>{item.title}</Nav.Link>
									</LinkContainer>
								))
							}
						</Nav>
						<hr className={Styles.divider} />
						<div className={Styles.menuContainer}>
							<button className={Styles.logOutButton} onClick={handleLogout}>Log Out</button>
						</div>
					</div>
				}
			</div>
			<hr className={`m-0 ${Styles.divider}`} />
			<div className={Styles.sidebarBottomSection}>
				<div className={Styles.citiesContainer}>
					<h3 className="text-right mb-2 mt-0">Popular</h3>
					<div className="d-flex justify-content-end">
						<Link className={Styles.city} to='/'>Karachi</Link>
						<Link className={Styles.city} to='/'>Islamabad</Link>
						<Link className={Styles.city} to='/'>Lahore</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export { Sidebar };
