import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Styles from './portfolioSummarySection.module.scss';
import EarningsIcon from '../../../assets/transactionHistory/earningsIcon.svg';
import ValueIcon from '../../../assets/transactionHistory/valueIcon.svg';
import InvestmentIcon from '../../../assets/transactionHistory/investmentIcon.svg';

const PortfolioSummarySection = () => {
	const [totalEarning, setTotalEarning] = useState({
		value: 0,
		propertiesSold: 0,
		rentalProperties: 0
	});

	const [portfolioValue, setPortfolioValue] = useState({
		value: 0,
		propertiesOwned: 0,
		trend: ''
	});

	const [totalInvestment, setTotalInvestment] = useState({
		value: 0,
		propertiesPurchased: 0
	});

	useEffect(() => {
		setTotalEarning({ value: 230000, propertiesSold: 18, rentalProperties: 6 });
		setPortfolioValue({ value: 32300000, propertiesOwned: 7, trend: '+4.88%' });
		setTotalInvestment({ value: 2680000, propertiesPurchased: 18 });
	}, []);

	return (
		<Row className={`mx-0 p-0 ${Styles.cards}`}>
			<Col className={Styles.cardColumn}>
				<Card className={`${Styles.card} containerShadow`}>
					<Card.Body className="d-flex align-items-center">
						<img src={EarningsIcon} alt="Total earnings"/>
						<div className="ml-3">
							<Card.Text className="text-muted mb-1">Total Earnings</Card.Text>
							<Card.Title className="mb-0">Rs. {totalEarning.value.toLocaleString()}</Card.Title>
						</div>
					</Card.Body>
					<Card.Footer className={Styles.cardBottom}>
						<p>Properties Sold: {totalEarning.propertiesSold.toLocaleString()}</p>
						<p>Rented Properties: {totalEarning.rentalProperties.toLocaleString()}</p>
					</Card.Footer>
				</Card>
			</Col>
			<Col className={Styles.cardColumn}>
				<Card className={`${Styles.card} containerShadow`}>
					<Card.Body className="d-flex align-items-center">
						<img src={ValueIcon} alt="Portfolio value" />
						<div className="ml-3">
							<Card.Text className="text-muted mb-1">Portfolio Value</Card.Text>
							<div className="d-flex">
								<Card.Title className="mb-0">Rs. {portfolioValue.value.toLocaleString()}</Card.Title>
								<p className={Styles.trendText}>{portfolioValue.trend}</p>
							</div>
						</div>
					</Card.Body>
					<Card.Footer className={Styles.cardBottom}>
						<p className="m-0">Properties Owned: {portfolioValue.propertiesOwned.toLocaleString()}</p>
					</Card.Footer>
				</Card>
			</Col>
			<Col className={Styles.cardColumn}>
				<Card className={`${Styles.card} containerShadow`}>
					<Card.Body className="d-flex align-items-center">
						<img src={InvestmentIcon} alt="Total investment"/>
						<div className="ml-3">
							<Card.Text className="text-muted mb-1">Total Investment</Card.Text>
							<Card.Title className="mb-0">Rs. {totalInvestment.value.toLocaleString()}</Card.Title>
						</div>
					</Card.Body>
					<Card.Footer className={Styles.cardBottom}>
						<p className="m-0">Properties Purchased: {totalInvestment.propertiesPurchased.toLocaleString()}</p>
					</Card.Footer>
				</Card>
			</Col>
		</Row>
	);
};

export { PortfolioSummarySection };
