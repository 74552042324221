import { Row, Col } from 'react-bootstrap';
import Styles from './heroSection.module.scss';
import { WhatIsFractionalOwnership } from '../../../assets/fractionalOwnershipScreen';
import { Container } from '../../container';

function HeroSection() {
	return (
		<div className="py-5">
			<Container>
				<Row className="align-items-center">
					<Col xs={12} lg={6}>
						<h1 className={Styles.sectionHeading}>
							Property is expensive. Fractional Ownership let's you still invest
						</h1>
						<h4 className={Styles.sectionSubHeading}>
							What is Fractional Ownership or Co-ownership?
						</h4>
						<p className={Styles.sectionText}>
							Imagine you have a cake. While some people might be able to eat a whole cake, most
							people cannot. Having some cake would still be nice though, no?
						</p>
						<p className={Styles.sectionText}>
							We believe the same thing with investment property ownership. Some people might be
							able to buy a whole property but most people simply cannot buy the most attractive
							investment options. That’s why we enable you to buy a fraction of the best investment
							properties so that you can take control of your financial future.
						</p>
					</Col>
					<Col xs={12} lg={6}>
						<div className="w-100">
							<img src={WhatIsFractionalOwnership} alt="What is Fractional Ownership" width="100%" loading="lazy" />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export { HeroSection };
