import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { ProgressBar } from '../../../progressBar';
import { ReactComponent as ShareIcon } from '../../../../assets/propertyCard/shareIcon.svg';
import { toSlashDateFormat, toPriceFormat } from '../../../../utils/index';
import { ShareSocialsModal } from '../shareSocialsModal';
import Styles from './card.module.scss';

export const PropertyCard = ({ property }) => {
	const shareContainerRef = useRef();
	const [shareContainer, setShareContainer] = useState(false);

	const shareSocialsModalHandler = () => {
		setShareContainer(!shareContainer);
	};

	return (
		<Card key={property.id} className={`${Styles.propertyCard} shadow-sm`}>
			<div className={Styles.postedTime}>{toSlashDateFormat(property.created_at)}</div>
			{property.status && <div className={Styles.statusTag}>{property.status}</div>}
			<Link to="/" className={Styles.linkImage}>
				<Card.Img variant="top" src={property.mainImage} />
			</Link>
			<Card.Body className="py-2">
				<div className={Styles.cardTitleContainer}>
					<h4 className="m-0">H#17B, ST#32</h4>
					<div className="position-relative">
						<ShareIcon className={Styles.icon} onClick={shareSocialsModalHandler} />
						{
							shareContainer
							&& <ShareSocialsModal
								url={property.url || window.location.href}
								innerRef={shareContainerRef}
								modalHandler={shareSocialsModalHandler}
							/>
						}
					</div>
				</div>
				<p className={Styles.descriptionText}>Park One Flats, Faisal Ave Islamabad</p>
				<p className="m-0 pt-2">Your Share: 18%</p>
				<p className="m-0 pb-2">Value: Rs. {toPriceFormat(3215000)}</p>
				<p className={`${Styles.count} mb-1`}>
					{toPriceFormat(415000)}/{toPriceFormat(1500000)}
				</p>
				<ProgressBar total={1500000} current={415000} />
			</Card.Body>
		</Card>
	);
};
